import { useState, useEffect } from "react";
//-------------- Others --------------------
import { Container, Row, Col, Button } from "react-bootstrap";
import classes from "./Invoice.module.css";
import * as actionCreator from "../../../store/action/index";
import { useDispatch, useSelector } from "react-redux";

const stripe = require("stripe")(process.env.STRIPE_SECRET_KEY);

const Invoice = () => {
  const [customer, setCustomer] = useState({});
  const [authUrl, setAuthUrl] = useState("");
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const userData = useSelector((state) => state.user.userData);
  const dispatch = useDispatch();

  useEffect(() => {
    async function createConnectionToken() {
      if (!userData) {
        await dispatch(actionCreator.populateUserData());
      }
      try {
        const connectionToken = await stripe.terminal.connectionTokens.create({
          location: "default",
          online: true,
          customer_email: userData.email,
        });

        const stripe = require('stripe')('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');

        const portalUrl = await stripe.billingPortal.configurations.create({
          features: {
            customer_update: {
              allowed_updates: ['email', 'tax_id'],
              enabled: true,
            },
            invoice_history: {enabled: true},
          },
          business_profile: {
            privacy_policy_url: 'https://example.com/privacy',
            terms_of_service_url: 'https://example.com/terms',
          },
        });

        setAuthUrl(portalUrl.url);
       
      } catch (error) {
        console.error(error);
      }
    }

    createConnectionToken();


  }, [userData, dispatch]);

  return (
    <>
      <Container>
        <Row style={{ marginTop: "50px" }} className="text-left">
          <Col md={12}>
            <h1 className={classes.title}>Mes informations de paiement</h1>
            <h2 className={classes.subtitle}>
              Pour plus de sécurité, vos informations de paiement sont gérées
              directement par Stripe.
            </h2>
          </Col>
        </Row>

        <Row className="text-left">
          <Col md={12} style={{ paddingTop: 30 }}>
            <Button
              variant="primary"
              type="button"
              className={classes.button}
              href={
                authUrl
                  ? authUrl
                  : "https://billing.stripe.com/p/login/fZe8yN6VV9CN67CfYY"
              }
            >
              Modifier mes informations de paiement et de facturation
            </Button>
          
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Invoice;
