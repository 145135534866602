import { clientCredentialAxios } from '../AxiosInstance';



export const login = (data) => {
    return clientCredentialAxios.post('login',data);
}

export const register = (data) => {
    return clientCredentialAxios.post('register',data);
}

export const confirmEmail = (data) => {
    return clientCredentialAxios.post('confirm-email',data);
}

export const departues = () => {
    return clientCredentialAxios.get('departues');
}

export const places = ($departue_id) => {
    return clientCredentialAxios.get('places/'+$departue_id);
}


export const ResetPasswordEmail = (data) => {
    return clientCredentialAxios.post('reset-password/email',data);
}

export const ResetPasswordCheck = (data) => {
    return clientCredentialAxios.post('reset-password/check',data);
}

export const ResetPassword = (data) => {
    return clientCredentialAxios.post('reset-password',data);
}

export const getCalculatedPrice = (data) => {
    return clientCredentialAxios.post('calculated-price',data);
}


