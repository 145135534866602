import React, { useEffect } from "react";
//--------- Others ------------------
import * as actionCreator from "../store/action/index";
import ReactGA from "react-ga";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

ReactGA.initialize("UA-185657986-1");
const trackPage = (page) => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const LayoutNotLogin = ({ children, page }) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(actionCreator.populateUserData());
    }
  }, []);

  useEffect(() => {
    if (Object.keys(userData).length) {
      if (userData.type === "admin") {
        history.push("/admin/requests");
      }
      if (userData.type === "driver") {
        history.push("/driver/requests");
      }
    }
  }, [userData, history]);

  return (
    <>
      {trackPage(page ? page : "Another Page")}
      {children}
    </>
  );
};

export default LayoutNotLogin;
