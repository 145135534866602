import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";
import * as authApi from "../../../Axios/Call/AuthApi";
import AlertGeneral from "../../Commons/AlertGeneral/AlertGeneral";
import LoadingButton from "../../Commons/LoadingButton/LoadingButton";
import moment from "moment";
import { useForm } from "react-hook-form";
import * as clientCredential from "../../../Axios/Call/ClientCredential";
import { Form } from "react-bootstrap";

import Datetime from "react-datetime";

const ModalEdit = ({ onOpenCloseEditRequest, openModalEdit, renderStatus }) => {
  const [request, setRequest] = useState({});
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const userData = useSelector((state) => state.user.userData);
  const [loading, setLoading] = useState(false);
  const [departues, setDepartues] = useState([]);
  const [loadingServices, setLoadingServices] = useState(false);

  // LIST OF TIMES
  const [timeDepartue, setTimeDepartue] = useState([]);
  const [timeArrival, setTimeArrival] = useState([]);

  const [departueDate, setDepartueDate] = useState(null);
  const [returnDate, setReturnDate] = useState(null);
  const [choosenTimeDepartue, setChoosenTimeDepartue] = useState(null);
  const [choosenTimeArrival, setChoosenTimeArrival] = useState(null);

  const [loadingDepartue, setLoadingDepartue] = useState(false);
  const [errorApiPost, setErrorApiPost] = useState(false);

  // ADDITIONAL SERVICES
  const [additionalServices, setAdditionalServices] = useState();
  const [choosenServices, setChoosenServices] = useState();

  const [calculatePrice, setCalculatePrice] = useState(request?.price);


  const handleDepartureDateTimeChange = (date) => {
    setDepartueDate(date);
  };

  const handleArrivalDateTimeChange = (date) => {
    setReturnDate(date);
  };

  const handleDepartureTimeChange = (time) => {

  };

  const handleArrivalTimeChange = (time) => {

  };

  const yesterday = moment().subtract(1, "day");
  const disablePastDt = (current) => {
    return current.isAfter(yesterday);
  };

  const filteredDepartues = departues.filter(
    (departue) => departue.id !== request?.car_request?.departue_place
  );

  const filteredArrival = departues.filter(
    (arrival) => arrival.id !== request?.car_request?.arrival_place
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  useEffect(() => {
    if (Object.keys(userData).length) {
      setLoadingApi(true);

      authApi
        .adminGetRequest(openModalEdit)
        .then((response) => {
          setLoadingApi(false);
          setRequest(response.data);
        })
        .catch((error) => {
          setLoadingApi(false);
        });

      setLoadingServices(true);
      authApi
        .getAdditionalServicesClient()
        .then((response) => {
     
          const services = response.data.map((service) => ({
            id: service.id,
            name: service.name,
            price: service.price,
          }));
          setAdditionalServices(services);
          setLoadingServices(false);
        })
        .catch((error) => {});
    }
  }, [userData]);

  useEffect(() => {
    setLoadingDepartue(true);
    clientCredential
      .departues()
      .then((response) => {
        setDepartues(response.data);
        setLoadingDepartue(false);
      })
      .catch((error) => {
        setLoadingDepartue(false);
      });

    authApi
      .getAdditionalServicesClient()
      .then((response) => {
        setAdditionalServices(response.data);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    if (departueDate) {
      authApi
        .timeDepatue({ data: moment(departueDate).format("yyyy-MM-DD") })
        .then((response) => {
          setTimeDepartue(response.data);
        })
        .catch((error) => {});
    }
  }, [departueDate]);

  useEffect(() => {
    if (returnDate) {
      authApi
        .timeReturn({ data: moment(returnDate).format("yyyy-MM-DD") })
        .then((response) => {
          setTimeArrival(response.data);
        })
        .catch((error) => {});
    }
  }, [returnDate]);

  useEffect(() => {
    if (request) {
      setDepartueDate(
        moment(request?.car_request?.departue_date_time).format("YYYY-MM-DD")
      );
      setReturnDate(
        moment(request?.car_request?.arrival_date_time).format("YYYY-MM-DD")
      );
      setChoosenServices(request?.car_request?.services ?? []);

      setChoosenTimeDepartue(request?.car_request?.time_departue_id);
      setChoosenTimeArrival(request?.car_request?.time_arrival_id);
    }
 
  }, [request]);

  useEffect(() => {
    setValue(
      "departue_date_time",
      moment(departueDate, "DD/MM/YYYY").format("YYYY-MM-DD")
    );
  }, [departueDate, setValue]);

  useEffect(() => {
    setValue(
      "arrival_date_time",
      moment(returnDate, "DD/MM/YYYY").format("YYYY-MM-DD")
    );
  }, [departueDate, setValue]);

  useEffect(() => {
    setValue("time_departue_id", choosenTimeDepartue);
    setValue("time_arrival_id", choosenTimeArrival);



  }, [departueDate, returnDate, choosenTimeDepartue, choosenTimeArrival]);

  const hasNotGoodDay = (time /*Time is 00:00 - 00:15 */) => {
    if (
      //It delete the bed hours
      parseInt(moment().add(2, "hours").format("HH"), 10) >
        parseInt(time.substr(0, 2), 10) &&
      moment().format("DD/MM/yyyy") ===
        moment(departueDate)?.format("DD/MM/yyyy")
    ) {
      return true;
    }

    if (
      moment().format("DD/MM/yyyy") ===
        moment(departueDate)?.format("DD/MM/yyyy") && //same date departue as today
      parseInt(moment().format("HH"), 10) < 7 &&
      (parseInt(moment().format("HH"), 10) >= 0 ||
        parseInt(moment().format("HH"), 10) === 12 ||
        parseInt(moment().format("HH"), 10) === 24) &&
      parseInt(time.substr(0, 2), 10) < 12
    ) {
      return true;
    }
    return false;
  };


  // TO CALCULATE THE NEW PRICE
  {/*
  useEffect(() => {
    if (returnDate && departueDate) {
      let days = returnDate.diff(departueDate, "days")
        ? returnDate.diff(departueDate, "days")
        : 1;
      clientCredential
      .getCalculatedPrice({ departue_id: choosenPlace === choosenDepartue ? choosenDepartue.id : 2, days: days })
      .then((response) => {
          setCalculatedPrice(response.data);
        })
        .catch((error) => {});
    }
  }, [returnDate, departueDate, choosenDepartue]);
*/}


  // TO WATCH USEFORM
  const allFieldValues = watch();
 // console.log(JSON.stringify(allFieldValues, null, 2));

  const onSubmit = async (data) => {
  //  console.log("data", data);

    const choosenServicesSet = new Set(
      choosenServices.map((service) => service.id)
    );

    const mergedServices = [...choosenServicesSet].map((id) => ({ id: id }));

    const payload = {
      id: openModalEdit,

      // USER
      plate: data.plate,

      // PLACES
      place_id: 1,
      departue_place: data.departue_place,
      arrival_place: data.arrival_place,

      // DATES
      departue_date_time: moment(data.departue_date_time).format("yyyy-MM-DD"),
      arrival_date_time: moment(data.arrival_date_time).format("yyyy-MM-DD"),

      // TIME SLOTS
      time_departue_id: data.time_departue_id,
      time_arrival_id: data.time_arrival_id,
      has_baggage: data.has_bagage,

      // DESTINATION AND PROVENANCE
      departue_destination: data.departue_destination,
      return_destination: data.return_destination,

      // ADDITIONAL SERVICES
      extra_services: mergedServices,
    };

    try {
      const response = await authApi.adminEditRequest(openModalEdit, payload);
      console.log("payload", payload);
      setErrorApiPost(false);
    } catch (error) {
      console.log(error);
      setErrorApiPost(true);
    }

    window.location.reload();
  };

  const placeMap = {
    "rennes-airport": "Aéroport de Rennes",
    "rennes-trainstation": "Gare de Rennes",
    1: "rennes-airport",
    2: "rennes-trainstation",
    3: "rennes-trainstation",
  };

  const handleToggleChange = (serviceId, isChecked) => {
    let updatedChoosenServices;
    if (isChecked) {
      updatedChoosenServices = [...choosenServices, { id: serviceId }];
    } else {
      updatedChoosenServices = choosenServices.filter(
        (service) => service.id !== serviceId
      );
    }

    setChoosenServices(updatedChoosenServices);
    setValue(
      "services",
      updatedChoosenServices.map((service) => ({ id: service.id }))
    );
  };

  const calculateTotal = () => {
    let total = 0;

    choosenServices?.forEach((service) => {
      const filteredServices = additionalServices.filter(
        (additionalService) => {
          return !request.car_request.services.some((requestedService) => {
            return requestedService.id === additionalService.id;
          });
        }
      );
      const selectedService = filteredServices.find((s) => s.id === service.id);
      if (selectedService) {
        total += parseFloat(selectedService.price);
      }
    });

    return total;
  };

  return (
    <>
      <Modal show={true} onHide={() => onOpenCloseEditRequest()} size={"lg"}>
        <Modal.Header closeButton>
          <Row>
            <Col>
              <span className="title">Modifier la reservation</span>
            </Col>
          </Row>
        </Modal.Header>
        {!loadingApi ? Object.keys(request).length && (
          <Modal.Body
            style={{ marginTop: 0, paddingTop: 0, backgroundColor: "#FFF" }}
          >
            {Object.keys(request).length ? (
              <form onSubmit={handleSubmit(onSubmit)}>
                <>
                  <div style={{ marginTop: 15, padding: 5 }}>
                    <h2
                      style={{ color: "black", fontSize: 20, paddingLeft: 10 }}
                    >
                      Client
                    </h2>
                  </div>

                  <Row
                    style={{
                      backgroundColor: "#FFF",
                      borderRadius: "5px",
                      margin: "0px 10px",
                      border: "1px solid #00000015",
                      padding: "20px",
                    }}
                  >
                    <Col xs={12} lg={6} className="text-left">
                      <span style={{ fontFamily: "Satoshi-Bold" }}>
                        {request?.car_request?.client?.name ||
                          request?.car_request?.name}
                      </span>

                      <span> </span>
                      <p
                        style={{
                          display: "inline-block",
                          fontFamily: "Satoshi-Bold",
                        }}
                      >
                        {request?.car_request.client?.surname ||
                          request?.car_request?.surname}
                      </p>

                      <div>
                        <a href={`mailto:${request?.car_request?.email}`}>
                          {request?.car_request?.client?.email ||
                            request?.car_request?.email}{" "}
                        </a>
                      </div>
                    </Col>

                    <Col md={4}>
                      <Col md={12}>
                        <label className="label">Plaque</label>
                      </Col>
                      <Col md={12}>
                        <input
                          className="form-input"
                          type="text"
                          name="plate"
                          ref={register}
                          {...register("plate", { required: true })}
                          placeholder="Immatriculation"
                          defaultValue={request?.car_request?.plate}
                        />
                        {errors.plate && (
                          <span style={{ color: "red" }}>
                            Ce champ est requis
                          </span>
                        )}
                      </Col>
                    </Col>
                  </Row>
                </>

                <div style={{ marginTop: 15, padding: 5 }}>
                  <h2 style={{ color: "black", fontSize: 20, paddingLeft: 10 }}>
                    Aller
                  </h2>
                </div>

                <Row
                  style={{
                    backgroundColor: "#FFF",
                    borderRadius: "5px",
                    margin: "0px 10px",
                    border: "1px solid #00000015",
                    padding: "20px",
                  }}
                >
                  <Col md={4} style={{ paddingTop: 6 }}>
                    <label className="label">Lieu</label>
                    <select
                      className="form-select"
                      name="departue_place"
                      ref={register}
                      {...register("departue_place", { required: true })}
                      defaultValue={request?.car_request?.departue_place}
                    >
                      {filteredDepartues.map((departue, index) => (
                        <option key={index} value={departue.slug}>
                          {departue.name}
                        </option>
                      ))}
                      {/* Add more options here */}
                    </select>
                    {errors.arrival_place && (
                      <span style={{ color: "red" }}>Ce champ est requis</span>
                    )}
                  </Col>
                  <Col md={4} style={{ paddingTop: 6 }}>
                    <label className="label">Date</label>

                    <Datetime
                      timeFormat={false}
                      dateFormat={"DD/MM/YYYY"}
                      value={moment(departueDate).format("DD/MM/YYYY")}
                      onChange={handleDepartureDateTimeChange}
                      inputProps={{
                        className: "dateTimeStyle",
                        readOnly: true,
                      }}
                      isValidDate={disablePastDt}
                    />
                    <input
                      type="hidden"
                      name="departue_date_time"
                      ref={register}
                      {...register("departue_date_time", { required: true })}
                      defaultValue={moment(departueDate).format("YYYY-MM-DD")}
                    />

                    {errors.departue_date_time && (
                      <span style={{ color: "red" }}>Ce champ est requis</span>
                    )}
                  </Col>
                  <Col md={4} style={{ paddingTop: 6 }}>
                    <label className="label">Heure</label>
                    <select
                      className="form-select"
                      value={choosenTimeDepartue}
                      onChange={(e) => {
                        setChoosenTimeDepartue(e.target.value);
                      }}
                      //ref={register}
                      {...register("time_departue_id", { required: true })}
                    >
                      {timeDepartue?.map((time, index) =>
                        time.no_click || hasNotGoodDay(time.name) ? (
                          <option value={time.id} key={index} disabled>
                            {time.name} (complet)
                          </option>
                        ) : (
                          <option value={time.id}>{time.name}</option>
                        )
                      )}

                      {/* Add more options here */}
                    </select>
                    {errors.time_arrival_id && (
                      <span style={{ color: "red" }}>Ce champ est requis</span>
                    )}
                  </Col>
                  <Col md={4} style={{ paddingTop: 6 }}>
                    <label className="label">Destination</label>
                    <input
                      className="form-input"
                      type="text"
                      name="departue_destination"
                      ref={register}
                      {...register("departue_destination", { required: true })}
                      defaultValue={request?.car_request?.departue_destination}
                      placeholder="Destination"
                    />
                    {errors.departue_destination && (
                      <span style={{ color: "red" }}>Ce champ est requis</span>
                    )}
                  </Col>
                </Row>

                <div style={{ marginTop: 15, padding: 5 }}>
                  <h2 style={{ color: "black", fontSize: 20, paddingLeft: 10 }}>
                    Retour
                  </h2>
                </div>

                <Row
                  style={{
                    backgroundColor: "#FFF",
                    borderRadius: "5px",
                    margin: "0px 10px",
                    border: "1px solid #00000015",
                    padding: "20px",
                  }}
                >
                  <Col md={4} style={{ paddingTop: 6 }}>
                    <label className="label">Lieu</label>
                    <select
                      className="form-select"
                      name="arrival_place"
                      ref={register}
                      {...register("arrival_place", { required: true })}
                      defaultValue={request?.car_request?.arrival_place}
                    >
                      {filteredArrival.map((arrival, index) => (
                        <option key={index} value={arrival.slug}>
                          {arrival.name}
                        </option>
                      ))}
                      {/* Add more options here */}
                    </select>
                    {errors.arrival_place && (
                      <span style={{ color: "red" }}>Ce champ est requis</span>
                    )}
                  </Col>
                  <Col md={4} style={{ paddingTop: 6 }}>
                    <label className="label">Date</label>

                    <Datetime
                      timeFormat={false}
                      dateFormat={"DD/MM/YYYY"}
                      value={moment(returnDate).format("DD/MM/YYYY")}
                      onChange={handleArrivalDateTimeChange}
                      inputProps={{
                        className: "dateTimeStyle",
                        readOnly: true,
                      }}
                      isValidDate={disablePastDt}
                    />
                    <input
                      type="hidden"
                      name="arrival_date_time"
                      ref={register}
                      {...register("arrival_date_time", { required: true })}
                      defaultValue={moment(returnDate).format("YYYY-MM-DD")}
                    />

                    {errors.arrival_date_time && (
                      <span style={{ color: "red" }}>Ce champ est requis</span>
                    )}
                  </Col>
                  <Col md={4} style={{ paddingTop: 6 }}>
                    <label className="label">Heure</label>
                    <select
                      className="form-select"
                      value={choosenTimeArrival}
                      onChange={(e) => {
                        setChoosenTimeArrival(e.target.value);
                      }}
                      //ref={register}
                      {...register("time_arrival_id", { required: true })}
                    >
                      {timeArrival?.map((time, index) =>
                        time.no_click || hasNotGoodDay(time.name) ? (
                          <option value={time.id} key={index} disabled>
                            {time.name} (complet)
                          </option>
                        ) : (
                          <option value={time.id}>{time.name}</option>
                        )
                      )}

                      {/* Add more options here */}
                    </select>
                    {errors.time_arrival_id && (
                      <span style={{ color: "red" }}>Ce champ est requis</span>
                    )}
                  </Col>
                  <Col md={4} style={{ paddingTop: 6 }}>
                    <label className="label">Provenance</label>
                    <input
                      className="form-input"
                      type="text"
                      name="return_destination"
                      ref={register}
                      {...register("return_destination", { required: true })}
                      defaultValue={request?.car_request?.return_destination}
                      placeholder="Provenance retour"
                    />
                    {errors.return_destination && (
                      <span style={{ color: "red" }}>Ce champ est requis</span>
                    )}
                  </Col>

                  <Col md={4} style={{ paddingTop: 6 }}>
                    <label className="label">Bagage en soute</label>
                    <select
                      className="form-select"
                      name="has_bagage"
                      ref={register}
                      {...register("has_bagage")}
                      defaultValue={request?.car_request?.has_baggage ? 1 : 0}
                    >
                      <option value={0}>Non</option>
                      <option value={1}>Oui</option>

                      {/* Add more options here */}
                    </select>
                    {errors.has_bagage && (
                      <span style={{ color: "red" }}>Ce champ est requis</span>
                    )}
                  </Col>
                </Row>

                <div style={{ marginTop: 15, padding: 5 }}>
                  <h2 style={{ color: "black", fontSize: 20, paddingLeft: 10 }}>
                    Services additionnels
                  </h2>
                </div>

                <Row
                  style={{
                    backgroundColor: "#FFF",
                    borderRadius: "5px",
                    margin: "0px 10px",
                    border: "1px solid #00000015",
                    padding: "20px",
                  }}
                >
                  <Col style={{ padding: 10 }}>
                    {!loadingServices ? (
                      additionalServices.map((service, index) => {
                        const isChecked = request.car_request.services.find(
                          (reqService) => reqService.id === service.id
                        );

                        return (
                          <>
                            <Form.Check
                              className="black-toggle"
                              type="switch"
                              id={`flexSwitchCheckDefault-${index}`}
                              label={`${service.name} (${service.price}€)`}
                              style={{ color: "#000" }}
                              checked={isChecked}
                              disabled={isChecked}
                              onChange={(e) =>
                                handleToggleChange(service.id, e.target.checked)
                              }
                            />
                            <input type="hidden" {...register("services")} />
                          </>
                        );
                      })
                    ) : (
                      <LoadingButton
                        loading={loading}
                        variant="primary"
                        type="submit"
                      >
                        Enregistrer
                      </LoadingButton>
                    )}
                  </Col>
                  <span>
                    Reste à payer :{" "}
                    {additionalServices && choosenServices && calculateTotal()}€
                  </span>
                </Row>
                <Row></Row>
                <div className="mt-4 text-center">
                  <Button
                    variant="primary"
                    type="submit"
                    style={{
                      backgroundColor: "#000",
                      color: "#fff",
                      border: "none",
                    }}
                  >
                    Enregistrer
                  </Button>

                  {errorApi && (
                    <AlertGeneral message="Une erreur est survenue lors de l'enregistrement." />
                  )}
                </div>
              </form>
            ) : (
              <LoadingButton loading={loading} variant="primary" type="submit">
                Enregistrer
              </LoadingButton>
            )}
          </Modal.Body>
        ) : 
        (  <LoadingButton loading={loading} variant="primary" type="submit">
        
      </LoadingButton>)
          }
      </Modal>
    </>
  );
};

export default ModalEdit;
