import React, {useState, useEffect} from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
//-------------- Components ----------------\
import TextBox from '../../../Components/Commons/FormComponent/TextBox';
import LoadingButton from '../../../Components/Commons/LoadingButton/LoadingButton';
import ModalSimple from '../../../Components/Commons/ModalSimple/ModalSimple';
import AlertGeneral from '../../../Components/Commons/AlertGeneral/AlertGeneral';
//-------------- Others --------------------
import { useForm } from 'react-hook-form';
import {Link, useParams, useHistory} from 'react-router-dom'
import * as authApi from '../../../Axios/Call/AuthApi';
import {BiArrowBack} from 'react-icons/bi';
//------------- Style -----------------------
import classes from './AdditionalServicesAddEdit.module.css'
import ImageUploader from "react-images-upload";

const AdditionalServicesList = () => {
    const {id} = useParams();
    const history = useHistory();
    const [service, setService] = useState({});
    const [base64, setBase64] = useState('');
    const [errorBase64, setErrorBase64] = useState(false);
    const [loadingApi, setLoadingApi] = useState(false);
    const [errorApi, setErrorApi] = useState(false);


    const { control, register, handleSubmit, errors, watch, reset } = useForm({
        defaultValues:{
            name: '',
            price: '',
            description:'',
        }
    }); // initialise the hook

    useEffect(() => {
        if(id){
            setLoadingApi(true);
            authApi.getOneAdditionalService(id).then(response => {
                setService(response.data);
              
                reset(response.data);
                setLoadingApi(false);
            }).catch(error => {
                setLoadingApi(false);
                setErrorApi(true);
            })
        }

    },[id]);

    const onClickAddServiceHandler = (data) => {
        if(id){
            setLoadingApi(true);
            authApi.editAdditionalService(id,{...data, base64: base64}).then(response => {
                history.push('/additional-services');
          
                setLoadingApi(false);
            }).catch(error => {
                setErrorApi(true);
                setLoadingApi(false);
            })
        }else{
            if(!base64){
                setErrorBase64(true);
                return false;
            }
            setLoadingApi(true)
            authApi.createAdditionlService({...data, base64: base64}).then(response => {
                history.push('/additional-services');
                setLoadingApi(false);
            }).catch(error => {
                setErrorApi(true);
                setLoadingApi(false);
            })
        }
    }

    const onUploadPictureHandler = (file) => {
        file=file[0];
        let reader = new FileReader();
        reader.onloadend = (e) => {
            setBase64(reader.result);
            setErrorBase64(false);
        };

        reader.readAsDataURL(file);

    }

    return(<>
        {errorApi ? <AlertGeneral color='danger' description='Error creating service' setTrigger={setErrorApi} /> : null}
        <Container fluid>
            <Row style={{marginTop: '2rem'}}>
                <Col className='text-left'>
                    <Row>
                        <Col className='my-auto' xs={1}>
                            <Link to='/additional-services'>
                                <BiArrowBack size={38} color={'#1C222D'}/>
                            </Link>
                        </Col>
                        <Col className='text-left'>
                            <span className='title'>
                                {id && Object.keys(service).length ? 'Modifier '+service.name: "Création d'un service additionel" }
                            </span>
                        </Col>
                    </Row>
                </Col>
            </Row>
            { ( id && Object.keys(service).length ) || !id ? 
            <Form onSubmit={handleSubmit(onClickAddServiceHandler)} >
                <Row style={{ marginTop: '4rem'}}>
                    <Col xs={12} lg={6} >
                        
                        <TextBox ref={register({ required: 'true' })} placeHolder={''} type={'input'} name={'name'}
                            error={ errors.name ?  'Field Required' : ''} offset={0} span={12} labelName={'Nom du service'}
                            labelStyle={{color: 'black'}}
                            errorStyle={{color: 'red'}}
                        />
                    </Col>
               

                   
                    <Col xs={12} lg={6} >
                        <Row>
                            <Col className='text-left'>
                                <Form.Label className={classes.formLabelStyle} >Prix du service ( € )</Form.Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} lg={12} >
                                <Form.Control ref={register({ required: 'true' })} 
                                    placeHolder={''} 
                                    type={'number'} 
                                    name={'price'}
                                    className='textStyle'
                                    style={{width: '100%'}}
                                    step={0.01}
                                />
                            </Col>
                        </Row>
                        <Row><Col> {errors.price && ( <span className='errorStyle'>{errors.price}</span>)}</Col></Row>
                    </Col>
                </Row>
                
                <Row style={{marginTop: '1rem'}}> 
                <Col xs={12} lg={6} >
                        <Row>
                            <Col className='text-left'>
                                <Form.Label className={classes.formLabelStyle} >Description du service</Form.Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} lg={12} >
                                <Form.Control  ref={register({ required: 'true' })} 
                                      placeholder={''} 
                                      as={'textarea'}
                                    rows={5}
                                    name={'description'}
                                    style={{width: '100%', lineHeight: '1'}}
                                    className='textStyle'
                                    step={0.01}
                              
                                />
                            </Col>
                        </Row>
                        <Row><Col> {errors.description && ( <span className='errorStyle'>{errors.description}</span>)}</Col></Row>
                    </Col>
                  
                    <Col xs={12} lg={6} >
                        <Row >
                            <Col >
                                <ImageUploader
                                
                                    buttonText={'télécharger des photos'}
                                    label=''
                                    onChange={(event) => onUploadPictureHandler(event)}
                                    imgExtension={['.jpg', '.png','.jpeg']}
                                    buttonClassName={'buttonGeneralStyle'}
                                    labelClass={'textStyle'}
                                    maxFileSize={5242880}
                                />
                            </Col>
                        </Row>
                        {errorBase64 ? 
                            <Row><Col className='text-left'><span className='text-danger'>
                                Veuillez insérer une image</span></Col></Row>
                        : null }          
                    </Col>
              

                  
                    <Col xs={12} lg={6}>
                        {base64 ? 
                            <img style={{width: '50%'}} src={ base64 } />
                        : service.base64 ?
                            <img src={'data:image/png;base64,'+service.base64} style={{width: '25%'}} />
                        : null
                        }
                    </Col>
                </Row>


                <Row style={{ marginTop: '3rem'}}>
                    <Col xs={12} lg={12} >
                    <Col>
                        <Button type='submit' className={'buttonGeneralStyle'} style={{width: 200}}><span >
                            {id ? 'Modifier le service additionnel' : 'Créer le service' }
                        </span></Button>
                    </Col>
                    </Col>
                </Row>
            </Form>
            : <LoadingButton /> }
        </Container>
    </>)
}

export default AdditionalServicesList;