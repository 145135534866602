import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import classes from "./ModalView.module.css";
import { useSelector } from "react-redux";
import * as authApi from "../../../Axios/Call/AuthApi";
import AlertGeneral from "../../Commons/AlertGeneral/AlertGeneral";
import LoadingButton from "../../Commons/LoadingButton/LoadingButton";
import moment from "moment";

const ModalView = ({ onOpenCloseViewRequest, openModalView, renderStatus }) => {
  const [request, setRequest] = useState({});
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const userData = useSelector((state) => state.user.userData);

  useEffect(() => {
    if (Object.keys(userData).length) {
      setLoadingApi(true);
      if (userData.type === "admin") {
        authApi
          .adminGetRequest(openModalView)
          .then((response) => {
            setLoadingApi(false);
            setRequest(response.data);
          })
          .catch((error) => {
            setLoadingApi(false);
          });
      }
      if (userData.type === "driver") {
        authApi
          .driverGetRequest(openModalView)
          .then((response) => {
            setLoadingApi(false);
            setRequest(response.data);
          })
          .catch((error) => {
            setLoadingApi(false);
          });
      }
    }
  }, [userData]);

  const placeMap = {
    "rennes-airport": "Aéroport de Rennes",
    "rennes-trainstation": "Gare de Rennes",
    1: "Aéroport de Rennes",
    3: "Gare de Rennes",
  };

  return (
    <>
      <Modal show={true} onHide={() => onOpenCloseViewRequest()} size={"lg"}>
        <Modal.Header closeButton>
          <Row>
            <Col>
              <span className="title">Détails de la reservation</span>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body style={{ marginTop: 0, paddingTop: 0 }}>
          <Row>
            <Col className="text-left">
              {Object.keys(request).length ? (
                <>
                  <div style={{ marginTop: 10, padding: 5 }}>
                  <h2 style={{ color: "black", fontSize: 20, paddingLeft: 10 }}>
                    Client
                  </h2>
                </div>
                <Row
                  style={{
                    padding: 20,
                    backgroundColor: "#FFF",
                    borderRadius: "5px",
                    margin: "0px 10px",
                    border: "1px solid #00000015",
                  }}
                >
                    <Col xs={12} lg={6} className="text-left">
                      <span>
                        Prénom:{" "}
                        {request?.car_request?.client?.name ||
                          request?.car_request?.name}
                      </span>
                    </Col>
                    <Col xs={12} lg={6} className="text-left">
                      <span>
                        Nom:{" "}
                        {request?.car_request.client?.surname ||
                          request?.car_request?.surname}
                      </span>
                    </Col>
                    <Col xs={12} lg={6} className="text-left">
                      <span>
                        Mail:{" "}
                        {request?.car_request?.client?.email ||
                          request?.car_request?.email}{" "}
                      </span>
                    </Col>
                    <Col xs={12} lg={6} className="text-left">
                      <span>
                        Téléphone:{" "}
                        {request?.car_request?.client?.phone ||
                          request?.car_request?.phone}{" "}
                      </span>
                    </Col>
                  </Row>



                  <div style={{ marginTop: 10, padding: 5 }}>
                  <h2 style={{ color: "black", fontSize: 20, paddingLeft: 10 }}>
                    Prise en charge
                  </h2>
                </div>
                <Row
                  style={{
                    padding: 20,
                    backgroundColor: "#FFF",
                    borderRadius: "5px",
                    margin: "0px 10px",
                    border: "1px solid #00000015",
                  }}
                >
                    <Col xs={12} lg={6} className="text-left">
                      <span>
                        Aller :{" "}
                        <b style={{ fontFamily: "Satoshi-black" }}>
                          {request.car_request.departue_place
                            ? placeMap[request.car_request.departue_place]
                            : placeMap[request.car_request.place_id]}
                        </b>
                      </span>
                    </Col>
                    <Col xs={12} lg={6} className="text-left">
                      <span>
                        Retour :{" "}
                        <b style={{ fontFamily: "Satoshi-black" }}>
                          {request.car_request.arrival_place
                            ? placeMap[request.car_request.arrival_place]
                            : placeMap[request.car_request.place_id]}
                        </b>
                      </span>
                    </Col>
                    <Col xs={12} lg={6} className="text-left">
                      <span>
                        Date de départ :{" "}
                        <b style={{ fontFamily: "Satoshi-black" }}>
                          {moment(
                            request.car_request.departue_date_time
                          ).format("DD/MM/YYYY")}{" "}
                        </b>
                        {request.car_request.departueTime.name}
                      </span>
                    </Col>
                    <Col xs={12} lg={6} className="text-left">
                      <span>
                        Date de retour:{" "}
                        <b style={{ fontFamily: "Satoshi-black" }}>
                          {moment(request.car_request.arrival_date_time).format(
                            "DD/MM/YYYY"
                          )}{" "}
                        </b>
                        {request.car_request.arrivalTime.name}
                      </span>
                    </Col>

                    {userData.type === "admin" ? (
                      <>
                        <Col xs={12} lg={6} className="text-left">
                          <span>
                            Prix:{" "}
                            {(request.car_request.pay_amount / 100).toFixed(2)}
                            {" €"}
                          </span>
                        </Col>
                      </>
                    ) : null}





                  {request?.car_request?.plate && <Col xs={12} lg={12} className="text-left mt-2">
                      <span >
                        <b style={{ fontFamily: "Satoshi-black" }}>Véhicule:</b> {request?.car_request?.car_brand} {request?.car_request?.car_model} {request?.car_request?.car_color} ({request.car_request.plate})
                      </span>
                  </Col>}



                    <Col xs={12} lg={6} className="text-left mt-2">
                      <span>
                        Destination à l'aller :{" "}
                        {request.car_request.departue_destination}{" "}
                      </span>
                    </Col>
                    <Col xs={12} lg={6} className="text-left mt-2">
                      <span>
                        Provenance au retour :{" "}
                        {request.car_request.return_destination}{" "}
                      </span>
                    </Col>
                    <Col xs={12} lg={6} className="text-left mt-2">
                      <span>
                        Bagage en soute :{" "}
                        <b style={{ fontFamily: "Satoshi-black" }}>
                          {request.car_request.has_baggage ? "Oui" : "Non"}{" "}
                        </b>
                      </span>
                    </Col>

                    <Col
                      xs={12}
                      lg={12}
                      className="text-left"
                      style={{ paddingTop: 10 }}
                    >
                      <span style={{ fontFamily: "Satoshi-black" }}>
                        Statut actuel :{" "}
                        {renderStatus(request.car_request.status)}
                      </span>
                    </Col>
                  </Row>

                

                  <div style={{ marginTop: 10, padding: 5 }}>
                  <h2 style={{ color: "black", fontSize: 20, paddingLeft: 10 }}>
                  Détails sur la réservation
                  </h2>
                </div>

                <Row
                  style={{
                    padding: 20,
                    backgroundColor: "#FFF",
                    borderRadius: "5px",
                    margin: "0px 10px",
                    border: "1px solid #00000015",
                  }}
                >
                    <Col xs={12} lg={6} className="text-left">
                      <span>
                        Réservé depuis :{" "}
                        {request.car_request.fligth_number
                          ? request.car_request.fligth_number
                          : "non disponible"}
                      </span>
                    </Col>
                    <Col xs={12} lg={6} className="text-left">
                      <span>
                        Date de la réservation :{" "}
                        {moment(request.car_request.created_at).format(
                          "DD/MM/YYYY [à] HH:mm"
                        )}
                      </span>
                    </Col>
                    <Col xs={12} lg={6} className="text-left">
                      <span>
                        Numéro de réservation : {request.car_request.id}{" "}
                      </span>
                    </Col>

                    <Col xs={12} lg={6} className="text-left">
                      <span
                        style={{
                          color:
                            request.car_request.insurance === 1
                              ? "green"
                              : "red",
                        }}
                      >
                        Assurance annulation :{" "}
                        <b style={{ fontFamily: "Satoshi-black" }}>
                          {request.car_request.insurance === 1 ? "Oui" : "Non"}
                        </b>
                      </span>
                    </Col>
                  </Row>

                  {userData.type === "admin" ? (
                    <>
                        <div style={{ marginTop: 10, padding: 5 }}>
                  <h2 style={{ color: "black", fontSize: 20, paddingLeft: 10 }}>
                  Voiturier
                  </h2>
                </div>
                      {request.car_request.driver ? (
                         <Row
                         style={{
                           padding: 20,
                           backgroundColor: "#FFF",
                           borderRadius: "5px",
                           margin: "0px 10px",
                           border: "1px solid #00000015",
                         }}
                       >
                          <Col xs={12} lg={6} className="text-left">
                            <span>
                              <b>Prénom: </b>
                              {request.car_request.driver.name}
                            </span>
                          </Col>
                          <Col xs={12} lg={6} className="text-left">
                            <span>
                              Nom: {request.car_request.driver.surname}
                            </span>
                          </Col>
                          <Col xs={12} lg={6} className="text-left">
                            <span>
                              Mail: {request.car_request.driver.email}{" "}
                            </span>
                          </Col>
                          <Col xs={12} lg={6} className="text-left">
                            <span>
                              Téléphone: {request.car_request.driver.phone}{" "}
                            </span>
                          </Col>
                          <Col xs={12} lg={6} className="text-left">
                            <span>
                              Adresse: {request.car_request.driver.address}{" "}
                            </span>
                          </Col>
                          <Col xs={12} lg={6} className="text-left">
                            <span>
                              Permis: {request.car_request.driver.license}{" "}
                            </span>
                          </Col>
                        </Row>
                      ) : (
                        <Row
                  style={{
                    padding: 20,
                    backgroundColor: "#FFF",
                    borderRadius: "5px",
                    margin: "0px 10px",
                    border: "1px solid #00000015",
                  }}
                >
                          <Col style={{ padding: 10 }}>
                            <span>Voiturier non affecté</span>
                          </Col>
                        </Row>
                      )}
                    </>
                  ) : null}

                

                  <div style={{ marginTop: 10, padding: 5 }}>
                  <h2 style={{ color: "black", fontSize: 20, paddingLeft: 10 }}>
                  Services additionels
                  </h2>
                </div>
                <Row
                  style={{
                    padding: 20,
                    backgroundColor: "#FFF",
                    borderRadius: "5px",
                    margin: "0px 10px",
                    border: "1px solid #00000015",
                  }}
                >
                    <Col style={{ padding: 10 }}>
                      {request.car_request.services.length ? (
                        request.car_request.services.map((service) => {
                          return (
                            <Col xs={12} lg={6} className="text-left">
                              <span>{service.name}</span>
                            </Col>
                          );
                        })
                      ) : (
                        <p>Aucun</p>
                      )}
                    </Col>
                  </Row>

                  {Object.keys(request).length && request.forms.length ? (
                    <>
                      <Row style={{ marginTop: 10 }}>
                        <Col
                          style={{ backgroundColor: "#232D4C", padding: 10 }}
                        >
                          <span style={{ color: "white", fontSize: 20 }}>
                            Prise en charge
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {request.forms.map((form, index) => {
                            return (
                              <>
                                <Row style={{ marginTop: 10, padding: 10 }}>
                                  <Col>
                                    <span
                                      style={{
                                        fontSize: 18,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {index === 0
                                        ? "Formulaire de prise en charge"
                                        : "Formulaire de retour"}
                                    </span>
                                  </Col>
                                </Row>
                                <Row style={{ marginTop: 10, padding: 10 }}>
                                  <Col xs={12} lg={6}>
                                    <span>
                                      Date:{" "}
                                      {moment(form.created_at).format(
                                        "DD/MM/YYYY HH:mm"
                                      )}
                                    </span>
                                  </Col>
                                  <Col xs={12} lg={6}>
                                    <span>Kilométrage: {form.km}</span>
                                  </Col>
                                </Row>

                                <Row style={{ marginTop: 10, padding: 10 }}>
                                  {form.base64.map((image) => {
                                    return (
                                      <Col
                                        className="colMarginForm"
                                        xs={12}
                                        lg={6}
                                      >
                                        <img
                                          className={classes.imageStyle}
                                          src={"data:image/png;base64," + image}
                                          style={{ width: "100%" }}
                                        />
                                      </Col>
                                    );
                                  })}
                                </Row>
                                <Row style={{ marginTop: 10, padding: 10 }}>
                                  <Col className="text-left">
                                    <span>Commentaires: {form.comments}</span>
                                  </Col>
                                </Row>
                              </>
                            );
                          })}
                        </Col>
                      </Row>
                    </>
                  ) : null}
                </>
              ) : (
                <LoadingButton />
              )}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalView;
