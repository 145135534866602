import React, { useEffect, useState } from "react";
//-------------- Components ----------------\
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral";
//-------------- Others --------------------
import { Controller, useForm } from "react-hook-form";
import * as authApi from "../../../Axios/Call/AuthApi";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import classes from "./Profile.module.css";
import placeholderImg from "../../../Assets/Images/placeholderPic.png";
import { useDispatch, useSelector } from "react-redux";
import * as actionCreator from "../../../store/action/index";

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const Profile = () => {
  const [errorApiPost, setErrorApiPost] = useState(false);
  const [successApiPost, setSuccessApiPost] = useState(false);
  const [image, setImage] = useState(null);
  const userData = useSelector((state) => state.user.userData);
  const dispatch = useDispatch();


  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(actionCreator.populateUserData());
    }
  }, [dispatch]);

  const { handleSubmit, errors, reset, control } = useForm({
    defaultValues: {
      firstname: "asdf",
      lastname: "",
      company: "",
      email: "",
      phone: "",
    },
  });

  useEffect(() => {
    if (userData) {
      reset({
        firstname: userData.name,
        lastname: userData.surname,
        email: userData.email,
        phone: userData.phone,
        company: userData.company,
      });
    }
   
    
  }, [userData, reset]);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const base64 = await toBase64(file);
    setImage(base64);
  };

  const emailValidator = (value) =>
    value.trim().match(/^.+@.+$/) || "Email invalide";

  const onSubmit = async (data) => {
    const payload = {
      name: data.firstname,
      surname: data.lastname,
      phone: data.phone,
      company: data.company,
      email: data.email,
      profile_jpeg_base64: image,
    };

    try {
      const response = await authApi.updateProfile(payload);
   
      setSuccessApiPost(true);
    } catch (error) {
      console.log(error);
      setErrorApiPost(true);
    } finally {
      dispatch(actionCreator.populateUserData());
    }
  };



  return (
    <>
      {successApiPost ? (
        <AlertGeneral
          color="success"
          description="Votre compte a bien été mis à jour"
          setTrigger={setSuccessApiPost}
        />
      ) : null}
      {errorApiPost ? (
        <AlertGeneral
          color="danger"
          description="Il y a eu une erreur lors de la création"
          setTrigger={setErrorApiPost}
        />
      ) : null}
      <Container>
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
          <label>
            <Form.Group>
              <div className={classes.profilePhoto}>
                <img
                  src={image ? image : userData.profile_picture_url ? userData.profile_picture_url : placeholderImg}
                  alt="profil"
                  width={100}
                />
              </div>
              <input
                type="file"
                hidden
                name="profileImage"
                accept="image/jpeg"
                onChange={handleImageChange}
              />
              <Form.Label>
                Cliquer sur la photo pour modifier (.jpeg uniquement)
              </Form.Label>
          
            </Form.Group>
          </label>
          <Row>
            <Col md={6}>
              <Form.Group>
                <Form.Label className={classes.label}>Prénom</Form.Label>
                <Controller
                  control={control}
                  name="firstname"
                  rules={{
                    required: "Prénom requis",
                  }}
                  render={(field, { invalid }) => (
                    <>
                      <Form.Control
                        type="text"
                        {...field}
                        placeholder="Entrez votre prénom"
                        isInvalid={invalid}
                      />
                      {errors.firstname && (
                        <Form.Text className="text-danger">
                          {errors.firstname.message}
                        </Form.Text>
                      )}
                    </>
                  )}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label className={classes.label}>
                  Nom de famille
                </Form.Label>
                <Controller
                  control={control}
                  name="lastname"
                  rules={{
                    required: "Nom de famille requis",
                  }}
                  render={(field, { invalid }) => (
                    <>
                      <Form.Control
                        type="text"
                        {...field}
                        placeholder="Entrez votre nom de famille"
                        isInvalid={invalid}
                      />
                      {errors.lastname && (
                        <Form.Text className="text-danger">
                          {errors.lastname.message}
                        </Form.Text>
                      )}
                    </>
                  )}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label className={classes.label}>Entreprise</Form.Label>
                <Controller
                  control={control}
                  name="company"
                  render={(field, { invalid }) => (
                    <>
                      <Form.Control
                        type="text"
                        {...field}
                        placeholder="Facultatif"
                        isInvalid={invalid}
                      />
                      {errors.company && (
                        <Form.Text className="text-danger">
                          {errors.company.message}
                        </Form.Text>
                      )}
                    </>
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group>
                <Form.Label className={classes.label}>Email</Form.Label>
                <Controller
                  control={control}
                  name="email"
                  rules={{
                    required: "Email requis",
                    validate: emailValidator,
                  }}
                  render={(field, { invalid }) => (
                    <>
                      <Form.Control
                        type="email"
                        {...field}
                        placeholder="Entrez votre adresse email"
                        isInvalid={invalid}
                      />
                      {errors.email && (
                        <Form.Text className="text-danger">
                          {errors.email.message}
                        </Form.Text>
                      )}
                    </>
                  )}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label className={classes.label}>Téléphone</Form.Label>
                <Controller
                  control={control}
                  name="phone"
                  rules={{
                    required: "Numéro de téléphone requis",
                  }}
                  render={(field, { invalid }) => (
                    <>
                      <Form.Control
                        type="tel"
                        {...field}
                        placeholder="Entrez votre numéro de téléphone"
                        isInvalid={invalid}
                      />
                      {errors.phone && (
                        <Form.Text className="text-danger">
                          {errors.phone.message}
                        </Form.Text>
                      )}
                    </>
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12} style={{ paddingTop: 30 }}>
              <Button
                variant="primary"
                type="submit"
                className={classes.button}
              >
                Enregistrer le profil
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default Profile;
