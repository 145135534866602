import React, {useState, useEffect} from 'react'
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classes from './AlertGeneral.module.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface Props {
    color: string,
    closeAlone?: Boolean,
    description: string,
    setTrigger: Function,
}
const AlertGeneral:React.FC<Props> = ({color,closeAlone,description,setTrigger}) => {
    useEffect( () => {
        const time = setTimeout(() => setTrigger(false), 10000)
        return() => {
            clearTimeout(time)
        }
    },[])

    if(color === 'danger'){
        toast.error(description, {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }else{
        toast.success(description, {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    return(
        <>
        {/*
        <Alert className={classes.alertStyle} variant={color} onClose={() => setTrigger(false)} dismissible>
            <Row>
                <Col className='text-center'>
                    <span className={classes.description}>{description}</span>
                </Col>
            </Row>
        </Alert>*/}
        <ToastContainer
            position="top-right"
            autoClose={10000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            onClick={() => setTrigger(false)}
            rtl={false}
            pauseOnFocusLoss
            draggable
            limit={1}
            />
        </>
    );
}


export default AlertGeneral;