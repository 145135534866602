import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import moment from "moment";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { AiOutlineForm, AiFillCar } from "react-icons/ai";
import { FaCalculator, FaParking } from "react-icons/fa";
import classes from "./Step2.module.css";
import { useWindowWidth } from "@react-hook/window-size";

const Step2 = ({
  departueDate,
  returnDate,
  choosenPlace,
  choosenDepartue,
  onClickSecondStepDone,
  editInformation,
  choosenTimeArrival,
  choosenTimeDepartue,
  calculatedPrice,
  promoCode,
}) => {
  const userData = useSelector((state) => state.user.userData);
  const history = useHistory();
  const screenWidth = useWindowWidth();
  return (
    <>
      <Row>
        <Col>
          <h1 className={"title"} style={{fontSize:'40px'}}>VOTRE RÉSERVATION</h1>
        </Col>
      </Row>
      <Row >
        <Col>
          <span className={"labelStyleSteps"} style={{paddingRight:"10px"}} onClick={() => editInformation()}>
            Modifer
          </span>
          <AiOutlineForm
            size={20}
            onClick={() => editInformation()}
            className={classes.iconStyle}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "2rem" }}>
        <Col xs={12} lg={4}>
          <Row>
            <Col className="text-left">
              <span className="labelStyleSteps">Prise en charge</span>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card style={{ paddingTop: 10, paddingBottom: 10 }}>
                <span
                  style={{
                    color: "black",

                    fontSize: 16,
                  }}
                >
                  {choosenDepartue.name}
                </span>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xs={12} lg={4}>
          <Row>
            <Col className="text-left">
              <span className="labelStyleSteps">Retour du véhicule</span>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card style={{ paddingTop: 10, paddingBottom: 10 }}>
                <span
                  style={{
                    color: "black",

                    fontSize: 16,
                  }}
                >
                  { choosenPlace.name}
                </span>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xs={12} lg={4}>
          <Row>
            <Col className="text-left">
              <span className="labelStyleSteps">Votre voyage</span>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card style={{ paddingTop: 10, paddingBottom: 10 }}>
                <span style={{ color: "black", fontSize: 16 }}>
                  {"Du  " +
                    departueDate.format("DD/MM/YYYY") +
                    " " +
                    choosenTimeDepartue.name +
                    " au " +
                    returnDate.format("DD/MM/YYYY") +
                    " " +
                    choosenTimeArrival.name}
                </span>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>

      {screenWidth > 1025 ? (
        <>
          <Row style={{ marginTop: "1rem" }}>
            <Col xs={12} lg={4} className="my-auto">
              <Row>
                <Col className="text-left">
                  <Row>
                    <Col>
                      <span className="labelStyleSteps">Tarif Arvin Care</span>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: -10 }}>
                    <Col>
                      <span
                        className="labelStyleSteps"
                        style={{ fontSize: 14 }}
                      >
                        (parking + voiturier + assurance)
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={12} lg={4} className="my-auto">
              <Row>
                <Col className="text-left">
                  <span className="labelStyleSteps">
                    Coût moyen des parkings locaux
                  </span>
                </Col>
              </Row>
            </Col>
            <Col className="my-auto">
              <Row>
                <Col className="text-left">
                  <span className="labelStyleSteps">Economie réalisée</span>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col xs={12} lg={4} className="my-auto">
              <Row>
                <Col>
                  <Card
                    style={{
                      paddingTop: 10,
                      paddingBottom: 10,
                      borderColor: "white",
                      backgroundColor: "#232D4C",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <AiFillCar color="white" />
                      <span
                        style={{
                          color: "white",
                          fontSize: 22,
                          fontFamily:"Satoshi-Bold",
                          paddingLeft: 10,
                        }}
                      >
                        {calculatedPrice.total_our_price &&
                          calculatedPrice.total_our_price.toFixed(2)}{" "}
                        € {moment(returnDate).diff(moment(departueDate), 'days') > 1 && <b style={{fontSize:14, fontFamily:"Satoshi-Regular"}}>({(calculatedPrice?.total_our_price/(moment(returnDate).diff(moment(departueDate), 'days'))).toFixed(2)}€ par jour)</b>}
                      </span>
                    </div>
                    <span
                      style={{
                        color: "white",
                        fontSize: 18,
                        fontWeight: "400",
                        paddingLeft: 10,
                      }}
                    >
                      {promoCode &&
                        "Code " +
                          promoCode.code +
                          " (-" +
                          promoCode.price +
                          (promoCode.type === "percentage" ? "%)" : "€)") +
                          " appliqué"}
                    </span>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col xs={12} lg={4}>
              <Row style={{ height: "100%" }}>
                <Col style={{ height: "100%" }}>
                  <Card
                    style={{
                      paddingTop: 10,
                      paddingBottom: 10,
                      borderColor: "white",
                      backgroundColor: "#8F94A0",
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <FaParking color="white" />
                      <span
                        style={{
                          color: "white",
                          fontSize: 18,
                          paddingLeft: 10,
                        }}
                      >
                        {calculatedPrice.total_their_price &&
                          calculatedPrice.total_their_price.toFixed(2)}{" "}
                        €
                      </span>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row style={{ height: "100%" }}>
                <Col style={{ height: "100%" }}>
                  <Card
                    style={{
                      paddingTop: 10,
                      paddingBottom: 10,
                      borderColor: "white",
                      backgroundColor: "#5F9EA0",
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <FaCalculator color="white" />
                      <span
                        style={{
                          color: "white",
                          fontSize: 22,
                          fontFamily:"Satoshi-Bold",
                          paddingLeft: 10,
                        }}
                      >
                        {calculatedPrice.percentage &&
                          calculatedPrice.percentage.toFixed(2)}{" "}
                        €
                      </span>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row style={{ marginTop: "1rem" }}>
            <Col xs={12} lg={4} className="my-auto">
              <Row>
                <Col className="text-left">
                  <Row>
                    <Col>
                      <span className="labelStyleSteps">Tarif ARVIN CARE</span>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: -10 }}>
                    <Col>
                      <span
                        className="labelStyleStepsSubtitle"
                        style={{ fontSize: 10 }}
                      >
                        (parking + voiturier + assurance)
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card
                    style={{
                      paddingTop: 10,
                      paddingBottom: 10,
                      borderColor: "white",
                      backgroundColor: "#232D4C",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <AiFillCar color="white" />
                      <span
                        style={{
                          color: "white",
                          fontSize: 16,
                          fontWeight: "600",
                          paddingLeft: 10,
                        }}
                      >
                        {calculatedPrice.total_our_price &&
                          calculatedPrice.total_our_price.toFixed(2)}{" "}
                        €
                      </span>
                    </div>
                    <span
                      style={{
                        color: "white",
                        fontSize: 12,
                        fontWeight: "400",
                        paddingLeft: 10,
                      }}
                    >
                      {promoCode &&
                        "Code " +
                          promoCode.code +
                          " -" +
                          promoCode.price +
                          (promoCode.type === "percentage" ? "%" : "€") +
                          " appliqué"}
                    </span>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col xs={12} lg={4} className="my-auto">
              <Row>
                <Col className="text-left">
                  <span className="labelStyleSteps">
                    COÛT MOYEN DES PARKINGS LOCAUX
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card
                    style={{
                      paddingTop: 10,
                      paddingBottom: 10,
                      borderColor: "white",
                      backgroundColor: "#8F94A0",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <FaParking color="white" />
                      <span
                        style={{
                          color: "white",
                          fontSize: 16,
                          paddingLeft: 10,
                        }}
                      >
                        {calculatedPrice.total_their_price &&
                          calculatedPrice.total_their_price.toFixed(2)}{" "}
                        €
                      </span>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col className="my-auto">
              <Row>
                <Col className="text-left">
                  <span className="labelStyleSteps">ECONOMIE RÉALISÉE</span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card
                    style={{
                      paddingTop: 10,
                      paddingBottom: 10,
                      borderColor: "white",
                      backgroundColor: "#5F9EA0",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <FaCalculator color="white" />
                      <span
                        style={{
                          color: "white",
                          fontSize: 16,
                          paddingLeft: 10,
                        }}
                      >
                        {calculatedPrice.percentage &&
                          calculatedPrice.percentage.toFixed(2)}{" "}
                        €
                      </span>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
      <Row style={{ marginTop: "2rem" }}>
        <Col xs={12} lg={{ span: 8, offset: 2 }}>
          <Row>
            {Object.keys(userData).length ? (
              <Col>
                <Button
                   onClick={() => editInformation()}
                  className={"buttonGeneralStyleMenu"}
                  style={{ width: 150, marginRight: 10 }}
                >
                  <span>Retour</span>
                </Button>
                <Button
                  className="buttonGeneralStyle"
                  onClick={() => onClickSecondStepDone()}
                >
                  Suivant
                </Button>
              </Col>
            ) : (
              <>
                <Col>
                  <Button
                    onClick={() => editInformation()}
                    className={"buttonGeneralStyleMenu"}
                    style={{ width: 150, marginRight: 10 }}
                  >
                    <span>Retour</span>
                  </Button>
                  <Button
                    className="buttonGeneralStyle"
                    onClick={() => history.push("/login")}
                    //style={{ width: screenWidth > 1025 ? 250 : "90%" }}
                  >
                    Se connecter pour réserver
                  </Button>
                </Col>
              </>
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Step2;
