import React, {useEffect, useState} from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image';
//-------------- Compoents ----------------------
import * as clientCredentialApi from '../../../Axios/Call/ClientCredential';
import LoadingButton from '../../../Components/Commons/LoadingButton/LoadingButton';
//------------- Others ------------------------
import {useParams, Link} from 'react-router-dom';
import classes from './EmailConfirmation.module.css'
import logo from '../../../Assets/Images/logo.png';




const EmailConfirmation = () => {
    const {token} = useParams<{token : string}>();
    const [loadingApi, setLoadingApi] = useState(false);
    const [successApi, setSuccessApi] = useState(false);
    const [errorApi, setErrorApi] = useState(false);
    useEffect(() => {
        setLoadingApi(true);
        clientCredentialApi.confirmEmail({email_token: token}).then(response => {
            setLoadingApi(false)
            setSuccessApi(true);
        }).catch(error => {
            setLoadingApi(false)
            setErrorApi(true);
        })
    },[])

    return(<>
        <Container fluid>
                <Row style={{marginTop: '4rem'}}><Col><Image src={logo} style={{width: '10%'}} /></Col></Row>
            <Row style={{marginTop: 60}}><Col>
                {loadingApi ? <LoadingButton /> :
                    successApi ?
                        <span className="titleStyle">
                        Email vérifié avec succès</span>
                    : errorApi ?
                        <span className='text-danger'>Erreur de vérification de l'e-mail</span>
                    : null
                }
            </Col></Row>
            {successApi ?
                <Row style={{marginTop: '2rem'}}>
                    <Col>
                        <Link to='/login'>
                            <Button className={'buttonGeneralStyle'} style={{width: 200}}>
                                <span >Continuer</span>
                            </Button>
                        </Link>
                    </Col>
                </Row>
            : null }
        </Container>
    </>)
}


export default EmailConfirmation