import React, {useState, useEffect, useRef} from 'react';
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import classes from './ModalForm.module.css';
import Camera, {FACING_MODES} from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { ImBin } from "react-icons/im";
import * as authApi from '../../../Axios/Call/AuthApi';
import AlertGeneral from '../../Commons/AlertGeneral/AlertGeneral';
import LoadingButton from '../../Commons/LoadingButton/LoadingButton';
import ImageUploader from "react-images-upload";


const ModalForm = ({openCloseModalForm, openModalForm, setOpenModalForm}) => {
    const [pictures, setPictures] = useState([]);
    const [newPic, setNewPic] = useState([]);
    const [currentFile, setCurrentFile] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [numberKm, setNumberKm] = useState('');
    const [comments, setComments] = useState('');
    const [formError, setFormError] = useState({pictures: false, numberKm: false});
    const [errorApi, setErrorApi] = useState(false);
    const [loadingApi, setLoadingApi] = useState(false);
    const [successApi, setSuccessApi] = useState(false);
    const fileRef = useRef(null);

    const handleTakePhoto = (dataUri) => {
        setPictures(pictures.concat(dataUri));
        setIsOpen(false);
    }

    const onClickOpenCamera = () => {
        setIsOpen(true);
    }

    const onDeletePicture = (dataUri) => {
        const newPictures = [...pictures];
        const index = pictures.findIndex(pic => pic == dataUri);
        newPictures.splice(index,1);
        setPictures(newPictures);
    }

    const onChangeNumberKm = (text) => {
        setNumberKm(text);
    }

    const onChangeComments = (text) => {
        setComments(text);
    }

    const onSendFormHandler = () => {
    
        if(!numberKm.length){
            setFormError({...formError,numberKm: true});
            return false;
        }

        setLoadingApi(true);
        authApi.createForm({pictures: pictures, number_km: numberKm, request_id: openModalForm.id, comments: comments}).then(response => {
            setSuccessApi(true);
            setLoadingApi(false);
            openCloseModalForm()
        }).catch(error => {
            setErrorApi(true);
            setLoadingApi(false);
        })
    }

    const onUploadPictureHandler = (file) => {
       
        setNewPic(newPic.concat(file));

    }

    useEffect(() => {
        window.scrollTo(0, 0);
        if(newPic.length && newPic.length > currentFile){
            const file = newPic[currentFile];
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                if(!pictures.find(pic => pic === reader.result)){
                    setPictures(pictures.concat(reader.result));
                }
                setCurrentFile(currentFile + 1);
            };
        }
        if(newPic.length === currentFile && newPic.length !== 0 ){
            setCurrentFile(0);
            setNewPic([]);
        }
      
    },[newPic, currentFile]);

    return(<>
        <Modal show={true}  onHide={() => openCloseModalForm()} size={'lg'}>
            <Modal.Header closeButton>
                <Row>
                    <Col>
                        <span className='title'>Prise en charge du véhicule</span>
                    </Col>
                </Row>
            </Modal.Header>
            <Modal.Body >
                {errorApi ? <AlertGeneral color='danger' description='Error uploading form' setTrigger={setErrorApi} /> : null}
                {successApi ? <AlertGeneral color='success' description='Form uploading success' setTrigger={setErrorApi} /> : null}
                <Row style={{marginTop: 20}}>
                    <Col xs={12} md={ 12}>
                        <Row><Col className='text-center'>
                            <span className='title'>
                                {openModalForm.type === 'returning' ? 'Vous retournez la voiture' : 'Vous prenez la voiture'}
                            </span>
                        </Col></Row>
                        <Row> 
                            <Col>
                                <ImageUploader
                                    buttonText={'télécharger des photos'}
                                    label=''
                                    onChange={(event) => onUploadPictureHandler(event)}
                                    imgExtension={['.jpg', '.png','.jpeg']}
                                    buttonClassName={'buttonGeneralStyle'}
                                    labelClass={'textStyle'}
                                    maxFileSize={5242880}
                                    ref={fileRef}
                                />
                            </Col>
                        </Row>
                        {isOpen ? 
                            <>
                            { /*<Camera
                                onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }
                                idealFacingMode = {FACING_MODES.ENVIRONMENT}
                                //isFullscreen
                            />*/ }
                            </>
                        :
                            <Row style={{marginTop: '1rem'}}>
                                <Col xs={12} md={12} style={{marginBottom: 20}} className='text-center'>
                                    {/* <Button onClick = {() => onClickOpenCamera()} className={'buttonGeneralStyle'}>
                                        <span>prendre une photo</span>
                                    </Button> */ }
                                </Col>
                                { pictures.length ? 
                                    pictures.map( (picture,index) => {
                                        return(
                                            <Col className='colMarginForm'  xs={6} md={4} lg={3} key={index}>
                                                <div style={{position: "relative"}} >
                                                    <img style={{width: '100%'}} src={ picture } />
                                                    <div style={{position: 'absolute', top: 0}}>
                                                        <ImBin size={20} color={'red'} onClick={() => onDeletePicture(picture)}/>
                                                    </div>
                                                </div>
                                            </Col>      
                                        )
                                    })
                                : null }
                            </Row>
                        }
                    </Col>
                </Row>
                { formError.pictures ? 
                    <Row><Col className='text-left'><span className='text-danger'>
                    Veuillez insérer une image</span></Col></Row>
                : null }
                <Row style={{marginTop: '1rem'}}>
                    <Col className='text-left'>
                        <span className={'formLabelStyle'} >Kilomètrage de la voiture</span>
                    </Col>
                </Row>
                <Row>
                    <Col className='text-left'>
                        <input onChange={(event) => onChangeNumberKm(event.target.value)} value={numberKm}
                            className='textStyle' style={{width: '50%'}}
                        />
                    </Col>
                </Row>
                { formError.numberKm ? 
                <Row><Col className='text-left'><span className='text-danger'>Insert kilomètre de voiture</span></Col></Row>
                : null }
                <Row style={{marginTop: '1rem'}}>
                    <Col className='text-left'>
                        <span className={'formLabelStyle'} >Commentaires</span>
                    </Col>
                </Row>
                <Row>
                    <Col className='text-left'>
                        <textarea onChange={(event) => onChangeComments(event.target.value)} value={comments}
                            className='textStyle' style={{height: 200, width: '90%'}}
                        />
                    </Col>
                </Row>
                <Row style={{marginTop: '2rem'}}>
                    <Col className='text-center'>
                        {loadingApi ? <LoadingButton /> : 
                        <Button className='buttonGeneralStyle'
                            onClick={() => onSendFormHandler()}
                        >Envoyer</Button>
                        }
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    
    </>)
}


export default ModalForm;
