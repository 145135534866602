import React, {useState, useEffect} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

//-------------- Components ----------------\
import TextBox from '../../../Components/Commons/FormComponent/TextBox';
import LoadingButton from '../../../Components/Commons/LoadingButton/LoadingButton';
import AlertGeneral from '../../../Components/Commons/AlertGeneral/AlertGeneral';
//-------------- Others --------------------
import { useForm, Controller } from 'react-hook-form';
import {Link, useParams, useHistory} from 'react-router-dom'
import * as authApi from '../../../Axios/Call/AuthApi';
import {BiArrowBack} from 'react-icons/bi';


const PromoCodeAddEdit = () => {
    const {id} = useParams();
    const history = useHistory();
    const [loadingApi, setLoadingApi] = useState(false);
    const [errorApi, setErrorApi] = useState(false);
    const [codes, setCodes] = useState([]);

    const { control, register, handleSubmit, errors, watch, reset, } = useForm({
        defaultValues:{
            name: '',
            price: '',
        }
    }); // initialise the hook

    useEffect(() => {
        if(id){
            setLoadingApi(true);
            authApi.getOnePromoCode(id).then(response => {
                setCodes(response.data);
                reset(response.data);
                setLoadingApi(false);
            }).catch(error => {
                setLoadingApi(false);
                setErrorApi(true);
            })
        }
    },[id]);


    const onClickAddCodeHandler = (data) => {
   

        let formattedData = {
            name: data.description,
            code: data.code,
            description: data.description,
            is_active: data.is_active ? 1 : 0,
            type: data.type,
            price: data.price
        };



        if(id){

            setLoadingApi(true);
            authApi.editPromoCode(id,formattedData).then(response => {
                history.push('/promo-codes');
                setLoadingApi(false);
            }).catch(error => {
                setErrorApi(true);
                setLoadingApi(false);
            })
        } else {
            setLoadingApi(true);
            authApi.createPromoCode(formattedData).then(response => {
                history.push('/promo-codes');
                setLoadingApi(false);
            }).catch(error => {
       
                setErrorApi(true);
                setLoadingApi(false);
            })
        }
    };

    return(<>
        {errorApi ? <AlertGeneral color='danger' description='Error creating code' setTrigger={setErrorApi} /> : null}
        <Container fluid>
            <Row style={{marginTop: '2rem'}}>
                <Col className='text-left'>
                    <Row>
                        <Col className='my-auto' xs={1}>
                            <Link to='/promo-codes'>
                                <BiArrowBack size={38} color={'#1C222D'}/>
                            </Link>
                        </Col>
                        <Col className='text-left'>
                            <span className='title'>
                                {id && Object.keys(codes).length ? 'Modifier '+codes.name: "Création d'un code promo" }
                            </span>
                        </Col>
                    </Row>
                </Col>
            </Row>
            { ( id && Object.keys(codes).length ) || !id ?
            <Form onSubmit={handleSubmit(onClickAddCodeHandler)} >
                <Row style={{ marginTop: '1rem'}}>
                    <Col xs={12} lg={3} className='colMarginForm'>
                        <TextBox
                            ref={register({ required: 'true' })} placeHolder={'ex : ARVIN35'} type={'input'} name={'code'}
                            error={ errors.name ?  'Field Required' : ''} offset={0} span={12} labelName={'Code'}
                            labelStyle={{color: 'black'}}
                            errorStyle={{color: 'red'}}
                        />
                    </Col>


                    <Col xs={12} lg={3} className='colMarginForm'>
                        <TextBox ref={register({ required: 'true' })} placeHolder={'ex : code promo pour Noël'} type={'input'} name={'description'}
                            error={ errors.name ?  'Field Required' : ''} offset={0} span={12} labelName={'Description'}
                            labelStyle={{color: 'black'}}
                            errorStyle={{color: 'red'}}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} lg={3} className='colMarginForm'>
                        <Row>
                            <Col className='text-left'>
                                <Form.Label className={'formLabelStyle'} >Type de réduction</Form.Label>
                            </Col>
                        </Row>

                        <Row>
                            <Col className='text-left' >
                                <Form.Check inline label={"Prix"}
                                    group={"group1"}
                                    name={"type"}
                                    value={'price'}
                                    type={'radio'}
                                    ref={register()}/>
                                <Form.Check inline label={"Pourcentage"}
                                    group={"group1"}
                                    name={"type"}
                                    value={'percentage'}
                                    type={'radio'}
                                    ref={register()}/>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={12} lg={3} className='colMarginForm'>
                        <Row>
                            <Col className='text-left'>
                                <Form.Label className={'formLabelStyle'} >Montant de la réduction</Form.Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} lg={6}>
                                <Form.Control ref={register({ required: 'true' })}
                                    placeHolder={'ex : 10'}
                                    type={'number'}
                                    name={'price'}
                                    className='textStyle'

                                    step={0.1}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col> {errors.price && ( <span className='errorStyle'>{errors.price}</span>)}</Col>
                        </Row>
                        </Col>
                </Row>

                <Row>
                    <Col xs={12} lg={6} className='colMarginForm'>
                        <Row>
                            <Col className='text-left'>
                                <Form.Label className={'formLabelStyle'} >Statut</Form.Label>
                            </Col>
                        </Row>

                        <div className={"container-check text-left"}>
                            <Form.Check
                                name={"is_active"}
                                ref={register()}
                                disabled={false}
                                />
                            Actif/Inactif
                        </div>
                    </Col>
                </Row>

                <Row style={{marginTop: '5rem'}}>
                    <Col xs={12} lg={6}>
                        <Button type='submit' className={'buttonGeneralStyle'} style={{width: 200}}>
                            <span>{id ? 'Modifier le code promo' : 'Créer le code' }</span>
                        </Button>
                    </Col>
                </Row>
            </Form>
            : <LoadingButton /> }
        </Container>
    </>)
};

export default PromoCodeAddEdit;