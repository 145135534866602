import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
//-------------- Components ----------------\
import TextBox from "../../../Components/Commons/FormComponent/TextBox";
import LoadingButton from "../../../Components/Commons/LoadingButton/LoadingButton";
import ModalSimple from "../../../Components/Commons/ModalSimple/ModalSimple";
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral";
//-------------- Others --------------------
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import * as ClientCredential from "../../../Axios/Call/ClientCredential";
//import Cookies from "js-cookie";
import Background from "../../../Assets/Images/airport.jpg";
import logo from "../../../Assets/Images/logo.png";
//------------- Style -----------------------
import classes from "./Registration.module.css";



interface FormObject {
  email: string;
  password: string;
  password_confirmation: string;
  name: string;
  surname: string;
}

const Registration = () => {
  const [loadingApi, setLoadingApi] = useState<true | false>(false);
  const [errorApiAccount, setErrorApiAccount] = useState<true | false>(false);
  const [errorApi, setErrorApi] = useState<true | false>(false);
  const [successApi, setSuccessApi] = useState<true | false>(false);
  const [terms, setTerms] = useState<true | false>(false);
  const [termsError, setTermsError] = useState<true | false>(false);
  const { gtag, install } = require("ga-gtag");

  const { control, register, handleSubmit, errors, watch, reset } = useForm({}); // initialise the hook
  const history = useHistory();

  const emailValidator = (value: string) =>
    /^[a-zA-Z0-9]([a-zA-Z0-9._-]+)@(([a-zA-Z0-9-]+\.)+)([a-zA-Z]{2,4}|[0-9]{1,3})$/.test(
      value
    ) || "Email invalide";
  const passwordValidator = (value: string) =>
    value.trim().match(/^[^\s]{8,20}$/) ||
    "passe invalide : min 8 caractères, pas d'espaces, max 20 caractères";
  const passwordConfirmValidator = (value: string) =>
    value === watch("password") || "Les mots de passe ne correspondent pas";


    const google = () => {
     gtag('event', 'creation-compte', {'event_category' : 'inscription','event_label' : 'creation-compte'});
    }



  const onClickContactHandler = (data: FormObject) => {
    if (!terms) {
      setTermsError(true);
    } else {
      setLoadingApi(true);
      ClientCredential.register({
        ...data,
        //reference: Cookies.get("als_banned_id"),
      })
        .then((response) => {
          setLoadingApi(false);
          setSuccessApi(true);
          setErrorApiAccount(false);
          setErrorApi(false);
          setSuccessApi(true);
         google();
         setTimeout(() => {
          history.push("/login");
        }, 2000); // Délai de 2 secondes avant de rediriger

        })
        .catch((error) => {
          setLoadingApi(false);
          if (error.response && error.response.status == "400") {
            setErrorApiAccount(true);
          } else {
            setErrorApi(true);
          }
        });

       
    }

  };

  const onChangeSelect = () => {
    setTerms(!terms);
    setTermsError(false);
  };

  return (
    <>
      {successApi ? (
        <AlertGeneral
          setTrigger={setSuccessApi}
          closeAlone={false}
          color="success"
          description={
            "Votre compte a bien été créé."
          }
        />
      

      ) : null}
      <Container
        fluid
        style={{
          backgroundImage: `url(${Background})`,
          opacity: "90%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
          paddingBottom: "2rem",
          minHeight: "100vh",
        }}
      >
        <Row style={{ paddingTop: "2rem" }}>
          <Col xl={{ span: 6, offset: 3 }}>
            <Card className={classes.card}>
             
              <Row>
                <Col>
                  <span className={"titleForm"}>Votre compte</span>
                  <p className={classes.textStyle}>Arvin Care</p>
                </Col>
              </Row>
              <Form onSubmit={handleSubmit(onClickContactHandler)} noValidate>
                <Row>
                  <Col>
                    <Row className="rowMarginForm">
                      <Col xs={12} lg={12} >
                        <TextBox
                          ref={register({ validate: emailValidator })}
                          placeHolder={""}
                          type={"email"}
                          name={"email"}
                          error={errors.email ? errors.email.message : ""}
                          labelStyle={{ color: "black" }}
                          errorStyle={{ color: "red" }}
                          offset={0}
                          span={12}
                          labelName={"Email"}
                        />
                      </Col>
                    </Row>
                    <Row className="rowMarginForm">
                      <Col xs={12} lg={6} className="colMarginForm">
                        <TextBox
                          ref={register({ required: "true" })}
                          placeHolder={""}
                          type={"input"}
                          name={"phone"}
                          error={errors.phone ? "Champ requis" : ""}
                          offset={0}
                          span={12}
                          labelName={"Téléphone"}
                          labelStyle={{ color: "black" }}
                          errorStyle={{ color: "red" }}
                        />
                      </Col>
                      <Col xs={12} lg={6} className="colMarginForm">
                        <TextBox
                          ref={register()}
                          placeHolder={"facultatif"}
                          type={"input"}
                          name={"company"}
                          error={errors.company ? "Champ requis" : ""}
                          offset={0}
                          span={12}
                          labelName={"Entreprise"}
                          labelStyle={{ color: "black" }}
                          errorStyle={{ color: "red" }}
                        />
                      </Col>
                    </Row>
                    <Row className="rowMarginForm">
                      <Col xs={12} lg={6} className="colMarginForm">
                        <TextBox
                          ref={register({ required: "true" })}
                          placeHolder={""}
                          type={"input"}
                          name={"name"}
                          error={errors.name ? "Champ requis" : ""}
                          offset={0}
                          span={12}
                          labelName={"Prénom"}
                          labelStyle={{ color: "black" }}
                          errorStyle={{ color: "red" }}
                        />
                      </Col>
                      <Col xs={12} lg={6} className="colMarginForm">
                        <TextBox
                          ref={register({ required: "true" })}
                          placeHolder={""}
                          type={"input"}
                          name={"surname"}
                          error={errors.surname ? "Champ requis" : ""}
                          offset={0}
                          span={12}
                          labelName={"Nom de famille"}
                          labelStyle={{ color: "black" }}
                          errorStyle={{ color: "red" }}
                        />
                      </Col>
                    </Row>
                    <Row className="rowMarginForm">
                      <Col xs={12} lg={6} className="colMarginForm">
                        <TextBox
                          ref={register({ validate: passwordValidator })}
                          placeHolder={""}
                          type={"password"}
                          name={"password"}
                          error={errors.password ? errors.password.message : ""}
                          offset={0}
                          span={12}
                          labelName={"Mot de passe"}
                          labelStyle={{ color: "black" }}
                          errorStyle={{ color: "red" }}
                        />
                      </Col>
                      <Col xs={12} lg={6} className="colMarginForm">
                        <TextBox
                          ref={register({ validate: passwordConfirmValidator })}
                          placeHolder={""}
                          type={"password"}
                          name={"confirm_password"}
                          error={
                            errors.confirm_password
                              ? errors.confirm_password.message
                              : ""
                          }
                          offset={0}
                          span={12}
                          labelName={"Confirmez votre mot de passe"}
                          labelStyle={{ color: "black" }}
                          errorStyle={{ color: "red" }}
                        />
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2rem" }}>
                      <Col>
                        <Form.Check
                          type={"checkbox"}
                          label={
                            <>
                              {" "}
                              <span className={classes.textStyle}>
                                Accepter les{" "}
                              </span>
                              <a
                                href="https://www.ac-conciergerie.fr/conditions-generales-de-vente/"
                                target="_blank"
                              >
                                <span className={classes.linkStyle}>
                                  termes et conditions de vente
                                </span>
                              </a>
                              <span className={classes.textStyle}>
                                {" "}
                                ainsi que notre{" "}
                              </span>
                              <a
                                href="https://www.ac-conciergerie.fr/politique-de-confidentialite/"
                                target="_blank"
                              >
                                <span className={classes.linkStyle}>
                                  politique de confidentialité.
                                </span>
                              </a>{" "}
                            </>
                          }
                          checked={terms}
                          onChange={() => onChangeSelect()}
                        />
                      </Col>
                    </Row>
                    {errorApi || errorApiAccount || termsError ? (
                      <Row style={{ marginTop: 20 }}>
                        <Col>
                          <span className="errorStyle" style={{ color: "red" }}>
                            {errorApi ? "Erreur d'enregistrement." : null}
                            {errorApiAccount ? "Cet email existe déjà." : null}
                            {termsError
                              ? "Merci d'accepter les conditions de vente."
                              : null}
                          </span>
                        </Col>
                      </Row>
                    ) : null}
                    <Row style={{ marginTop: "2.5rem", paddingLeft: 20 }}>
                      <Col>
                        {loadingApi ? (
                          <LoadingButton />
                        ) : (
                          <Button
                            type="submit"
                            className={"buttonGeneralStyle"}
                            style={{ width: 300 }}
                          >
                            <span>Créer mon compte</span>
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
              <Row style={{ marginTop: "1rem" }}>
                <Col>
                  <span className={classes.textStyle}>
                   Vous avez déjà un compte ?{" "}
                  </span>
                  <Link to="/login">
                    <span className={classes.linkStyle}>M'identifier</span>
                  </Link>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Registration;
