import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

const ModalDeletePromo = ({ openCloseModalForm, onConfirmClick }) => {
    const onClickHandler = () => {
        onConfirmClick();
        openCloseModalForm();
    };

    return (
        <>
            <Modal show={true} onHide={() => openCloseModalForm()} size={"lg"}>
                <Modal.Header closeButton>
                    <Row>
                        <Col>
                            <span className="title">Êtes-vous sur de vouloir supprimer ?</span>
                        </Col>
                    </Row>
                </Modal.Header>
                <Modal.Body>
                    <Row style={{ marginTop: "2rem" }}>
                        <Col className="text-center">
                            <Button className="buttonGeneralStyle" onClick={onClickHandler}>Supprimer</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ModalDeletePromo;
