import { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import {
  AiOutlinePlusCircle,
  AiOutlineCheckCircle,
  AiOutlineQuestionCircle,
} from "react-icons/ai";
import classes from "./Step3.module.css";

const Step3 = ({
  onClickThirdStepDone,
  onChangeExtraOptionHandler,
  renderPrice,
  plate,
  departueDestination,
  returnDestination,
  changeDetail,
  errorsForm,
  additionalServices,
  choosenAdditionalServices,
  hasbaggage,
  choosenDepartue,
  choosenPlace,
  onChangeInsuranceHandler,
  calculatedPrice,
}) => {
  const [help, setHelp] = useState(false);
  const [serviceId, setServiceId] = useState(null);
  const [selectedInsurance, setSelectedInsurance] = useState(null);

  const insurances = [
    {
      id: 1,
      name: "Assurance annulation",
      description:
        "Réservation modifiable gratuitement et annulable jusqu'à 1h avant votre voyage",
      checked: true,
      percent: 10,
    },
    {
      id: 2,
      name: "Sans assurance annulation",
      description: "Réservation non modifiable et non remboursable",
      checked: false,
      percent: 0,
    },
  ];

  useEffect(() => {
    if (
      hasbaggage === null ||
      hasbaggage === undefined ||
      hasbaggage === false
    ) {
      changeDetail(false, "hasbaggage");
    }
  }, []);

  const handleHelp = (id) => {
    setServiceId(id);
    setHelp(!help);
  };

  const calculateInsurancePrice = (reservationPrice, percent) => {
    let price = (parseFloat(reservationPrice) * percent) / 100;
    // Arrondir à 5 au-dessus
    price = Math.ceil(price / 5) * 5;
    return price;
  };

  const handleOptionChange = (changeEvent) => {
    const selectedIndex = parseInt(changeEvent.target.value, 10);
    setSelectedInsurance(insurances[selectedIndex]);
    onChangeInsuranceHandler(insurances[selectedIndex]);
  };

  return (
    <>
      <Row>
        <Col>
          <h1 style={{ fontSize: 28 }}>Détails de votre voyage</h1>
        </Col>
      </Row>
      <Row style={{ marginTop: 20, padding: 10 }}>
        <Col xs={12} lg={12} style={{ marginBottom: 10 }}>
          <Row className={"rowStyle"}>
            <Col className="text-left">
              <span className="labelStyleSteps">Plaque d'immatriculation</span>
            </Col>
          </Row>
          <Row className={"rowStyle"}>
            <Col className="text-left">
              <input
                value={plate}
                className="inputStyle"
                onChange={(event) => changeDetail(event.target.value, "plate")}
                placeholder={"ex : AB-120-ER"}
                style={{ width: "100%" }}
              />
            </Col>
          </Row>
          {errorsForm.plate ? (
            <Row className={"rowStyle"}>
              <Col>
                <span className="text-danger">
                  Insérer votre plaque d'immatriculation
                </span>
              </Col>
            </Row>
          ) : null}
        </Col>
        <Col xs={12} lg={6}>
          <Row className={"rowStyle"}>
            <Col className="text-left">
              <span className="labelStyleSteps">
                Votre destination à l'aller
              </span>
            </Col>
          </Row>

          <Row className={"rowStyle"}>
            <Col className="text-left">
              <input
                value={departueDestination}
                className="inputStyle"
                onChange={(event) =>
                  changeDetail(event.target.value, "departueDestination")
                }
                placeholder={"ex : New York"}
                style={{ width: "100%" }}
              />
            </Col>
          </Row>
          {errorsForm.departueDestination ? (
            <Row className={"rowStyle"}>
              <Col>
                <span className="text-danger">
                  Veuillez renseigner votre destination
                </span>
              </Col>
            </Row>
          ) : null}
        </Col>

        <Col xs={12} lg={6}>
          <Row className={"rowStyle"}>
            <Col className="text-left">
              <span className="labelStyleSteps">Provenance au retour</span>
            </Col>
          </Row>

          <Row className={"rowStyle"}>
            <Col className="text-left">
              <input
                value={returnDestination}
                className="inputStyle"
                onChange={(event) =>
                  changeDetail(event.target.value, "returnDestination")
                }
                placeholder={"ex : Paris"}
                style={{ width: "100%" }}
              />
            </Col>
          </Row>
          {errorsForm.returnDestination ? (
            <Row className={"rowStyle"}>
              <Col>
                <span className="text-danger">
                  Veuillez renseigner votre provenance au retour
                </span>
              </Col>
            </Row>
          ) : null}
        </Col>
      </Row>

      {(choosenDepartue.name !== "Gare de Rennes" ||
        choosenPlace.name !== "Gare de Rennes") && (
        <Row className={"rowStyle"}>
          <Col className="text-left">
            <input
              type="checkbox"
              name="hasbaggage"
              checked={hasbaggage}
              onChange={(event) => {
                const { checked } = event.target;
                changeDetail(checked, "hasbaggage");
              }}
              style={{ width: "auto", marginLeft: "1rem" }}
            />
            <label
              style={{ fontFamily: "Satoshi-Bold", paddingLeft: 10 }}
              htmlFor="hasbaggage"
            >
              J'ai un bagage en soute
            </label>
          </Col>
        </Row>
      )}

      <Row style={{ marginTop: "2rem" }}>
        <Col>
          <h1 style={{ fontSize: 28, paddingTop: 30 }}>
            Services additionnels
          </h1>
        </Col>
      </Row>
      <Row style={{ marginTop: "2rem" }}>
        <Col xs={12} lg={{ span: 10, offset: 1 }}>
          <Row>
            {additionalServices.map((service, index) => {
              return (
                <Col xs={12} lg={6} style={{ marginTop: 10 }} key={index}>
                  <Card style={{ borderRadius: 20, paddingBottom: 10 }}>
                    <Image
                      src={"data:image/png;base64," + service.base64}
                      style={{ width: "100%", borderTopRightRadius: 20 }}
                      className={classes.image}
                    />

                    <span
                      style={{
                        marginTop: 10,
                        color: "#000",
                        fontWeight: "bold",
                        fontSize: 18,
                        marginBottom: 10,
                      }}
                    >
                      {service.name}
                      <AiOutlineQuestionCircle
                        size={25}
                        color={"#888"}
                        style={{
                          cursor: "pointer",
                          marginLeft: 10,
                          marginTop: "-4px",
                        }}
                        onClick={() => handleHelp(service.id)}
                      />
                      {serviceId === service.id && help && (
                        <p style={{ color: "#888", fontSize: "14px" }}>
                          {service.description}
                        </p>
                      )}
                    </span>

                    <Row>
                      <Col className="my-auto">
                        <span
                          style={{
                            marginTop: 10,
                            color: "#000",
                            fontFamily: "Satoshi-Bold",
                            fontSize: 18,
                          }}
                        >
                          {service.price} €
                        </span>
                      </Col>
                      <Col>
                        {!choosenAdditionalServices.find(
                          (choosenService) => choosenService.id === service.id
                        ) ? (
                          <AiOutlinePlusCircle
                            size={40}
                            onClick={() => onChangeExtraOptionHandler(service)}
                          />
                        ) : (
                          <AiOutlineCheckCircle
                            size={40}
                            color={"green"}
                            onClick={() => onChangeExtraOptionHandler(service)}
                          />
                        )}
                      </Col>
                    </Row>
                 
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>

      {/* INSURANCE */}

      <Row style={{ marginTop: "3rem", padding: 30 }}>
        {insurances.map((insurance, index) => (
          <Col
            key={index}
            style={{
              border: "solid 1px",
              padding: 50,
              margin: 10,
              boxShadow: "rgba(100, 100, 111, 0.1) 0px 7px 29px 0px",
              borderRadius: "5px",
              paddingTop: "auto",
              paddingBottom: "auto",
            }}
          >
            <div style={{ textAlign: "left" }}>
              <label style={{ fontFamily: "Satoshi-Black", fontSize: 20, color:"#232D4C"  }}>
                <input
                  type="radio"
                  name="react-tips"
                  value={index} // Utiliser l'index au lieu de selectedInsurance.id
                  checked={selectedInsurance?.id === insurance.id}
                  className="form-check-input"
                  onChange={handleOptionChange}
                  style={{ marginTop: 8}}
                  required
                />
                {insurance.name}
                {calculateInsurancePrice(calculatedPrice?.total_our_price, insurance.percent) > 0 && (
                  <b style={{ fontSize: 16 }}>
                    (  {calculateInsurancePrice(calculatedPrice?.total_our_price, insurance.percent) }
                    € )
                  </b>
                )}
              </label>
              <p style={{ textAlign: "left" }}>{insurance.description}</p>
            </div>
          </Col>
          
        ))}
      </Row>
         
      {errorsForm.choosenInsurance ? (
            <Row className={"rowStyle"}>
              <Col>
                <span className="text-danger">
                  Veuillez choisir une assurance
                </span>
              </Col>
            </Row>
          ) : null}

      <Row style={{ marginTop: 20 }}>
        <Col>
          <h2
            style={{
              marginTop: 30,
              color: "#000",
              fontSize: 22,
            }}
            className="title"
          >
            Prix total: {renderPrice()} €
          </h2>
        </Col>
      </Row>
      <Row style={{ marginTop: "1rem" }}>
        <Col>
          <Button
            className="buttonGeneralStyle"
            onClick={() => onClickThirdStepDone()}
          >
            Procéder au paiement
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Step3;
