import React, {useState} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import {AiOutlineClockCircle} from 'react-icons/ai';
import {BiTimer, BiBadgeCheck} from 'react-icons/bi';
import {FaLeaf} from 'react-icons/fa';
import {BsBarChartFill} from 'react-icons/bs';
import {GiWallet} from 'react-icons/gi';
import classes from './Advantages.module.css';
import { Fragment } from 'react';


const Avantages = () => {
  const [advantages, setAdvantages] = useState([
    { icon: <BiTimer color={'#FFF'} size={50}/>,
      title: 'RAPIDE',
      description: 'Déposez et récupérez votre véhicule au dépose minute de votre pôle de voyageurs',},
     
{ icon: <GiWallet color={'#FFF'} size={50}/>,
      title: 'ÉCONOMIQUE',
      description: 'Profitez d’un service de qualité tout en faisant des économies sur vos frais de parking',},

      { icon: <BsBarChartFill color={'#FFF'} size={50}/>,
      title: 'PRATIQUE',
      description: 'Pendant votre voyage, profitez d’une panoplie de services additionnels et personnalisables proposés lors de votre réservation.',},
      
      { icon: <BiBadgeCheck color={'#FFF'} size={50}/>,
      title: 'ASSURANCE',
      description: 'Votre véhicule est protégé par notre partenaire G&P Assurances.',},

  { icon: <FaLeaf color={'#FFF'} size={50}/>,
      title: 'ENGAGEMENT',
      description: 'Nous nettoyons les véhicules à partir d’un système de récupération et du filtrage des eaux de pluie et des eaux usées (phytoremédiation). Tous les déplacements de nos voituriers sont réalisés en trottinette électrique.',},

  ])
  return(<>
    <Row style={{backgroundColor: '#232D4C', marginBottom: '1rem'}}>
      <Col style={{padding: 40}} xs={12} lg={{span:8, offset: 2}}>
        <Row>
          <Col className='text-left'>
            <span className={classes.sectionTitle}>LES ATOUTS</span>
          </Col>
        </Row>
        <Row style={{marginTop: 20}}>
          {advantages.map((advantage,index) => {
            return(
              <Fragment key={index} >
              <Col xs={12} lg={4} style={{marginTop: 10}}>
                <Row>
                  <Col className='text-left'>
                    {advantage.icon}
                  </Col>
                </Row>
                <Row style={{marginTop: 10}}><Col className='text-left'>
                  <span className={classes.title}>{advantage.title}</span>
                </Col></Row>
                <Row><Col className='text-left' style={{marginTop: 10}}>
                  <span className={classes.description}>{advantage.description}</span>
                </Col></Row>
              </Col>
              </Fragment>
            )
          })}
          
        </Row>
      </Col>
    </Row>
  </>)
}

export default Avantages;