import React from "react";
import { AiFillAndroid, AiFillApple } from "react-icons/ai";
import app1 from "../../../Assets/Images/app1.png";
import app2 from "../../../Assets/Images/app2.png";
import CloseIcon from '@mui/icons-material/Close';
import { useWindowWidth } from "@react-hook/window-size";




const MobileAppBanner = () => {
  const [visible, setVisible] = React.useState(true);
  const appleLink = "https://apps.apple.com/in/app/arvin-care/id6445979745?uo=2";
  const androidLink = "https://play.google.com/store/apps/details?id=com.arvin_care.app&gl=FR";
  const screenWidth = useWindowWidth();

  return (
    visible && (
      <div style={{
        position: "fixed",
        bottom: 10,
        right: 10,
        zIndex: 1000,
        backgroundColor: "#fff",
        borderRadius:5,
        padding:15,
        boxShadow: "rgba(100, 100, 111, 0.5) 0px 7px 29px 0px",
        textAlign:"center",
        margin:10, 
        paddingBottom:1,
      }}> 
      <div className="card-body" >
      <CloseIcon style={{float:'right', cursor:'pointer'}} onClick={()=> setVisible(false)}/>
      
        <div style={{paddingBottom:20}}>
      
        <a href={appleLink}><img src={app1} style={{width:150,padding:1 }}></img></a>
        {screenWidth > 800 && <a href={appleLink}> <img src={app2} style={{width:150,padding:1}}></img></a>}
      </div>
        <h2 style={{color:"#232D4C", fontFamily:'Satoshi-Bold', fontSize:20}}>L'application mobile Arvin Care est disponible !</h2>
        <p >Dés maintenant sur <a href={appleLink} style={{color:"#232D4C", fontFamily:'Satoshi-Black'}}> <AiFillApple size={20} style={{paddingBottom:3}}/> Apple Store</a> et  <a href={androidLink} style={{color:"#232D4C", fontFamily:'Satoshi-Black'}}><AiFillAndroid size={20} style={{paddingBottom:3}}/> Play Store</a></p>
        </div>
  
      </div>
    )
  );
};

export default MobileAppBanner;
