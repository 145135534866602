import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import classes from './ErrorPage.module.css'
import { FaHome } from "react-icons/fa";
import {Link} from 'react-router-dom';
import "../App.css";


const Page404 = () => {
    return(<>
        <Container fluid>
            <Row style={{marginTop: '2rem'}}>
                <Col xs={1}>
                    <Link to='/'>
                        <FaHome size={30} color='#1C222D'/>
                    </Link>
                </Col>
                <Col>
                    <h1>Page non trouvée</h1>
                </Col>
            </Row>
            <Row style={{marginTop: '2rem'}}>
                <Col>
                    <span className={classes.numberError} style={{  fontFamily: "'Satoshi-Medium',sans-serif"}}>404</span>
                </Col>
            </Row>
        </Container>
    </>)
}
export default Page404