import React, { useEffect, useState } from "react";
import Image from "react-bootstrap/Image";
//--------------- Components ----------------------
import NavbarAdmin from "../Components/Admin/NavbarAdmin/NavbarAdmin";
//-------------- Others --------------------------
import { useHistory, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as actionCreator from "../store/action/index";
import { RootState } from "typesafe-actions";
//-------------- Bootstrap ----------------------
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
//-------------- Imagini -------------------------
import { useWindowWidth } from "@react-hook/window-size";
import { GiHamburgerMenu } from "react-icons/gi";
import classes from "./Layout.module.css";
import ReactGA from "react-ga";
import { hotjar } from "react-hotjar";
import Logo from "../Assets/Images/Logo_Arvin-Care_white.png";


//hotjar.initialize('1808374');

ReactGA.initialize("G-NL431KCJSJ");
const trackPage = (page: string) => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

interface Props {
  children: JSX.Element;
  page?: string;
}

const LayoutAdmin: React.FC<Props> = ({ children, page }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const screenWidth = useWindowWidth();
  const [isOpen, setIsOpen] = useState(screenWidth > 1025 ? true : false);

  const openCloseMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {trackPage(page ? page : "Another Page")}
      <div className={classes.wrapperDiv}>
        {isOpen || screenWidth > 1025 ? (
          <div className={classes.menuStyle}>
            <div style={{ display: "flex" }}>
              <div style={{ width: "100%"}}>
                <a href="https://www.ac-conciergerie.fr" target="_blank">
                  <Image
                    src={Logo}
                    className={classes.imageWidth}
                    
                  />
                </a>
              </div>
              {screenWidth > 1025 ? null : (
                <div
                  style={{ zIndex: 30, paddingRight: 20, paddingLeft: 5 }}
                  className="my-auto"
                >
                  <GiHamburgerMenu
                    size={36}
                    color={"white"}
                    onClick={() => openCloseMenu()}
                  />
                </div>
              )}
            </div>
            <div style={{ position: "fixed" }}>
              <NavbarAdmin setIsOpen={setIsOpen} />
            </div>
          </div>
        ) : null}
        {!isOpen || screenWidth > 1025 ? (
           <div className={classes.mainStyle} style={{ overflowY: 'scroll' }}>
            {screenWidth > 1025 ? null : (
              <>
                <div
                  style={{ display: "flex", paddingLeft: 20, backgroundColor:"#232D4C" }}
                  className="text-left"
                  
                >
                  <div style={{ width: "90%" }}>
                    <a href="https://www.ac-conciergerie.fr" target="_blank">
                      <Image
                        src={Logo}
                        style={{ marginTop: 5, marginBottom: 5, width:"10%" }}
                        className={classes.imageWidth}
                      />
                    </a>
                  </div>
                  <div
                    style={{ zIndex: 30, paddingRight: 20 }}
                    className="my-auto"
                  >
                    <GiHamburgerMenu
                      size={36}
                      color={"white"}
                      onClick={() => openCloseMenu()}
                    />
                  </div>
                </div>
              </>
            )}
            {children}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default LayoutAdmin;
