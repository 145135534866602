import React, {useState, useEffect} from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
//-------------- Components ----------------
import TextBox from '../../../Components/Commons/FormComponent/TextBox';
import LoadingButton from '../../../Components/Commons/LoadingButton/LoadingButton';
import AlertGeneral from '../../../Components/Commons/AlertGeneral/AlertGeneral';
//-------------- Others --------------------
import { useForm } from 'react-hook-form';
import classes from './ResetPassword.module.css'
import {Link, useParams, useHistory} from 'react-router-dom'
import * as clientCredentialApi from '../../../Axios/Call/ClientCredential'; 
import Logo from '../../../Assets/Images/logo.png'

interface FormObject {
    password: string,
    confirm_password: string,
}

const ResetPassword = () => {
    const {token} = useParams<{token : string}>();
    const [loadingCheckToken, setLoadingCheckToken ] = useState(true);
    const [successCheckToken, setSuccessCheckToken ] = useState(false);
    const [errorCheckToken, setErrorCheckToken] = useState(false);
    const [loadingChangePassword, setLoadingChangePassword] = useState(false);
    const [successChangePassword, setSuccessChangePassword] = useState(false);
    const [errorChangePassword, setErrorChangePassword] = useState(false);
    const history = useHistory();
    
    const { control, register, handleSubmit, errors, watch, reset } = useForm({
    }); // initialise the hook

    const passwordValidator = (value:string) => value.trim().match(/^[^\s]{8,20}$/) || "passe invalide : min 8 caractères, pas d'espaces, max 20 caractères";
    const passwordConfirmValidator = (value:string) => value === watch('password') || 'Les mots de passe ne correspondent pas';

    useEffect(() => {
        clientCredentialApi.ResetPasswordCheck({reset_token: token}).then(response => {
            setSuccessCheckToken(true)
            setLoadingCheckToken(false)
        }).catch( error => {
            setErrorCheckToken(true);
            setLoadingCheckToken(false);
        });
    },[])

    const onClickSubmitForm = (data:FormObject) => {
        setLoadingChangePassword(true)
        clientCredentialApi.ResetPassword({...data, reset_token: token}).then(response => {
            setLoadingChangePassword(false);
            setSuccessChangePassword(true);
            history.push('/login');
        }).catch(error => {
            setErrorChangePassword(true);
            setLoadingChangePassword(false);
        });
    }

    return(<>
        <Container fluid>
            { successChangePassword ? 
                <AlertGeneral closeAlone={true} description='Les mots de passe ne correspondent pas' color='success' setTrigger={setSuccessChangePassword} /> 
            : null}
            <Row style={{ marginTop: '2rem'}}>
                <Col lg={{span:10, offset:1}}>
                    <Row style={{marginTop: 10, marginBottom: 20}}>
                        <Col>
                            <Image src={Logo} className={classes.imageStyle} />
                        </Col>
                    </Row>
                    { loadingCheckToken ? <LoadingButton /> :
                        successCheckToken ? 
                            <Card className={classes.card}>
                                <Row><Col><span className={classes.titleStyle}>Choisissez un nouveau mot de passe</span></Col></Row>
                                <Form onSubmit={handleSubmit(onClickSubmitForm)} >
                                    <Row><Col>
                                        <Row style={{ marginTop: '1rem'}}>
                                            <Col xs={12} lg={6} className='colMarginForm'>
                                                <TextBox ref={register({ validate: passwordValidator })} placeHolder={'Entrez votre nouveau mot de passe'} type={'password'} name={'password'}
                                                    error={ errors.password ?  errors.password.message : ''} offset={0} span={12} labelName={'Mot de passe'}
                                                    labelStyle={{color: 'black', }}
                                                    errorStyle={{color: 'red',}}
                                                />
                                            </Col>
                                            <Col xs={12} lg={6} className='colMarginForm'>
                                                <TextBox ref={register({ validate: passwordConfirmValidator })} placeHolder={'Confirmez votre nouveau mot de passe'} type={'password'} name={'confirm_password'}
                                                    error={ errors.confirm_password ?  errors.confirm_password.message : ''} offset={0} span={12} labelName={'Confirmez votre mot de passe'}
                                                    labelStyle={{color: 'black'}}
                                                    errorStyle={{color: 'red', fontWeight: 'bolder'}}
                                                />
                                            </Col>
                                        </Row>
                                        { errorChangePassword ? 
                                        <Row style={{marginTop: 20}}>
                                            <Col>
                                                <span className='errorStyle' style={{color: 'red', fontWeight: 'bolder'}}>
                                                    Erreur lors de la réinitialisation du mot de passe
                                                </span>
                                            </Col>
                                        </Row>
                                        : null }
                                        <Row style={{marginTop: '2.5rem'}}><Col>
                                            {loadingChangePassword ? <LoadingButton /> :
                                                successChangePassword ? null :
                                                <Button type='submit' className={'buttonGeneralStyle'} style={{width: 300}}><span >Changer mon mot de passe</span></Button>
                                            }
                                            {successChangePassword ?
                                                <Link to='/'>
                                                    <Button className={'buttonGeneralStyle'} style={{width: 200}}>
                                                        <span >Se connecter</span>
                                                    </Button>
                                                </Link>
                                            : null }
                                        </Col></Row>
                                    </Col></Row>
                                </Form>
                            </Card>
                        : errorCheckToken ? 
                        <span className='text-danger'>Token Error</span>
                        : null
                    }
                </Col>
            </Row>
        </Container>
    </>)
}


export default ResetPassword