import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import classes from './ErrorPage.module.css'
import { FaHome } from "react-icons/fa";
import {Link} from 'react-router-dom';


const Page403 = () => {
    
    return(<>
        <Container fluid>
        <Row style={{marginTop: '2rem'}}>
            <Col xs={1}>
                <Link to='/'>
                    <FaHome size={30} color='#000'/>
                </Link>
            </Col>
            <Col>
                <span className={classes.nameError}>Accès non autorisé à cette page</span>
            </Col>
        </Row>
        <Row style={{marginTop: '2rem'}}>
            <Col>
                <span className={classes.numberError}>403</span>
            </Col>
        </Row>
        </Container>
    </>)
}
export default Page403