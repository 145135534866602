import React, { useState, useEffect } from "react";
import * as authApi from "../../../Axios/Call/AuthApi";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
//-------------------- Components ---------------------
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral";
//-------------------- Others ---------------------------
import { useHistory } from "react-router-dom";
import emptyPage from "../../../Assets/Images/emptyPage.png";
import { Image } from "react-bootstrap";
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/fr';
dayjs.extend(relativeTime);
dayjs.locale('fr');
const Subscriptions = () => {


  const [subscribers, setSubscribers] = useState([]);
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const history = useHistory();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("nom");


    // Fonction pour mettre à jour la recherche
    const handleSearch = (event) => {
      setSearchTerm(event.target.value);
    };

      // Filtrage des abonnés selon la recherche
  const filteredSubscribers = subscribers.filter((subscriber) =>
  subscriber.user.surname.toLowerCase().includes(searchTerm.toLowerCase()) || subscriber.user.name.toLowerCase().includes(searchTerm.toLowerCase()) || subscriber.user.email.toLowerCase().includes(searchTerm.toLowerCase())
);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const listOfSubscribers = () => {
    setLoadingApi(true);
    authApi
      .listOfSubscribers()
      .then((response) => {
        setLoadingApi(false);
        setSubscribers(response.data.subscription);
      })
      .catch((error) => {
        setErrorApi(true);
        setLoadingApi(false);
      });
    };

  useEffect(() => {
    setLoadingApi(true);
    listOfSubscribers();
  }, []);

  const onClickDeleteSubscription = (id) => {
    setLoadingApi(true);
    console.log(id)
    authApi.cancelSubscription(id).then(response => {
        listOfSubscribers();
    }).catch(error => {
        setErrorApi(true);
        setLoadingApi(false);
    })
  };

  const onClickResumeSubscription = (id) => {
    setLoadingApi(true);
    console.log(id)
    authApi.resumeSubscription(id).then(response => {
        listOfSubscribers();
    }).catch(error => {
        setErrorApi(true);
        setLoadingApi(false);
    })
  };

  return (
    <>
      {errorApi ? (
        <AlertGeneral
          color="danger"
          description="Error loading clients"
          setTrigger={setErrorApi}
        />
      ) : null}
      <Container fluid>
        <Row style={{ paddingTop: "4rem" }}>
          <Col>
            <Row>
              <Col style={{ textAlign: "left", marginLeft: 20 }}>
                <span className="title">Abonnés</span>
              </Col>
            </Row>
            <Row>

            <Col style={{ textAlign: "right", marginRight: 20, fontFamily:"Satoshi-Medium", width:"400px" }}>
          <input
            type="text"
            style={{ fontFamily:"Satoshi-Medium", width:"250px", borderRadius:5, border:'0.1px solid #888', padding:5 }}
            placeholder="Rechercher par nom d'abonné"
            value={searchTerm}
            onChange={handleSearch}
          />
        </Col>
      </Row>
            {filteredSubscribers.length > 0 ? (
              <Row className="cardStyle">
                <Col>
                  <TableContainer component={Paper}>
                    <Table style={{ width: "100%" }}>
                      <TableHead className="tableBackground">
                        <TableRow>
                          <TableCell align="left">
                            <TableSortLabel
                              active={orderBy === "name"}
                              direction={orderBy === "name" ? order : "asc"}
                              onClick={createSortHandler("name")}
                            >
                              <span className="tableHeaderStyle">Nom</span>
                            </TableSortLabel>
                          </TableCell>

                          <TableCell align="left">
                            <TableSortLabel
                              active={orderBy === "email"}
                              direction={orderBy === "email" ? order : "asc"}
                              onClick={createSortHandler("email")}
                            >
                              <span className="tableHeaderStyle">Email</span>
                            </TableSortLabel>
                          </TableCell>
                          <TableCell align="left">
                            <TableSortLabel
                              active={orderBy === "phone"}
                              direction={orderBy === "phone" ? order : "asc"}
                              onClick={createSortHandler("phone")}
                            >
                              <span className="tableHeaderStyle">
                                Téléphone
                              </span>
                            </TableSortLabel>
                          </TableCell>
                          <TableCell align="left">
                            <TableSortLabel
                              active={orderBy === "type"}
                              direction={orderBy === "type" ? order : "asc"}
                              onClick={createSortHandler("type")}
                            >
                              <span className="tableHeaderStyle">
                                Abonnement
                              </span>
                            </TableSortLabel>
                          </TableCell>
                          <TableCell align="left">
                            <TableSortLabel
                              active={orderBy === "type"}
                              direction={orderBy === "type" ? order : "asc"}
                              onClick={createSortHandler("type")}
                            >
                              <span className="tableHeaderStyle">
                                Fin engagement
                              </span>
                            </TableSortLabel>
                          </TableCell>
                          <TableCell align="left">
                            <span className="tableHeaderStyle">Actions</span>
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {stableSort(filteredSubscribers, getComparator(order, orderBy))
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((subscriber) => (
                            
                            subscriber.subscription.is_active && <TableRow key={subscriber.id}>
                              <TableCell
                                component="th"
                                scope="row"
                                align={"left"}
                                key={subscriber.id}
                              >
                                {subscriber.user.name}      {subscriber.user.surname} 
                              </TableCell>
                              <TableCell align="left">
                                {subscriber.user.email}
                              </TableCell>
                              <TableCell align="left">
                                {subscriber.user.phone}
                              </TableCell>
                              <TableCell align="left">
                                <Button
                                  style={{
                                    border:'none',
                                    backgroundColor: `${subscriber.subscription.color}`,
                                  }}
                                >
                             
                                  {subscriber.subscription.name}
                                </Button>
                              </TableCell>
                              <TableCell align="left">
                              
                                  {subscriber.end_engagement ? subscriber.end_engagement : "Pas d'engagement"}
                          
                              </TableCell>
                              <TableCell align="left">
                                {!subscriber.stripe_sub.ends_at ? <Button
                                  className="buttonGeneralStyleMenu"
                                  onClick={() =>
                                    onClickDeleteSubscription(subscriber.user.id)
                                  }
                                >
                                  {" "}
                                  Annuler{" "}
                                </Button> :
                                <Button
                                className="buttonGeneralStyleMenu"
                                onClick={() =>
                                  onClickResumeSubscription(subscriber.user.id)
                                }
                              >
                                {" "}
                                Reprendre (possible avant le : {subscriber.end_engagement} {" "})
                              </Button>
                                }
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={subscribers.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Nombre de lignes"
                  />
                </Col>
              </Row>
            ) : (
              <>
                {" "}
                <Image src={emptyPage} /> <h2>Pas encore d'abonnés</h2>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Subscriptions;
