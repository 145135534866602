import { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import LoadingButton from "../../Commons/LoadingButton/LoadingButton";
import classes from "./Step4.module.css";
import { useHistory } from "react-router-dom";
import * as authApi from "../../../Axios/Call/AuthApi";
import Card from "react-bootstrap/Card";
import { AiFillCar, AiFillLock } from "react-icons/ai";
import moment from "moment";
import { Fragment } from "react";

const Step4 = ({
  renderPrice,
  departueDate,
  arrivalDate,
  carkm,
  plate,
  hasbaggage,
  choosenAdditionalServices,
  choosenPlace,
  choosenTimeDepartue,
  choosenTimeArrival,
  choosenDepartue,
  calculatedPrice,
  departueDestination,
  returnDestination,
  setStep,
  insurance,
}) => {
  const [name, setName] = useState("");
  const [terms, setTerms] = useState(false);
  const [stripeObject, setStripeObject] = useState("");
  const [loadingStripeApi, setLoadingStripeApi] = useState(false);
  const [errorStripeApi, setErrorStripeApi] = useState(false);
  const [loadingButtonPayment, setLoadingButtonPayment] = useState(false);
  const [errorStripe, setErrorStripe] = useState(false);
  const [paymentFaild, setPaymentFaild] = useState(false);
  const [successPayment, setSuccessPayment] = useState(false);
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const { gtag, install } = require("ga-gtag");

  useEffect(() => {
    
    setLoadingStripeApi(true);
    setLoadingStripeApi(false);
    authApi
      .paymentHandler({ amount: renderPrice() })
      .then((response) => {
        setStripeObject(response.data);
        setLoadingStripeApi(false);
      })
      .catch((error) => {
        setErrorStripeApi(true);
        setLoadingStripeApi(false);
      });
  }, []);

  const onChangeSelect = () => {
    setTerms(!terms);
  };

  const google = (
    id,
    amount,
    choosenAdditionalServices,
    choosenDepartue,
    choosenPlace,
    hasbaggage,
    departueDate,
    arrivalDate,
    choosenTimeArrival,
    choosenTimeDepartue,
    plate,
    insurance,
  ) => {
    const items = [];
    items.push({
      id: "0", // ID du produit - Paramètre obligatoire
      name: "Reservation", // Titre du produit - Paramètre obligatoire
      category: "Voiturier", // Catégorie du produit - Paramètre facultatif
      Lieu: choosenDepartue,
      "Lieu de prise en charge": choosenPlace,
      "Date du départ": moment(departueDate).format("DD-MM-yyyy"),
      "Heure départ": choosenTimeDepartue.name,
      "Date arrivée": arrivalDate.name,
      "Heure arrivée": moment(departueDate).format("DD-MM-yyyy"),
      "Bagage en soute": hasbaggage,
      "Plaque immatriculation": plate, // Variante du produit : lieu de prise en charge
      quantity: "1", // Quantité - Paramètre obligatoire
      // Prix - Paramètre obligatoire
    });

    choosenAdditionalServices.forEach((service) => {
      items.push({
        categorie: "Service additionnel",
        id: service.id,
        name: service.name,
        price: service.price + "€",
      });
    });

    gtag("event", "purchase", {
      transaction_id: id, // ID de transaction - Paramètre obligatoire
      value: amount + "€", // Montant total HT - Paramètre obligatoire
      currency: "EUR", // Devise de la transaction - Paramètre facultatif
      TVA: "20%", // Montant de la TVA - Paramètre facultatif
      "Mode de paiement": "CB", // Moyen de paiement - Paramètre obligatoire
      items: items, // services et services additionnels
    });
  };

  const handleSubmit = async (event) => {
    if (name && terms) {
      // Block native form submission.
      event.preventDefault();

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      setLoadingButtonPayment(true);

      //console.log(elements.getElement(CardElement));
      //return true
      const result = await stripe.confirmCardPayment(
        stripeObject.paymentIntentId,
        {
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              name: name,
            },
          },
        }
      );

      if (result.error) {
        setErrorStripe(result.error.message);
        setLoadingButtonPayment(false);
        setPaymentFaild(true);
      } else {
        // The payment has been processed!
        if (result.paymentIntent.status === "succeeded") {
          setLoadingButtonPayment(false);
          const amount = renderPrice();

          const placeMap = {
            1: "rennes-airport",
            2: "rennes-trainstation",
          };

          authApi
            .createRequest({
              place_id: 1,
              departue_date_time: moment(departueDate).format("yyyy-MM-DD"),
              arrival_date_time: moment(arrivalDate).format("yyyy-MM-DD"),
              pay_amount: renderPrice() * 100,
              departue_destination: departueDestination,
              arrival_destination: returnDestination,
              //car_km: carkm,
              time_arrival_id: choosenTimeArrival.id,
              time_departue_id: choosenTimeDepartue.id,
              plate: plate,
              additional_servies: choosenAdditionalServices.map((service) => {
                return { id: service.id };
              }),
              flight_number: "web",
              departue_place: placeMap[choosenDepartue.id],
              arrival_place: placeMap[choosenPlace.id],
              has_baggage: hasbaggage,
              insurance: insurance.id===1 ? true : false,
            })

            .then((response) => {
              setLoadingButtonPayment(false);
              setSuccessPayment(true);
              google(
                result.paymentIntent.id,
                amount,
                choosenAdditionalServices,
                choosenDepartue.name,
                choosenPlace.name,
                hasbaggage,
                departueDate,
                arrivalDate,
                choosenTimeArrival,
                choosenTimeDepartue,
                plate
              );
            })
            .catch((error) => {
              setLoadingButtonPayment(false);
              setPaymentFaild(true);
            });
        }
      }
    }
  };

  const onClickTryAgainHandler = () => {
    setLoadingButtonPayment(false);
    setErrorStripe("");
    setPaymentFaild(false);
  };

  const onChangeNameHandler = (event) => {
    setName(event);
  };
  return (
    <>
      {successPayment ? (
        <>
          <Row>
            <Col>
              <span style={{ fontSize: 28, color: "green" }}>
                Paiement confirmé
              </span>
            </Col>
          </Row>
          <Row style={{ marginTop: "2rem" }}>
            <Col xs={12} lg={4}>
              <Row>
                <Col className="text-left">
                  <span className="labelStyleSteps">
                    LIEU DE PRISE EN CHARGE
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card style={{ paddingTop: 10, paddingBottom: 10 }}>
                    <span
                      style={{
                        color: "black",
                        textTransform: "uppercase",
                        fontSize: 14,
                      }}
                    >
                      {choosenDepartue.name}
                    </span>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col xs={12} lg={4}>
              <Row>
                <Col className="text-left">
                  <span className="labelStyleSteps">LIEU D’ARRIVÉE</span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card style={{ paddingTop: 10, paddingBottom: 10 }}>
                    <span
                      style={{
                        color: "black",
                        textTransform: "uppercase",
                        fontSize: 14,
                      }}
                    >
                      {choosenPlace.name}
                    </span>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col xs={12} lg={4}>
              <Row>
                <Col className="text-left">
                  <span className="labelStyleSteps">VOTRE VOYAGE</span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card style={{ paddingTop: 10, paddingBottom: 10 }}>
                    <span style={{ color: "black", fontSize: 14 }}>
                      {"Du  " +
                        departueDate.format("DD/MM/YYYY") +
                        " " +
                        choosenTimeDepartue.name +
                        " au " +
                        arrivalDate.format("DD/MM/YYYY") +
                        " " +
                        choosenTimeArrival.name}
                    </span>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row style={{ marginTop: "1rem" }}>
            <Col xs={12} lg={{ offset: 4, span: 4 }} className="my-auto">
              <Row>
                <Col className="text-left">
                  <Row>
                    <Col>
                      <span className="labelStyleSteps">Tarif ARVIN CARE</span>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: -10 }}>
                    <Col>
                      <span
                        className="labelStyleSteps"
                        style={{ fontSize: 10 }}
                      >
                        (parking + voiturier + assurance)
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card
                    style={{
                      paddingTop: 10,
                      paddingBottom: 10,
                      borderColor: "white",
                      backgroundColor: "green",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <AiFillCar color="white" />
                      <span
                        style={{
                          color: "white",
                          fontSize: 16,
                          fontWeight: "bolder",
                          paddingLeft: 10,
                        }}
                      >
                        {renderPrice()} € 
                      </span>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col xs={12} lg={4} className="my-auto">
              {/*
                <Row>
                    <Col className='text-left'>
                        <span className='labelStyleSteps'>TARIF DU PARKING LOCAL</span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card style={{paddingTop: 10, paddingBottom: 10, borderColor: 'white', backgroundColor: '#5F9EA0'}}>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <FaParking color='white' />
                                <span style={{color: 'white', fontSize: 14, paddingLeft: 10}}>
                                    {calculatedPrice.total_their_price && calculatedPrice.total_their_price.toFixed(2)} €
                                </span>
                        </div>
                        </Card>
                    </Col>
                </Row> 
              */}
            </Col>
            <Col className="my-auto">
              {/*
                <Row>
                    <Col className='text-left'>
                        <span className='labelStyleSteps'>ECONOMIE RÉALISÉE</span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card style={{paddingTop: 10, paddingBottom: 10, borderColor: 'white', backgroundColor: '#003399'}}>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <FaCalculator color='white' />
                                <span style={{color: 'white', fontSize: 16, paddingLeft: 10}}>
                                    {calculatedPrice.percentage && (calculatedPrice.percentage).toFixed(2)} €
                                </span>
                            </div>
                        </Card>
                    </Col>
                </Row> 
                */}
            </Col>
          </Row>
          <Row style={{ marginTop: "1rem" }}>
            <Col>
              <Button className="buttonGeneralStyle" onClick={() => setStep(1)}>
                Retour à l'accueil
              </Button>
            </Col>
          </Row>
        </>
      ) : loadingStripeApi ? (
        <LoadingButton />
      ) : paymentFaild ? (
        <>
          <Row>
            <Col className="text-center">
              <span className={classes.errorMessage}>{errorStripe}</span>
            </Col>
          </Row>
          <LoadingButton animationData={"error"} />
          <Row style={{ marginTop: "2rem" }}>
            <Col className="text-center">
              <Button
                className={"buttonGeneralStyle"}
                onClick={onClickTryAgainHandler}
              >
                Réessayer
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col className="text-center">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <AiFillLock size={30} />
                <h1
                  className="title"
                  style={{ paddingTop: 10, marginLeft: 10 }}
                >
                  Paiement sécurisé
                </h1>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: "1.5rem" }}>
            <Col
              xs={12}
              lg={{ span: 12, offset: 0 }}
              md={12}
              className="text-left"
            >
              <Form.Label>
                <span className="labelStyleSteps">
                  Prénom et nom du titulaire
                </span>
              </Form.Label>
              <Form.Control
                type="text"
                id="form-name"
                placeholder="Exemple : Michel Durand"
                className="textStyle"
                onChange={(event) => onChangeNameHandler(event.target.value)}
                value={name}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "2rem" }}>
            <Col className="text-left">
              <span className="labelStyleSteps">Numéro de carte</span>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={{ span: 12, offset: 0 }}>
              <div style={{ border: "1px solid #ced4da", padding: 10 }}>
                <CardElement
                  options={{
                    style: {
                      base: {
                        color: "grey",
                        fontFamily: "'Satoshi-Medium', sans-serif",
                        fontSmoothing: "antialiased",
                        fontSize: "18px",
                        "::placeholder": { color: "#aab7c4" },
                      },
                      invalid: { color: "#fa755a", iconColor: "#fa755a" },
                    },
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: "2rem" }}>
            <Col>
              <Form.Check
                type={"checkbox"}
                label={
                  <Fragment>
                    <span className={classes.textStyle}>Accepter les </span>
                    <a
                      className={classes.textStyle}
                      href="https://www.ac-conciergerie.fr/conditions-generales-de-vente"
                      target="_blank"
                    >
                      <span style={{ color: "#232D4C" }}>
                        Termes et conditions de vente
                      </span>
                    </a>
                  </Fragment>
                }
                checked={terms}
                onChange={() => onChangeSelect()}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "2rem" }}>
            <Col className="text-center">
              {loadingButtonPayment ? (
                <LoadingButton />
              ) : (
                <Button
                  disabled={!name || !stripe || !terms ? true : false}
                  type="submit"
                  className="buttonGeneralStyle"
                >
                  Payer {renderPrice()}€
                </Button>
              )}
            </Col>
          </Row>
        </form>
      )}
    </>
  );
};

export default Step4;
