import React, { useEffect } from "react";
import "./App.css";
import "moment/locale/fr";
import "../Assets/Fonts/AvenirNextLTPro-Regular.otf";
//------------------------- Component Import ----------------------------------
import Login from "./AuthPages/Login/Login";
import Registration from "./AuthPages/Registration/Registration";
import ForgotPassword from "./AuthPages/ForgotPassword/ForgotPassword";
import ResetPassword from "./AuthPages/ResetPassword/ResetPassword";
import EmailConfirmation from "./AuthPages/EmailConfirmation/EmailConfirmation";
import Requests from "./AdminPages/Requests/Requests";
import ClientList from "./AdminPages/ClientsList/ClientList";
import CreateEditDrive from "./AdminPages/CreateEditDriver/CreateEditDriver";
import Home from "./ClientPages/Home";
import DepartueList from "./AdminPages/DepartueList/DepartueList";
import AdditionalServicesList from "./AdminPages/AdditionalServicesList/AdditionalServicesList";
import DepartuePriceList from "./AdminPages/DepartuePriceList/DepartuePriceList";
import AdditionalServicesAddEdit from "./AdminPages/AdditionalServicesAddEdit/AdditionalServicesAddEdit";
import PromoCodeList from "./AdminPages/PromoCodeList/PromoCodeList";
import PromoCodeAddEdit from "./AdminPages/PromoCodeAddEdit/PromoCodeAddEdit";
import DepartueAddEditPrice from "./AdminPages/DepartueAddEditPrice/DepartueAddEditPrice";
import Profile from "./AdminPages/Profile/Profile";
import Invoice from "./AdminPages/Invoice/Invoice";
import Subscriptions from "./AdminPages/SubscriptionsList/Subscriptions";
//------------------------ Hoc ------------------------------
import LayoutNotLogin from "../Hoc/LayoutNotLogin";
import LayoutAdmin from "../Hoc/LayoutAdmin";
import LayoutCheckAdmin from "../Hoc/LayoutCheckAdmin";
import LayoutCheckDriver from "../Hoc/LayoutCheckDriver";
//--------------------------  Route Import -----------------------------------
import {
  Redirect,
  Route,
  Router as BrowserRouter,
  Switch,
  useHistory,
} from "react-router-dom";
import "react-datetime/css/react-datetime.css";
import UserList from "./AdminPages/UsersList/UserList";
//----------- Error ------------
import Page404 from "./ErrorPage/Page404";
import Page403 from "./ErrorPage/Page403";
import CreateReservation from "./AdminPages/CreateReservationPage/CreateReservation";
import ClientReuqests from "./ClientPages/Reservations";
import { useDispatch } from "react-redux";
import { populateUserData } from "../store/action";


// this is kept in sync by actions signIn and signOut
const isAuthenticated = () => localStorage.getItem("token");
const PublicRoute = (props) => <Route {...props} />;
const PrivateRoute = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(populateUserData());
  }, [dispatch]);

  return isAuthenticated() ? <Route {...props} /> : <Redirect to={"/"} />;
};

const App = () => {
  const history = useHistory();

  //------------------------------------ Rendering ----------------------------------------------
  return (
    <div className="App">
      <BrowserRouter history={history} basename={"/"}>
        <Switch>
          <PublicRoute
            path="/"
            exact
            render={() => (
              <LayoutNotLogin page={"Home"}>
                <Home />
              </LayoutNotLogin>
            )}
          />
          <PublicRoute
            path="/register"
            exact
            render={() => (
              <LayoutNotLogin page={"Registration"}>
                <Registration />
              </LayoutNotLogin>
            )}
          />
          <PublicRoute
            path="/login/:type?"
            exact
            render={() => (
              <LayoutNotLogin page={"Login"}>
                <Login />
              </LayoutNotLogin>
            )}
          />
          <PublicRoute
            path="/confirm-email/:token"
            exact
            render={() => (
              <LayoutNotLogin page={"Confirm-Email"}>
                <EmailConfirmation />
              </LayoutNotLogin>
            )}
          />
          <PublicRoute
            path="/forgot-password"
            exact
            render={() => (
              <LayoutNotLogin page={"Forgot-Password"}>
                <ForgotPassword />
              </LayoutNotLogin>
            )}
          />
          <PublicRoute
            path="/reset-password/:token"
            exact
            render={() => (
              <LayoutNotLogin page={"Reset-Password"}>
                <ResetPassword />
              </LayoutNotLogin>
            )}
          />

          <PrivateRoute
            path="/driver/requests"
            exact
            render={() => (
              <LayoutCheckDriver>
                <LayoutAdmin page={"Requests"}>
                  <Requests />
                </LayoutAdmin>
              </LayoutCheckDriver>
            )}
          />
          <PrivateRoute
            path="/admin/requests"
            exact
            render={() => (
              <LayoutCheckAdmin>
                <LayoutAdmin page={"Requests"}>
                  <Requests />
                </LayoutAdmin>
              </LayoutCheckAdmin>
            )}
          />
          <PrivateRoute
            path="/client/requests"
            exact
            render={() => (
              <LayoutAdmin page={"ClientRequests"}>
                <ClientReuqests />
              </LayoutAdmin>
            )}
          />
          <PrivateRoute
            path="/clients"
            exact
            render={() => (
              <LayoutCheckAdmin>
                <LayoutAdmin page={"Clients"}>
                  <ClientList />
                </LayoutAdmin>
              </LayoutCheckAdmin>
            )}
          />
            <PrivateRoute
            path="/subscriptions"
            exact
            render={() => (
              <LayoutCheckAdmin>
                <LayoutAdmin page={"Subscriptions"}>
                  <Subscriptions />
                </LayoutAdmin>
              </LayoutCheckAdmin>
            )}
          />
          <PrivateRoute
            path="/users"
            exact
            render={() => (
              <LayoutCheckAdmin>
                <LayoutAdmin page={"Users"}>
                  <UserList />
                </LayoutAdmin>
              </LayoutCheckAdmin>
            )}
          />

          <PrivateRoute
            path="/profil"
            exact
            render={() => (
              <>
                {/* <LayoutCheckAdmin> */}
                <LayoutAdmin page={"Profile"}>
                  <Profile />
                </LayoutAdmin>
                {/* </LayoutCheckAdmin> */}
              </>
            )}
          />

          <PrivateRoute
            path="/client/facturation"
            exact
            render={() => (
             
                <LayoutAdmin page={"Facturation"}>
                  <Invoice />
                </LayoutAdmin>

            )}
          />

          <PrivateRoute
            path="/driver/:id?"
            exact
            render={() => (
              <LayoutCheckAdmin>
                <LayoutAdmin page={"Users"}>
                  <CreateEditDrive />
                </LayoutAdmin>
              </LayoutCheckAdmin>
            )}
          />

          <PrivateRoute
            path="/departues"
            exact
            render={() => (
              <LayoutCheckAdmin>
                <LayoutAdmin page={"Departues"}>
                  <DepartueList />
                </LayoutAdmin>
              </LayoutCheckAdmin>
            )}
          />
          <PrivateRoute
            path="/departue-prices/:id"
            exact
            render={() => (
              <LayoutCheckAdmin>
                <LayoutAdmin page={"Departue List"}>
                  <DepartuePriceList />
                </LayoutAdmin>
              </LayoutCheckAdmin>
            )}
          />
          <PrivateRoute
            path="/prices-add-edit/:departueId/:id?"
            exact
            render={() => (
              <LayoutCheckAdmin>
                <LayoutAdmin page={"Departue Prices Add"}>
                  <DepartueAddEditPrice />
                </LayoutAdmin>
              </LayoutCheckAdmin>
            )}
          />

          <PrivateRoute
            path="/additional-services"
            exact
            render={() => (
              <LayoutCheckAdmin>
                <LayoutAdmin page={"Services"}>
                  <AdditionalServicesList />
                </LayoutAdmin>
              </LayoutCheckAdmin>
            )}
          />

          <PrivateRoute
            path="/additional-service/:id?"
            exact
            render={() => (
              <LayoutCheckAdmin>
                <LayoutAdmin page={"Create Service"}>
                  <AdditionalServicesAddEdit />
                </LayoutAdmin>
              </LayoutCheckAdmin>
            )}
          />
          <PrivateRoute
            path="/promo-codes"
            exact
            render={() => (
              <LayoutCheckAdmin>
                <LayoutAdmin page={"Promo"}>
                  <PromoCodeList />
                </LayoutAdmin>
              </LayoutCheckAdmin>
            )}
          />
          <PrivateRoute
            path="/promo-code/:id?"
            exact
            render={() => (
              <LayoutCheckAdmin>
                <LayoutAdmin page={"Create Promo"}>
                  <PromoCodeAddEdit />
                </LayoutAdmin>
              </LayoutCheckAdmin>
            )}
          />

          <PrivateRoute
            path="/admin/create-reservation"
            exact
            render={() => (
              <LayoutCheckAdmin>
                <LayoutAdmin page={"Create Reservation"}>
                  <CreateReservation />
                </LayoutAdmin>
              </LayoutCheckAdmin>
            )}
          />

          <PublicRoute path="/403" exact component={Page403} />
          <Route component={Page404} />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
