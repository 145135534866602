import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { AiFillTwitterCircle } from "react-icons/ai";
import { RiFacebookCircleLine, RiInstagramLine } from "react-icons/ri";
import { TiSocialLinkedinCircular } from "react-icons/ti";
import classes from "./Socials.module.css";
import { FaGoogle } from "react-icons/fa";

const Socials = () => {


  const google = (social) => {

    if (social === "facebook") {
      window.gtag('event', 'reseaux-sociaux', {'event_category': 'liens-sortants','event_label': 'facebook'}); 

    }
    if (social === "instagram") {
      window.gtag('event', 'reseaux-sociaux', {'event_category': 'liens-sortants','event_label': 'instagram'}); 

    }
    if (social === "linkedin") {
      window.gtag('event', 'reseaux-sociaux', {'event_category': 'liens-sortants','event_label': 'Linkedin'}); 

    }
  
  };


  
  return (
    <>
      <Row style={{ marginTop: "3rem", marginBottom: "4rem" }}>
        <Col xs={12} className="text-center">
          <span className={classes.title}>Suivez-nous !</span>
        </Col>
        <Col xs={12} lg={{ offset: 5, span: 2 }} style={{ marginTop: 40 }}>
          <Row>
            <Col>
              <a href="https://www.facebook.com/ARVINCARESERVICE" onClick={() => google("facebook")}>
                <RiFacebookCircleLine size={40} color="#232D4C" />
              </a>
            </Col>
            <Col>
              <a href="https://instagram.com/arvincare_voiturier" onClick={() => google("instagram")}>
                <RiInstagramLine size={40} color="#232D4C" />
              </a>
            </Col>
            <Col>
              <a href="https://www.linkedin.com/company/arvin-care/" onClick={() => google("linkedin")}>
                <TiSocialLinkedinCircular size={40} color="#232D4C" />
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Socials;
