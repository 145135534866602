import React, {useState, useEffect} from 'react';
import * as authApi from '../../../Axios/Call/AuthApi';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
//-------------------- Components ---------------------
import LoadingButton from '../../../Components/Commons/LoadingButton/LoadingButton';
import AlertGeneral from '../../../Components/Commons/AlertGeneral/AlertGeneral';
//-------------------- Others ---------------------------
import { AiOutlineForm } from 'react-icons/ai';
import {useHistory} from 'react-router-dom';
import classes from './UserList.module.css';

const UserList = () => {
    const [users, setUsers] = useState([]);
    const [loadingApi, setLoadingApi] = useState(false);
    const [errorApi, setErrorApi] = useState(false);
    const history = useHistory();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('nom');
  

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };

      function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
          return -1;
        }
        if (b[orderBy] > a[orderBy]) {
          return 1;
        }
        return 0;
      }
      
      function getComparator(order, orderBy) {
        return order === 'desc'
          ? (a, b) => descendingComparator(a, b, orderBy)
          : (a, b) => -descendingComparator(a, b, orderBy);
      }

      const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
      };
      

      function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
      }

      const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
      };

    useEffect(() => {
        setLoadingApi(true);
        authApi.adminGetDrivers().then(response => {
            setLoadingApi(false);
            setUsers(response.data);
        }).catch(error => {
            setErrorApi(true)
            setLoadingApi(false);
        });
    },[]);

    const onClickCreateDriver = () => {
        history.push('/driver');
    }

    return(<>
        {errorApi ? <AlertGeneral color='danger' description='Error loading clients' setTrigger={setErrorApi} /> : null}
        <Container fluid >
            <Row style={{paddingTop: '4rem'}}>
                <Col>
                    <Row>
                        <Col className='text-left' xs={12} lg={2}>
                            <span className='title'>Utilisateurs</span>
                        </Col>
                        <Col className='text-left'>
                            <Button className='buttonGeneralStyleMenu' onClick={() => onClickCreateDriver()}>Créer un voiturier</Button>
                        </Col>
                    </Row>
                    <Row className='cardStyle'>
                        <Col>
                        <TableContainer component={Paper}>
                            <Table style={{width: '100%'}}>
                                <TableHead className='tableBackground'>
                                <TableRow>
                                    <TableCell align="left">
                                        <TableSortLabel
                                            active={orderBy === 'name'}
                                            direction={orderBy === 'name' ? order : 'asc'}
                                            onClick={createSortHandler('name')}
                                            ><span className='tableHeaderStyle'>Nom</span>
                                         </TableSortLabel>
                                    </TableCell>
                                        
                                    <TableCell align="left">
                                        <TableSortLabel
                                            active={orderBy === 'email'}
                                            direction={orderBy === 'email' ? order : 'asc'}
                                            onClick={createSortHandler('email')}
                                            ><span className='tableHeaderStyle'>Email</span>
                                         </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="left">
                                    <TableSortLabel
                                        active={orderBy === 'phone'}
                                        direction={orderBy === 'phone' ? order : 'asc'}
                                        onClick={createSortHandler('phone')}
                                        ><span className='tableHeaderStyle'>Téléphone</span>
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="left">
                                        <TableSortLabel
                                            active={orderBy === 'type'}
                                            direction={orderBy === 'type' ? order : 'asc'}
                                            onClick={createSortHandler('type')}
                                            ><span className='tableHeaderStyle'>Type</span>
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="left">
                                        <span className='tableHeaderStyle'>Actions</span>
                                    </TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {stableSort(users, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user) => (
                                    <TableRow key={user.id}>
                                        <TableCell component="th" scope="row" align={'left'}>
                                            {user.name} {user.surname}
                                        </TableCell>
                                        <TableCell align="left">{user.email}</TableCell>
                                        <TableCell align="left">{user.phone}</TableCell>
                                        <TableCell align="left">{user.type}</TableCell>
                                        <TableCell align="left">
                                            {user.type === 'driver' ? <AiOutlineForm onClick={() => history.push('/driver/'+user.id)}/> : '' }
                                        </TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={users.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            labelRowsPerPage='Nombre de lignes'
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    
    </>)
}

export default UserList;