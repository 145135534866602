import React, {useState, useEffect} from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
//-------------- Components ----------------\
import TextBox from '../../../Components/Commons/FormComponent/TextBox';
import LoadingButton from '../../../Components/Commons/LoadingButton/LoadingButton';
import ModalSimple from '../../../Components/Commons/ModalSimple/ModalSimple';
import AlertGeneral from '../../../Components/Commons/AlertGeneral/AlertGeneral';
//-------------- Others --------------------
import { useForm } from 'react-hook-form';
import {Link, useParams, useHistory} from 'react-router-dom'
import * as authApi from '../../../Axios/Call/AuthApi';
import {BiArrowBack} from 'react-icons/bi';
//------------- Style -----------------------
import ImageUploader from "react-images-upload";

const DepartueAddEditPrice = () => {
    const {departueId} = useParams();
    const {id} = useParams();
    const history = useHistory();
    const [price, setPrice] = useState({});
    const [loadingApi, setLoadingApi] = useState(false);
    const [errorApi, setErrorApi] = useState(false);

    const { control, register, handleSubmit, errors, watch, reset } = useForm({
        defaultValues:{
            days: '',
            our_price: '',
            their_price: '',
        }
    }); // initialise the hook

    useEffect(() => {
        if(id){
            setLoadingApi(true);
            authApi.getOneDepartuePrice(id).then(response => {
                setPrice(response.data);
                reset(response.data);
                setLoadingApi(false);
            }).catch(error => {
                setLoadingApi(false);
                setErrorApi(true);
            })
        }
    },[id]);

    const onClickAddServiceHandler = (data) => {
        if(id){
            setLoadingApi(true);
            authApi.editDepartuePrice(id,{...data, departue_id: departueId}).then(response => {
                history.push('/departue-prices/'+departueId);
                setLoadingApi(false);
            }).catch(error => {
                setErrorApi(true);
                setLoadingApi(false);
            })
        }else{
            setLoadingApi(true)
            authApi.createDepartuePrice({...data, departue_id: departueId}).then(response => {
                history.push('/departue-prices/'+departueId);
                setLoadingApi(false);
            }).catch(error => {
                setErrorApi(true);
                setLoadingApi(false);
            })
        }
    }


    return(<>
        {errorApi ? <AlertGeneral color='danger' description='Error creating prix' setTrigger={setErrorApi} /> : null}
        <Container fluid>
            <Row style={{marginTop: '2rem'}}>
                <Col className='text-left'>
                    <Row>
                        <Col className='my-auto' xs={1}>
                            <Link to={'/departue-prices/'+departueId}>
                                <BiArrowBack size={38} color={'#1C222D'}/>
                            </Link>
                        </Col>
                        <Col className='text-left'>
                            <span className='title'>
                                {id && Object.keys(price).length ? "Modifier d'un prix": "Création d'un prix" }
                            </span>
                        </Col>
                    </Row>
                </Col>
            </Row>
            { ( id && Object.keys(price).length ) || !id ? 
            <Form onSubmit={handleSubmit(onClickAddServiceHandler)} >
                <Row style={{ marginTop: '1rem'}}>
                    <Col xs={12} lg={6} className='colMarginForm'>
                        <TextBox ref={register({ required: 'true' })} placeHolder={''} type={'number'} name={'days'}
                            error={ errors.days ?  'Field Required' : ''} offset={0} span={12} labelName={'Nombre de jours'}
                            labelStyle={{color: 'black'}}
                            errorStyle={{color: 'red'}}
                        />
                    </Col>
                    <Col xs={12} lg={6} className='colMarginForm'>
                        <Row>
                            <Col className='text-left'>
                                <Form.Label className={'formLabelStyle'} >Tarif Arvin Care</Form.Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} lg={12} className='colMarginForm'>
                                <Form.Control ref={register({ required: 'true' })} 
                                    placeHolder={''} 
                                    type={'number'} 
                                    name={'our_price'}
                                    className='textStyle'
                                    style={{width: '100%'}}
                                    step={0.01}
                                />
                            </Col>
                        </Row>
                        <Row><Col> {errors.our_price && ( <span className='errorStyle'>{errors.our_price}</span>)}</Col></Row>
                    </Col>
                    <Col xs={12} lg={6} className='colMarginForm'>
                        <Row>
                            <Col className='text-left'>
                                <Form.Label className={'formLabelStyle'} >Tarif local</Form.Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} lg={12} className='colMarginForm'>
                                <Form.Control ref={register({ required: 'true' })} 
                                    placeHolder={''} 
                                    type={'number'} 
                                    name={'their_price'}
                                    className='textStyle'
                                    style={{width: '100%'}}
                                    step={0.01}
                                />
                            </Col>
                        </Row>
                        <Row><Col> {errors.their_price && ( <span className='errorStyle'>{errors.their_price}</span>)}</Col></Row>
                    </Col>
                </Row>
                
                <Row style={{marginTop: '2rem'}}>
                    <Col>
                        <Button type='submit' className={'buttonGeneralStyle'} style={{width: 200}}><span >
                            {id ? 'Modifier le tarif' : 'Créer le tarif' }
                        </span></Button>
                    </Col>
                </Row>
            </Form>
            : <LoadingButton /> }
        </Container>
    </>)
}

export default DepartueAddEditPrice;