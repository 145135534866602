import React, { useState, useEffect } from "react";
//----------- Bootstrap --------------------------
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
//-------------- Components ----------------
import TextBox from "../../../Components/Commons/FormComponent/TextBox";
import LoadingButton from "../../../Components/Commons/LoadingButton/LoadingButton";
//-------------- Others --------------------
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";
import * as ClientCredential from "../../../Axios/Call/ClientCredential";
import { useSelector, useDispatch } from "react-redux";
import * as actionCreator from "../../../store/action/index";
import Background from "../../../Assets/Images/background.jpg";
import BackgroundCustomer from "../../../Assets/Images/airport.jpg";
//------------ Style --------------------
import classes from "./Login.module.css";
import logo from "../../../Assets/Images/Logo_ArvinCare_Voiturier.png";

const Login = () => {
  const [errorApi, setErrorApi] = useState(false);
  const [loadingApi, setLoadingApi] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { type } = useParams();
  const userData = useSelector((state) => state.user.userData);

  const emailValidator = (value) =>
    value.trim().match(/^.+@.+$/) || "Email non validé";

  const { register, handleSubmit, errors } = useForm({});

  const onClickContactHandler = (data) => {
    setLoadingApi(true);
    ClientCredential.login(data)
      .then((response) => {
        localStorage.setItem("token", "Bearer " + response.data.token);
        dispatch(actionCreator.populateUserData());
        setLoadingApi(false);
      })
      .catch((error) => {
        setErrorApi(true);
        setLoadingApi(false);
      });
  };

  useEffect(() => {
    //console.log(userData)
    if (Object.keys(userData).length) {
      if (userData.type === "admin") {
        history.push("/admin/requests");
      }
      if (userData.type === "driver") {
        history.push("/driver/requests");
      }
      if (userData.type === "client") {
        history.push("/");
      }
    }
  }, [userData, history]);

  return (
    <>
      <Container
        fluid
        style={{
          paddingBottom: 400,
          backgroundImage: `url(${type ? Background : BackgroundCustomer})`,
          opacity: "90%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
          minHeight: "100vh",
          display: "grid",
          justifyContent: "center",
        }}
      >
        <Row style={{ paddingTop: 20 }}>
          {type ? (
            <Col className="text-left">
              <Image
                src={
                  "https://static.wixstatic.com/media/e982f5_28c932de5a894dddaffd02f80902f66c~mv2.png/v1/fill/w_178,h_46,al_c,usm_0.66_1.00_0.01/VIGEE%20-%20LOGO%20(blanc).png"
                }
                style={{ width: "20%" }}
              />
            </Col>
          ) : null}
          <Col className="text-center">
            <Link to="/">
              <Image src={logo} className={classes.logoStyle} style={{height:"auto", width:'200px'}}/>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Card className={classes.card}>
              <Row>
                <Col className="text-left">
                  <span className={"title"}>S'identifier</span>
                </Col>
              </Row>
              <Form onSubmit={handleSubmit(onClickContactHandler)}>
                <Row>
                  <Col>
                    <Row style={{ marginTop: "1.2rem" }}>
                      <Col xs={12} lg={12}>
                        <TextBox
                          ref={register({ validate: emailValidator })}
                          placeHolder={"Adresse email"}
                          type={"email"}
                          name={"email"}
                          error={errors.email ? errors.email.message : ""}
                          labelStyle={{
                            color: "black",
                            textTransform: "capitalize",
                          }}
                          offset={0}
                          span={12}
                          labelName={"Email"}
                          errorStyle={{ color: "red" }}
                          noLabel
                        />
                      </Col>
                      <Col style={{ marginTop: "0rem" }}>
                        <TextBox
                          ref={register({ required: "true" })}
                          placeHolder={"Mot de passe"}
                          type={"password"}
                          name={"password"}
                          error={errors.password ? "Champ requis" : ""}
                          offset={0}
                          span={12}
                          labelName={"Mot de pass"}
                          labelStyle={{
                            color: "black",
                            textTransform: "capitalize",
                          }}
                          errorStyle={{ color: "red" }}
                          noLabel
                        />
                      </Col>
                    </Row>
                    {errorApi ? (
                      <>
                        <Row style={{ marginTop: "1rem" }}>
                          <Col>
                            <span
                              className="errorStyle"
                              style={{ color: "red", fontWeight: "bolder" }}
                            >
                              Email ou mot de passe incorrect
                            </span>
                          </Col>
                        </Row>
                      </>
                    ) : null}
                    <Row style={{ marginTop: "2.5rem" }}>
                      <Col>
                        {loadingApi ? (
                          <LoadingButton />
                        ) : (
                          <Button
                            type="submit"
                            className={"buttonGeneralStyle"}
                            style={{ width: "100%" }}
                          >
                            <span>Se Connecter</span>
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
              <Row style={{ marginTop: "1rem" }}>
                <Col>
                  <span className={classes.textStyle}>
                    Vous n'avez pas de compte ?{" "}
                  </span>
                  <Link to="/register">
                    <span className={classes.linkStyle}>Créer un compte</span>
                  </Link>
                </Col>
              </Row>
              {
                <Row>
                  <Col>
                    <Link to="/forgot-password">
                      <span className={classes.linkStyle}>
                        Mot de passe oublié
                      </span>
                    </Link>
                  </Col>
                </Row>
              }
            </Card>
          </Col>
        </Row>
        <Row style={{ position: "absolute", bottom: 0, right: 40 }}>
          {type ? (
            <Col className="text-right">
              <span style={{ color: "black", fontFamily: "Satoshi-Medium" }}>
                Service Client: support@vigee.fr |{" "}
              </span>
              <a href="https://www.vigee.fr/chat">
                <span style={{ color: "black", fontFamily: "Satoshi-Medium" }}>
                  Chat
                </span>
              </a>{" "}
              |{" "}
              <a href="https://www.vigee.fr/">
                <span style={{ color: "black", fontFamily: "Roboto" }}>
                  www.vigee.fr
                </span>
              </a>
            </Col>
          ) : null}
        </Row>
      </Container>
    </>
  );
};

export default Login;
