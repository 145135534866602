import React, { useEffect, useState } from "react";
//----------------- Bootstrap ----------------------------
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
//------------------ Components -----------------------------
import AlertGeneral from "../../Components/Commons/AlertGeneral/AlertGeneral";
import ModalView from "../../Components/Admin/ModalView/ModalView";

import TableRequests from "../../Components/Admin/TableRequests/TableRequests";
//----------------- Others ----------------------
import * as authApi from "../../Axios/Call/AuthApi";
import { useSelector } from "react-redux";
import ClientModalView from "../../Components/Clients/ModalView/ModalView";


const ClientReuqests = () => {
  const [requests, setRequests] = useState([]);
  const [errorApi, setErrorApi] = useState(false);
  const userData = useSelector((state) => state.user.userData);
  const [openModalView, setOpenModalView] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);

  useEffect(() => {
    if (userData.type === "client") {
      authApi
        .clientRequests()
        .then((response) => setRequests(response.data))
        .catch((error) => setErrorApi(true));
    }
  }, [userData]);

  const todoRequests = requests.filter((r) => r.status === "todo");
  const pickupRequests = requests.filter((r) => r.status === "pickup");
  const returnRequests = requests.filter((r) => r.status === "return");

  const renderStatus = (status) => {
    if (status === "todo") {
      return <Button variant="success">Nouveau</Button>;
    }
    if (status === "pickup") {
      return <Button variant="warning">Parking</Button>;
    }
    if (status === "return") {
      return <Button variant="secondary">Véhicule remis</Button>;
    }
  };


  const onOpenCloseViewRequest = (id) => {
    setOpenModalView(openModalView ? false : id);
  };


 


  return (
    <>
      {errorApi ? (
        <AlertGeneral
          color="danger"
          description="error loading request"
          setTrigger={setErrorApi}
        />
      ) : null}
      {openModalView &&
        (userData.type !== "client" ? (
          <ModalView
            onOpenCloseViewRequest={onOpenCloseViewRequest}
            openModalView={openModalView}
            renderStatus={renderStatus}
          />
        ) : (
          <ClientModalView
            onOpenCloseViewRequest={onOpenCloseViewRequest}
            openModalView={openModalView}
            renderStatus={renderStatus}
          />
        ))}

     


      <Container fluid>
        <Row style={{ paddingTop: "4rem" }}>
          <Col>
            {pickupRequests.length > 0 && (
              <>
                <Row style={{ marginTop: "0rem" }}>
                  <Col className="text-left">
                    <span className="title">Retours prévus</span>
                  </Col>
                </Row>

                <TableRequests
                  requests={pickupRequests}
                  onOpenCloseViewRequest={onOpenCloseViewRequest}
               
                  returns
                />
              </>
            )}
            {todoRequests.length > 0 && (
              <>
                <Row style={{ marginTop: "4rem" }}>
                  <Col className="text-left">
                    <span className="title">Réservations</span>
                  </Col>
                </Row>

                <TableRequests
                  requests={todoRequests}
                  onOpenCloseViewRequest={onOpenCloseViewRequest}
               
                />
              </>
            )}
            {returnRequests.length > 0 && (
              <>
                <Row style={{ marginTop: "0rem" , marginBottom:'2rem'}}>
                  <Col className="text-left">
                    <span className="title">Historique des réservations</span>
                  </Col>
                </Row>

                <TableRequests
                  requests={returnRequests}
                  onOpenCloseViewRequest={onOpenCloseViewRequest}
             
                  noEdit
                />
              </>
            )}
            {requests.length === 0 && (
              <Row style={{ marginTop: "4rem" }}>
                <Col className="text-left">
                  <span className="title">Aucune réservation</span>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ClientReuqests;
