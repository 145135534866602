import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import * as authApi from "../../../Axios/Call/AuthApi";
import { useHistory } from "react-router";

const ModalDelete = ({ openCloseModalForm, modalValue }) => {



  const history = useHistory();


  const deleteHandler = () => {
    authApi
      .deleteCarRequest(modalValue)
      .then((response) => {
        openCloseModalForm();
        window.location.reload();
      })
      .catch((error) => {});
  };


  return (
    <>
      <Modal show={true} onHide={() => openCloseModalForm()} size={"lg"}>
        <Modal.Header closeButton>
          <Row>
            <Col>
              <span className="title">Êtes-vous sur de vouloir supprimer la réservation ?</span>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ marginTop: "2rem" }}>
            <Col className="text-center">
              <Button
                className="buttonGeneralStyle"
                onClick={() => deleteHandler()}
              >
                Supprimer
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalDelete;
