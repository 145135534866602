import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
//-------------- Components ----------------
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral";
import LoadingButton from "../../../Components/Commons/LoadingButton/LoadingButton";
//-------------- Others --------------------
import { useForm } from "react-hook-form";
import TextBox from "../../../Components/Commons/FormComponent/TextBox";
import classes from "./ForgotPassword.module.css";
import { useHistory } from "react-router-dom";
import * as clientCredentialApi from "../../../Axios/Call/ClientCredential";
import Logo from "../../../Assets/Images/Logo_ArvinCare_Voiturier.png";
import Background from "../../../Assets/Images/airport.jpg";
interface FormObject {
  email: string;
}

const ForgotPassword = () => {
  const [emailSuccess, setEmailSuccess] = useState<true | false>(false);
  const [emailError, setEmailError] = useState<true | false>(false);
  const [loadingApi, setLoadingApi] = useState<true | false>(false);

  const history = useHistory();

  const { control, register, handleSubmit, errors, watch, reset } = useForm({}); // initialise the hook

  const emailValidator = (value: string) =>
    value.trim().match(/^.+@.+$/) || "Email non valida";

  const onClickFormHandler = (data: FormObject) => {
    clientCredentialApi
      .ResetPasswordEmail(data)
      .then((respose) => {
        setEmailSuccess(true);
      })
      .catch((error) => {
        setEmailError(true);
      });
  };

  return (
    <>
      <Container
        fluid
        style={{
          backgroundImage: `url(${Background})`,
          opacity: "90%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
          paddingBottom: "2rem",
          minHeight: "100vh",
        }}
      >
        {emailSuccess == true ? (
          <AlertGeneral
            closeAlone={true}
            description="Nous vous avons envoyé un e-mail avec des instructions sur la façon de récupérer votre mot de passe"
            color="success"
            setTrigger={setEmailSuccess}
          />
        ) : null}
        <Row >
          <Col xl={{ span: 10, offset: 1 }}>
            <Row style={{ marginTop: 10, marginBottom: 20 }}>
              <Col>
                <Image src={Logo} className={classes.imageStyle} />
              </Col>
            </Row>
            <Card className={classes.card} style={{maxWidth:"60em", marginLeft:"auto", marginRight:"auto"}}>
              <Row>
                <Col>
                  <span className={classes.titleStyle}>
                    Récupération du mot de passe
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <span className={classes.textStyle}>
                    Entrez votre email dans le formulaire ci-dessous, afin de recevoir un email de renouvellement du mot de passe.
                  </span>
                </Col>
              </Row>
              <Form onSubmit={handleSubmit(onClickFormHandler)}>
                <Row>
                  <Col>
                    <Row style={{ marginTop: "2rem" }}>
                      <Col xs={12} lg={{ span: 8, offset: 2 }}>
                        <TextBox
                          ref={register({ validate: emailValidator })}
                          placeHolder={"Veuillez entrer l'email utilisé pour votre inscription"}
                          type={"email"}
                          name={"email"}
                          error={errors.email ? errors.email.message : ""}
                          labelStyle={{ color: "black", fontSize:'18px', fontFamily:'Satoshi-Bold' }}
                          errorStyle={{ color: "red" }}
                          offset={0}
                          span={12}
                          labelName={"Votre email"}
                        />
                      </Col>
                    </Row>
                    {emailError ? (
                      <Row style={{ marginTop: 20 }}>
                        <Col>
                          <span
                            className="errorStyle"
                            style={{ color: "red"}}
                          >
                            Aucun compte associé à cet email n'a été trouvé
                          </span>
                        </Col>
                      </Row>
                    ) : null}
                    <Row style={{ marginTop: "2.5rem" }}>
                      <Col>
                        {loadingApi ? (
                          <LoadingButton />
                        ) : (
                          <>
                             <Button
                            onClick={()=>  history.push('/login')}
                           className={"buttonGeneralStyleMenu"}
                           style={{ width: 150, marginRight:10 }}
                         >
                           <span>Retour</span>
                         </Button>

                          <Button
                            type="submit"
                            className={"buttonGeneralStyle"}
                            style={{ width: 150 }}
                          >
                            <span>Continuer</span>
                          </Button>
                          </>
                                                 )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ForgotPassword;
