import { useEffect, useState } from "react";
//----------------- Bootstrap ----------------------------
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
//------------------ Components -----------------------------
import LoadingButton from "../../../Components/Commons/LoadingButton/LoadingButton";
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral";
import ModalView from "../../../Components/Admin/ModalView/ModalView";
import ModalEdit from "../../../Components/Admin/ModalEdit/ModalEdit";
import ModalForm from "../../../Components/Admin/ModalForm/ModalForm";
import TableRequests from "../../../Components/Admin/TableRequests/TableRequests";
//----------------- Others ----------------------
import * as authApi from "../../../Axios/Call/AuthApi";
import { useSelector } from "react-redux";
import classes from "./Request.module.css";
import moment from "moment";
import dayjs from "dayjs";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const Requests = () => {
  const [requests, setRequests] = useState([]);
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const userData = useSelector((state) => state.user.userData);
  const [subscribers, setSubscribers] = useState([]);
  const [openModalForm, setOpenModalForm] = useState(false);
  const [openModalView, setOpenModalView] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);


  const listOfSubscribers = () => {
    setLoadingApi(true);
    authApi
        .listOfSubscribers()
        .then((response) => {
          setLoadingApi(false);
          setSubscribers(response.data.subscription);
        })
        .catch((error) => {
          setErrorApi(true);
          setLoadingApi(false);
        });
  };

  useEffect(() => {
    setLoadingApi(true);
    listOfSubscribers();
    console.log("sub", subscribers)
  }, []);

  useEffect(() => {
    setLoadingApi(true);
    if (userData.type === "admin") {
      authApi
        .adminRequest()
        .then((response) => {
          setLoadingApi(false);
          setRequests(response.data);
        })
        .catch((error) => {
          setLoadingApi(false);
          setErrorApi(true);
        });
    }
    if (userData.type === "driver") {
      authApi
        .adminRequest()
        .then((response) => {
          setLoadingApi(false);
          setRequests(response.data);
        })
        .catch((error) => {
          setLoadingApi(false);
          setErrorApi(true);
        });
    }
  }, [userData]);




  const renderStatus = (status) => {
    if (status === "todo") {
      return <Button variant="success">Nouveau</Button>;
    }
    if (status === "pickup") {
      return <Button variant="warning">Parking</Button>;
    }
    if (status === "return") {
      return <Button variant="secondary">Terminé</Button>;
    }
  };

  const renderAllRequest = () => {
    //if(userData.type === 'admin'){
    //    return requests;
    //}
    //if(userData.type === 'driver'){
    return requests.todo_car_request;
    //}
  };

  const openCloseModalForm = (type, id) => {
    setOpenModalForm(openModalForm ? false : { type: type, id: id });
    if (openModalForm) {
      setLoadingApi(true);
      if (userData.type === "driver") {
        authApi
          .driverRequest()
          .then((response) => {
            setLoadingApi(false);
            setRequests(response.data);
          })
          .catch((error) => {
            setLoadingApi(false);
            setErrorApi(true);
          });
      }
    }
  };

  const onOpenCloseViewRequest = (id) => {
    setOpenModalView(openModalView ? false : id);
  };

  const onOpenCloseEditRequest = (id) => {
    setOpenModalEdit(openModalEdit ? false : id);
  };



  return (
    <>
      {errorApi ? (
        <AlertGeneral
          color="danger"
          description="error loading request"
          setTrigger={setErrorApi}
        />
      ) : null}
      {openModalView ? (
        <ModalView
          onOpenCloseViewRequest={onOpenCloseViewRequest}
          openModalView={openModalView}
          renderStatus={renderStatus}
        />
      ) : null}
      {openModalForm ? (
        <ModalForm
          openCloseModalForm={openCloseModalForm}
          openModalForm={openModalForm}
          setOpenModalForm={setOpenModalForm}
        />
      ) : null}
      {openModalEdit ? (
        <ModalEdit
          onOpenCloseEditRequest={onOpenCloseEditRequest}
          openModalEdit={openModalEdit}
          setOpenModalEdit={setOpenModalEdit}
        />
      ) : null}

      {!loadingApi ? (
        <Container fluid>
          <Tabs defaultActiveKey="ongoing" className={classes.customTab}>
            <Tab
              eventKey="ongoing"
              title={
                <span className={`${classes.customTab}`}>Aujourd'hui</span>
              }
            >
              {requests &&
                (requests.todo_car_request || requests.return_car_request) && (
                  <TableRequests
                    title={"Aujourd'hui"}
                    requests={[
                      ...requests.todo_car_request.filter((request) => {
                        const date = new Date(request.departue_date_time);
                        return dayjs().isSame(dayjs(date), "day");
                      }),
                      ...requests.pick_up_car_request.filter((request) => {
                        const date = new Date(request.arrival_date_time);
                        return dayjs().isSame(dayjs(date), "day");
                      }),
                    ]}
                    openCloseModalForm={openCloseModalForm}
                    onOpenCloseViewRequest={onOpenCloseViewRequest}
                    onOpenCloseEditRequest={onOpenCloseEditRequest}
                    all
                    subscribers={subscribers}
                  />
                )}
            </Tab>

            <Tab
              eventKey="week"
              title={
                <span className={`${classes.customTab}`}>Cette semaine  </span>
              }
            >
              {requests &&
                (requests.todo_car_request || requests.return_car_request) && (
                  <TableRequests
                    title={"Cette semaine"}
                    requests={[
                      ...requests.todo_car_request.filter((request) => {
                        const date = moment(request.departue_date_time);
                        return date.isBetween(
                          moment().startOf("week"),
                          moment().endOf("week"),
                          null,
                          "[]"
                        );
                      }),
                      ...requests.pick_up_car_request.filter((request) => {
                        const date = moment(request.arrival_date_time);
                        return date.isBetween(
                          moment().startOf("week"),
                          moment().endOf("week"),
                          null,
                          "[]"
                        );
                      }),
                    ]}
                    openCloseModalForm={openCloseModalForm}
                    onOpenCloseViewRequest={onOpenCloseViewRequest}
                    onOpenCloseEditRequest={onOpenCloseEditRequest}
                    all
                    subscribers={subscribers}
                  />
                )}
            </Tab>

            <Tab
              eventKey="parking"
              title={<span className={`${classes.customTab}`}>Parking</span>}
            >
              {requests && requests.pick_up_car_request && (
                <TableRequests
                  title={"Retours prévus"}
                  requests={requests.pick_up_car_request}
                  openCloseModalForm={openCloseModalForm}
                  onOpenCloseViewRequest={onOpenCloseViewRequest}
                  onOpenCloseEditRequest={onOpenCloseEditRequest}
                  returns
                  subscribers={subscribers}
                />
              )}
            </Tab>

            <Tab
              eventKey="reservations"
              title={<span className={`${classes.customTab}`}>À venir</span>}
            >
              {requests && requests.todo_car_request && (
                <TableRequests
                  title={
                    userData.type === "driver"
                      ? "Nouvelles Réservations"
                      : "Réservations"
                  }
                  requests={renderAllRequest()}
                  openCloseModalForm={openCloseModalForm}
                  onOpenCloseViewRequest={onOpenCloseViewRequest}
                  onOpenCloseEditRequest={onOpenCloseEditRequest}
                  subscribers={subscribers}
                />
              )}
            </Tab>

            <Tab
              eventKey="historique"
              title={<span className={`${classes.customTab}`}>Historique</span>}
            >
              {requests && requests.return_car_request && (
                <TableRequests
                  title={"Historique des réservations"}
                  past
                  requests={requests.return_car_request}
                  openCloseModalForm={openCloseModalForm}
                  onOpenCloseViewRequest={onOpenCloseViewRequest}
                  onOpenCloseEditRequest={onOpenCloseEditRequest}
                  noEdit
                />
              )}
            </Tab>
          </Tabs>
        </Container>
      ) : (
        <Container fluid style={{ marginTop: "100px" }}>
          <LoadingButton />
        </Container>
      )}
    </>
  );
};

export default Requests;
