import React, {useState, useEffect} from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
//-------------- Components ----------------\
import TextBox from '../../../Components/Commons/FormComponent/TextBox';
import LoadingButton from '../../../Components/Commons/LoadingButton/LoadingButton';
import ModalSimple from '../../../Components/Commons/ModalSimple/ModalSimple';
import AlertGeneral from '../../../Components/Commons/AlertGeneral/AlertGeneral';
//-------------- Others --------------------
import { useForm } from 'react-hook-form';
import {Link, useParams, useHistory} from 'react-router-dom'
import * as authApi from '../../../Axios/Call/AuthApi';
import {BiArrowBack} from 'react-icons/bi';
//------------- Style -----------------------
import classes from './CreateEditDriver.module.css'

const CreateEditDrive = () => {
    const {id} = useParams();
    const history = useHistory();
    const [driver, setDriver] = useState({});
    const [loadingApi, setLoadingApi] = useState(false);
    const [errorApi, setErrorApi] = useState(false);
    const [errorApiAccount, setErrorApiAccount] = useState(false);
    const [loadingApiPost, setLoadingApiPost] = useState(false);
    const [errorApiPost, setErrorApiPost] = useState(false);
    const [successApiPost,setSuccessApiPost] = useState(false);

    useEffect(() => {
        if(id){
            setLoadingApi(true);
            authApi.adminShowClient(id).then(response => {
                setDriver(response.data);
                reset(response.data);
                setLoadingApi(false);
            }).catch(error => {
                setLoadingApi(false);
                setErrorApi(true)
            })
        }
    },[id]);

    const { control, register, handleSubmit, errors, watch, reset } = useForm({
        defaultValues:{
            email: '',
            address: '',
            name: '',
            surname: '',
            license: '',
            phone: '',
        }
    }); // initialise the hook

    const emailValidator = (value) => value.trim().match(/^.+@.+$/) || 'Email invalide';
    const passwordValidator = (value) => value.trim().match(/^[^\s]{8,20}$/) || "passe invalide : min 8 caractères, pas d'espaces, max 20 caractères";
    const passwordConfirmValidator = (value) => value === watch('password') || 'Les mots de passe ne correspondent pas';

    const onClickAddDriver = (data) => {
        setLoadingApiPost(true);
        setErrorApiAccount(false);
        if(id){
            authApi.adminEditDriver(id,data).then(response => {
                setLoadingApiPost(false);
                setSuccessApiPost(true);
                history.push('/users');
            }).catch(error => {
                setLoadingApiPost(false);
                setErrorApiAccount(true);
            })
        }else{
            authApi.adminCreateDriver(data).then(response => {
                setLoadingApiPost(false);
                setSuccessApiPost(true);
                history.push('/users');
            }).catch(error => {
                setLoadingApiPost(false);
                setErrorApiAccount(true);
            })
        }
    }

    return(<>
        {errorApi ? <AlertGeneral color='danger' description='Erreur de chargement du voiturier' setTrigger={setErrorApi} /> : null}
        {successApiPost ? <AlertGeneral color='success' description='Le voiturier a été créé' setTrigger={setSuccessApiPost} /> : null}
        {errorApiPost ? <AlertGeneral color='danger' description='Il y a eu une erreur lors de la création du voiturier' setTrigger={setErrorApiPost} /> : null}
        <Container fluid>
            <Row style={{marginTop: '2rem'}}>
                <Col className='text-left'>
                    <Row>
                        <Col className='my-auto' xs={1}>
                            <Link to='/users'>
                                <BiArrowBack size={38} color={'#000'}/>
                            </Link>
                        </Col>
                        <Col className='text-left'>
                            <span className='title'>
                                {id && Object.keys(driver).length ? 'Modifier '+driver.name+' '+driver.surname  : 'Creation voiturier' }
                            </span>
                        </Col>
                    </Row>
                </Col>
            </Row>
            { ( id && Object.keys(driver).length ) || !id ? 
            <Form onSubmit={handleSubmit(onClickAddDriver)} >
                <Row style={{ marginTop: '1rem'}}>
                    <Col xs={12} lg={6}>
                        <TextBox ref={register({ validate: emailValidator })} placeHolder={'Écrivez ici'} type={'email'} name={'email'}
                            error={ errors.email ?  errors.email.message : errorApiAccount ? 'Email déjà utilisé' : ''}  
                            labelStyle={{color: 'black'}}
                            errorStyle={{color: 'red'}}
                            offset={0} span={12} labelName={'Email'}
                        />
                    </Col>
                    <Col xs={12} lg={6} className='colMarginForm'>
                        <TextBox ref={register({ required: 'true' })} placeHolder={'Écrivez ici'} type={'input'} name={'name'}
                            error={ errors.name ?  'Champ requis' : ''} offset={0} span={12} labelName={'Prénom'}
                            labelStyle={{color: 'black'}}
                            errorStyle={{color: 'red'}}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: '1rem'}}>
                    <Col xs={12} lg={6}>
                        <TextBox ref={register({ required: 'true' })} placeHolder={'Écrivez ici'} type={'input'} name={'surname'}
                            error={ errors.surname ?  'Champ requis' : ''}  
                            labelStyle={{color: 'black'}}
                            errorStyle={{color: 'red'}}
                            offset={0} span={12} labelName={'Nom de famille'}
                        />
                    </Col>
                    <Col xs={12} lg={6} className='colMarginForm'>
                        <TextBox ref={register({ required: 'true' })} placeHolder={'Écrivez ici'} type={'input'} name={'phone'}
                            error={ errors.phone ?  'Champ requis' : ''} offset={0} span={12} labelName={'Téléphone'}
                            labelStyle={{color: 'black'}}
                            errorStyle={{color: 'red'}}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: '1rem'}}>
                    <Col xs={12} lg={6}>
                        <TextBox ref={register({ required: 'true' })} placeHolder={'Écrivez ici'} type={'input'} name={'address'}
                            error={ errors.address ?  'Champ requis' : ''}  
                            labelStyle={{color: 'black'}}
                            errorStyle={{color: 'red'}}
                            offset={0} span={12} labelName={'Adresse'}
                        />
                    </Col>
                    <Col xs={12} lg={6} className='colMarginForm'>
                        <TextBox ref={register({ required: 'true' })} placeHolder={'Écrivez ici'} type={'input'} name={'license'}
                            error={ errors.license ?  'Champ requis' : ''} offset={0} span={12} labelName={'Permis de conduire'}
                            labelStyle={{color: 'black'}}
                            errorStyle={{color: 'red'}}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: '1rem'}}>
                    <Col xs={12} lg={6}>
                        <TextBox ref={register({ required: 'true' })} placeHolder={'Écrivez ici'} type={'input'} name={'city'}
                            error={ errors.city ?  'Champ requis' : ''}  
                            labelStyle={{color: 'black'}}
                            errorStyle={{color: 'red'}}
                            offset={0} span={12} labelName={'Ville'}
                        />
                    </Col>
                    <Col xs={12} lg={6} className='colMarginForm'>
                        <TextBox ref={register({ required: 'true' })} placeHolder={'Écrivez ici'} type={'input'} name={'cap'}
                            error={ errors.cap ?  'Champ requis' : ''} offset={0} span={12} labelName={'Code postal'}
                            labelStyle={{color: 'black'}}
                            errorStyle={{color: 'red'}}
                        />
                    </Col>
                </Row>
                <Row className='rowMarginForm'>
                    <Col xs={12} lg={6} className='colMarginForm'>
                        <TextBox ref={register(!id ? {validate: passwordValidator } : {})} placeHolder={'Écrivez ici'} type={'password'} name={'password'}
                            error={ errors.password ?  errors.password.message : ''} offset={0} span={12} labelName={'Mot de passe'}
                            labelStyle={{color: 'black', }}
                            errorStyle={{color: 'red', }}
                        />
                    </Col>
                    <Col xs={12} lg={6} className='colMarginForm'>
                        <TextBox ref={register(!id ? { validate: passwordConfirmValidator } : {})} placeHolder={'Écrivez ici'} type={'password'} name={'confirm_password'}
                            error={ errors.confirm_password ?  errors.confirm_password.message : ''} offset={0} span={12} labelName={'Confirmez votre mot de passe'}
                            labelStyle={{color: 'black', }}
                            errorStyle={{color: 'red', }}
                        />
                    </Col>
                </Row>
                <Row style={{marginTop: '2rem'}}>
                    <Col>
                        <Button type='submit' className={'buttonGeneralStyle'} sDrivertyle={{width: 200}}><span >
                            {id ? 'Modifier le voiturier' : 'Creation voiturier' }
                        </span></Button>
                    </Col>
                </Row>
            </Form>
            : <LoadingButton /> }
        </Container>
    </>)
}

export default CreateEditDrive;