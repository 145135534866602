import React, { useState, useEffect } from "react";
import * as authApi from "../../../Axios/Call/AuthApi";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import classes from "./ClientList.module.css";
import LoadingButton from "../../../Components/Commons/LoadingButton/LoadingButton";
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const ClientList = () => {
  const [clients, setClients] = useState([]);
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("nom");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  useEffect(() => {
    setLoadingApi(true);
    authApi
      .adminGetClients()
      .then((response) => {
        setLoadingApi(false);
        setClients(response.data);
      })
      .catch((error) => {
        setErrorApi(true);
        setLoadingApi(false);
      });
  }, []);

  return (
    <>
      {errorApi ? (
        <AlertGeneral
          color="danger"
          description="Error loading clients"
          setTrigger={setErrorApi}
        />
      ) : null}
      <Container fluid>
        <Row style={{ paddingTop: "4rem" }}>
          <Col>
            <Row>
              <Col className="text-left">
                <span className="title">Clients</span>
              </Col>
            </Row>
            <Row className="cardStyle">
              <Col>
                <TableContainer component={Paper}>
                  <Table style={{ width: "100%" }}>
                    <TableHead className="tableBackground">
                      <TableRow>
                        <TableCell align="left">
                          <TableSortLabel
                            active={orderBy === "name"}
                            direction={orderBy === "name" ? order : "asc"}
                            onClick={createSortHandler("name")}
                          >
                            <span className="tableHeaderStyle">Nom</span>
                          </TableSortLabel>
                        </TableCell>

                        <TableCell align="left">
                          <TableSortLabel
                            active={orderBy === "email"}
                            direction={orderBy === "email" ? order : "asc"}
                            onClick={createSortHandler("email")}
                          >
                            <span className="tableHeaderStyle">Email</span>
                          </TableSortLabel>
                        </TableCell>
                        <TableCell align="left">
                          <TableSortLabel
                            active={orderBy === "phone"}
                            direction={orderBy === "phone" ? order : "asc"}
                            onClick={createSortHandler("phone")}
                          >
                            <span className="tableHeaderStyle">Téléphone</span>
                          </TableSortLabel>
                        </TableCell>
                        <TableCell align="left">
                          <TableSortLabel
                            active={orderBy === "company"}
                            direction={orderBy === "company" ? order : "asc"}
                            onClick={createSortHandler("company")}
                          >
                            <span className="tableHeaderStyle">Entreprise</span>
                          </TableSortLabel>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {stableSort(clients, getComparator(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((client) => (
                          <TableRow key={client.id}>
                            <TableCell
                              component="th"
                              scope="row"
                              align={"left"}
                            >
                              {client.name} {client.surname}
                            </TableCell>
                            <TableCell align="left">{client.email}</TableCell>
                            <TableCell align="left">{client.phone}</TableCell>
                            <TableCell align="left">{client.company}</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={clients.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  labelRowsPerPage="Nombre de lignes"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ClientList;
