import React, {useEffect, useState} from 'react';
import * as authApi from '../../../Axios/Call/AuthApi';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import classes from './PromoCodeList.module.css';
import LoadingButton from '../../../Components/Commons/LoadingButton/LoadingButton';
import AlertGeneral from '../../../Components/Commons/AlertGeneral/AlertGeneral';
import { AiOutlineForm, AiOutlineStop } from 'react-icons/ai';
import {GrStatusGood} from 'react-icons/gr';
import {useHistory} from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { BiTrash } from "react-icons/bi";
import ModalDelete from "../../../Components/Admin/ModalDelete/ModalDelete";
import ModalDeletePromo from "../../../Components/Admin/ModalDeletePromo/ModalDelete";

const PromoCodeList = () => {
    const [codes, setCodes] = useState([]);
    const [errorApi, setErrorApi] = useState(false);
    const [loadingApi, setLoadingApi] = useState(false);
    const history = useHistory();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('nom');
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [codeIdToDelete, setCodeIdToDelete] = useState();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }
      
    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    const getPromoCodes = () => {
        setLoadingApi(true);
        authApi.getPromoCode().then(response => {
            setLoadingApi(false);
            setCodes(response.data);
        }).catch(error => {
            setErrorApi(false);
            setLoadingApi(false);
        });
    };

    const deletePromoCode = (id) => {
        setLoadingApi(true);
        authApi.deletePromoCode(id).then(response => {
            setLoadingApi(false);
            getPromoCodes();
        }).catch(error => {
            setErrorApi(false);
            setLoadingApi(false);
        });
    };

    useEffect(() => {
        getPromoCodes();
    },[]);


    return(<>
        {errorApi ? <AlertGeneral color='danger' description='Error loading clients' setTrigger={setErrorApi} /> : null}
        <Container fluid >
            <Row style={{paddingTop: '4rem'}}>
                <Col>
                    <Row>
                        <Col className='text-left my-auto' xs={6} lg={3} style={{paddingLeft:"40px"}}>
                            <span className='title'>Codes promo</span>
                        </Col>
                        <Col className='text-left'>
                            <Button className='buttonGeneralStyleMenu' onClick={() => history.push('promo-code')}>Nouveau code promo</Button>
                        </Col>
                    </Row>
                    <Row className='cardStyle'>
                        <Col>
                        <TableContainer component={Paper}>
                            <Table style={{width: '100%'}}>
                                <TableHead className='tableBackground'>
                                <TableRow>
                                    <TableCell align="left" style={{width:300}}>
                                        <TableSortLabel
                                            active={orderBy === 'name'}
                                            direction={orderBy === 'name' ? order : 'asc'}
                                            onClick={createSortHandler('name')}
                                            ><span className='tableHeaderStyle'>Nom</span>
                                         </TableSortLabel>
                                    </TableCell>
                                        
                                
                                    <TableCell align="left" style={{width:300}}>
                                        <span className='tableHeaderStyle'>Code</span>            
                                    </TableCell>
                                    <TableCell align="left" style={{width:300}}>
                                        <span className='tableHeaderStyle'>Type</span>            
                                    </TableCell>
                                    <TableCell align="left" style={{width:300}}>
                                        <span className='tableHeaderStyle'>Montant</span>            
                                    </TableCell>
                                    <TableCell align="left" style={{width:300}}>
                                        <span className='tableHeaderStyle'>Statut</span>            
                                    </TableCell>
                                    <TableCell align="left">
                                        <span className='tableHeaderStyle'>Actions</span>
                                    </TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {stableSort(codes, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((code) => (
                                    <TableRow key={code.id}>
                                        <TableCell component="th" scope="row" align={'left'}>
                                            {code.name}
                                        </TableCell>
                                       
                                        <TableCell align="left">{code.code}</TableCell>
                                        <TableCell component="th" scope="row" align={'left'}>
                                            {code.type ==="percentage" ? "Pourcentage" : "Montant"}
                                        </TableCell>
                                        <TableCell component="th" scope="row" align={'left'}>
                                            {code.type !=="price" ? code.price + '%': code.price+ '€'} 
                                        </TableCell>
                                        <TableCell component="th" scope="row" align={'left'}>
                                            {code.is_active ===0 ? "Inactif" : "Actif"}
                                        </TableCell>
                                        <TableCell align="left">
                                            <Row>
                                                <Col xs={3}>
                                                    <AiOutlineForm style={{ cursor: "pointer" }} onClick={() => history.push('/promo-code/'+code.id)}/>
                                                </Col>
                                                <Col xs={3}>
                                                    <BiTrash style={{paddingLeft:"", cursor: "pointer" }} onClick={() => { setShowModalDelete(true); setCodeIdToDelete(code.id); }}/>
                                                    {showModalDelete && <ModalDeletePromo openCloseModalForm={() => setShowModalDelete(!showModalDelete)} onConfirmClick={() => deletePromoCode(codeIdToDelete)}/>}
                                                </Col>
                                            </Row>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={codes.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            labelRowsPerPage='Nombre de lignes'
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    
    </>)
}


export default PromoCodeList;