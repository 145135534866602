import { authApiAxios } from "../AxiosInstance";

export const me = () => {
  return authApiAxios.get("me");
};

export const adminRequest = () => {
  return authApiAxios.get("admin/requests");
};

export const adminEditRequest = (id, data) => {
  return authApiAxios.put("admin/request/update/" + id, data);
};

export const driverRequest = () => {
  return authApiAxios.get("driver/requests");
};

export const createForm = (data) => {
  return authApiAxios.post("driver/request", data);
};

export const driverGetRequest = (id) => {
  return authApiAxios.get("driver/request/show/" + id);
};

export const adminGetRequest = (id) => {
  return authApiAxios.get("admin/request/show/" + id);
};

export const adminGetClients = () => {
  return authApiAxios.get("admin/clients");
};

export const adminGetDrivers = () => {
  return authApiAxios.get("admin/drivers");
};

export const adminShowClient = (id) => {
  return authApiAxios.get("admin/user/" + id);
};

export const adminEditDriver = (id, data) => {
  return authApiAxios.post("admin/driver/" + id, data);
};

export const adminCreateDriver = (data) => {
  return authApiAxios.post("admin/driver", data);
};

export const paymentHandler = (data) => {
  return authApiAxios.post("payment-intent", data);
};

export const createRequest = (data) => {
  return authApiAxios.post("client/request", data);
};

export const timeReturn = (date) => {
  return authApiAxios.post("time/arrival", date);
};

export const timeDepatue = (date) => {
  return authApiAxios.post("time/departue", date);
};

export const getAdditionalServices = () => {
  return authApiAxios.get("additional-services");
};

export const createAdditionlService = (data) => {
  return authApiAxios.post("additional-service", data);
};

export const getOneAdditionalService = (id) => {
  return authApiAxios.get("additional-service/" + id);
};

export const editAdditionalService = (id, data) => {
  return authApiAxios.post("additional-service/" + id, data);
};

export const changeStatusService = (id) => {
  return authApiAxios.delete("additional-service/" + id);
};

/// COUPON CODES

export const getPromoCode = () => {
  return authApiAxios.get("coupons");
};

export const createPromoCode = (data) => {
  return authApiAxios.post("coupon", data);
};

export const getOnePromoCode = (id) => {
  return authApiAxios.get("coupon/" + id);
};

export const getPromoCodeByCode = (code) => {
  return authApiAxios.get("coupon/find-by-code?code=" + code);
};

export const editPromoCode = (id, data) => {
  return authApiAxios.patch("coupon/" + id, data);
};

export const deletePromoCode = (id) => {
  return authApiAxios.delete("coupon/" + id);
};

// DEPARTUE
export const getDepartuePrices = (id) => {
  return authApiAxios.get("departue-prices/" + id);
};

export const getOneDepartuePrice = (id) => {
  return authApiAxios.get("departue-price/" + id);
};

export const createDepartuePrice = (data) => {
  return authApiAxios.post("departue-price", data);
};

export const editDepartuePrice = (id, data) => {
  return authApiAxios.post("departue-price/" + id, data);
};

export const getAdditionalServicesClient = () => {
  return authApiAxios.get("additional-services/client");
};

export const createReservationAdmin = (data) => {
  return authApiAxios.post("admin/request", data);
};

export const deleteCarRequest = (id) => {
  return authApiAxios.delete(`admin/request/${id}`);
};

export const updateProfile = (data) => {
  return authApiAxios.put("me", data);
};

export const clientRequests = () => {
  return authApiAxios.get("car-requests");
};

export const clientrquestById = (id) => {
  return authApiAxios.get("car-requests/" + id);
};

// SUBSCRIPTIONS

export const getSubscriptions = () => {
  return authApiAxios.get("");
};

export const listOfSubscribers = () => {
  return authApiAxios.get("users-subscribed");
}

export const cancelSubscription = (id) => {
  return authApiAxios.delete("cancel_subscription/" + id);
}

export const resumeSubscription = (id) => {
  return authApiAxios.post("resume_subscription/" + id);
}


