import React, {useEffect} from 'react';
import {useSelector,useDispatch} from 'react-redux';
import * as actionCreator from '../store/action/index';
import { useHistory, Link} from 'react-router-dom'

const LayoutCheckAdmin = ({children}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const userData = useSelector((state) => state.user.userData)

    useEffect(() => {
        if(localStorage.getItem('token')){
            dispatch(actionCreator.populateUserData());
        }
    },[])


    useEffect(() => {
        if(Object.keys(userData).length && ( userData.type !== 'admin') ){
            history.push('/403')
        }
    },[userData])

    return(<>
        {children}
    </>)
}

export default LayoutCheckAdmin;