import React from "react";
//---------- Bootstrap -----------------
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
//---------- Components -----------------
//---------- Other import ----------------
import * as actionCreator from "../../../store/action/index";
import { Link, useLocation, useHistory } from "react-router-dom";
import classes from "./Navbar.module.css";
import { useSelector, useDispatch } from "react-redux";
import { useWindowWidth } from "@react-hook/window-size";
import { AiFillCar } from "react-icons/ai";
import { BsFillPeopleFill, BsFillPersonFill } from "react-icons/bs";
import { CgProfile } from "react-icons/cg";
import {HiOutlineBadgeCheck} from "react-icons/hi";

import { BiLogOut } from "react-icons/bi";
import { FaFileInvoiceDollar, FaPercent } from "react-icons/fa";
import { BsBuilding } from "react-icons/bs";

const NavbarCustomer = (props) => {
  const screenWidth = useWindowWidth();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);

  const onClickLogoutHandler = () => {
    dispatch(actionCreator.cleanUserData());
    history.push("/");
  };

  return (
    <>
      <Nav className={"flex-column " + classes.navStyle}>
        {userData.type === "admin" ? (
          <Nav.Item
            as={Link}
            to={"/admin/create-reservation"}
            onClick={() => props.setIsOpen(false)}
            style={{ marginTop: 0, textDecoration: "none" }}
          >
            <div >
              <Button
                className={
                  location.pathname === "/admin/create-reservation"
                    ? "buttonGeneralStyleMenu"
                    : "buttonGeneralStyleMenu"
                }
              >
                Nouvelle réservation
              </Button>
            </div>
          </Nav.Item>
        ) : null}

        <Nav.Item
          as={Link}
          to={userData ? `/${userData.type}/requests` :'/'}
          onClick={() => props.setIsOpen(false)}
          style={{ marginTop: 20, textDecoration: "none" }}
        >
          <div className={"text-left"}>
          <span
              className={
                location.pathname === `/${userData.type}/requests`
                  ? classes.nameStyleSelected
                  : classes.nameStyle
              }
            >
            <AiFillCar size={28}  style={{ paddingRight: 10 }} />
            
              Réservations
            </span>
          </div>
        </Nav.Item>

       

        {userData.type === "client" ? (
          <Nav.Item
            as={Link}
            to={"/client/facturation"}
            onClick={() => props.setIsOpen(false)}
            style={{ marginTop: 20, textDecoration: "none" }}
          >
            <div className={"text-left"}>
            <span
                className={
                  location.pathname === "/facturation"
                    ? classes.nameStyleSelected
                    : classes.nameStyle
                }
              >
              <FaFileInvoiceDollar
                size={23}
         
                style={{ paddingRight: 10 }}
              />
             
                Facturation
              </span>
            </div>
          </Nav.Item>
        ) : null}

{userData.type === "admin" ? (
          <Nav.Item
            as={Link}
            to={"/subscriptions"}
            onClick={() => props.setIsOpen(false)}
            style={{ marginTop: 20, textDecoration: "none" }}
          >
            <div className={"text-left"}>
            <span
                className={
                  location.pathname === "/subscriptions"
                    ? classes.nameStyleSelected
                    : classes.nameStyle
                }
              >
              <HiOutlineBadgeCheck
                size={28}
  
                style={{ paddingRight: 10 }}
              />
              
                Abonnements
              </span>
            </div>
          </Nav.Item>
        ) : null}


        {userData.type === "admin" ? (
          <Nav.Item
            as={Link}
            to={"/clients"}
            onClick={() => props.setIsOpen(false)}
            style={{ marginTop: 20, textDecoration: "none" }}
          >
            <div className={"text-left"}>
            <span
                className={
                  location.pathname === "/clients"
                    ? classes.nameStyleSelected
                    : classes.nameStyle
                }
              >
              <BsFillPeopleFill
                size={28}
  
                style={{ paddingRight: 10 }}
              />
              
                Clients
              </span>
            </div>
          </Nav.Item>
        ) : null}
        {userData.type === "admin" ? (
          <Nav.Item
            as={Link}
            to={"/users"}
            onClick={() => props.setIsOpen(false)}
            style={{ marginTop: 20, textDecoration: "none" }}
          >
            <div className={"text-left"}>
            <span
                className={
                  location.pathname === "/users"
                    ? classes.nameStyleSelected
                    : classes.nameStyle
                }
              >
              <BsFillPersonFill
                size={28}
             
                style={{ paddingRight: 10 }}
              />
             
                Utilisateurs
              </span>
            </div>
          </Nav.Item>
        ) : null}
        {userData.type === "admin" ? (
          <Nav.Item
            as={Link}
            to={"/departues"}
            onClick={() => props.setIsOpen(false)}
            style={{ marginTop: 20, textDecoration: "none" }}
          >
            <div className={"text-left"}>
            <span
                className={
                  location.pathname === "/departues"
                    ? classes.nameStyleSelected
                    : classes.nameStyle
                }
              >
              <BsBuilding
                size={28}
           
                style={{ paddingRight: 10 }}
              />
             
                Lieux
              </span>
            </div>
          </Nav.Item>
        ) : null}
        {userData.type === "admin" ? (
          <Nav.Item
            as={Link}
            to={"/additional-services"}
            onClick={() => props.setIsOpen(false)}
            style={{ marginTop: 20, textDecoration: "none" }}
          >
            <div className={"text-left"}>
            <span
                className={
                  location.pathname === "/additional-services"
                    ? classes.nameStyleSelected
                    : classes.nameStyle
                }
              >
              <FaFileInvoiceDollar
                size={23}
       
                style={{ paddingRight: 10 }}
              />
             
                Services additionnels
              </span>
            </div>
          </Nav.Item>
        ) : null}

        {userData.type === "admin" ? (
          <Nav.Item
            as={Link}
            to={"/promo-codes"}
            onClick={() => props.setIsOpen(false)}
            style={{ marginTop: 20, textDecoration: "none" }}
          >
            <div className={"text-left"}>
            <span
                className={
                  location.pathname === "/promo-codes"
                    ? classes.nameStyleSelected
                    : classes.nameStyle
                }
              >
              <FaPercent
                size={23}
           
                style={{ paddingRight: 10 }}
              />
              
                Codes promo
              </span>
            </div>
          </Nav.Item>
        ) : null}

<Nav.Item
          as={Link}
          to="/profil"
          onClick={() => props.setIsOpen(false)}
          style={{ marginTop: 20, textDecoration: "none" }}
        >
          <div className={"text-left"}>
          <span
              className={
                location.pathname === "/profil"
                  ? classes.nameStyleSelected
                  : classes.nameStyle
              }
            >
            <CgProfile size={28}  style={{ paddingRight: 10 }} />
           
              Mon profil
            </span>
          </div>
        </Nav.Item>

        <br />
        <Nav.Item
          as={Link}
          to="/"
          onClick={() => props.setIsOpen(false)}
          className={
            screenWidth > 575
              ? userData.type === "driver"
                ? classes.styleLogoutDriver
                : classes.styleLogoutAdmin
              : ""
          }
          style={{
            textDecoration: "none",
            marginTop: screenWidth > 575 ? 10 : 30,
          }}
        >
          <div
            className={`text-left`}
            style={{
              width: "90%",
              paddingTop: 10,
              borderTop: "1px solid white",
            }}
          >
            <BiLogOut size={30} color={"white"} style={{ paddingRight: 10 }} />
            <span
              className={classes.nameStyle}
              onClick={() => onClickLogoutHandler()}
            >
              Déconnexion
            </span>
          </div>
        </Nav.Item>
      </Nav>
    </>
  );
};

export default NavbarCustomer;
