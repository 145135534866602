import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import moment from "moment";
import { AiOutlineForm, AiFillEye, AiFillEdit } from "react-icons/ai";
import { useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import { BiTrash, BiMobileAlt, BiPencil, BiWorld } from "react-icons/bi";
import ModalDelete from "../ModalDelete/ModalDelete";
import {HiBadgeCheck} from "react-icons/hi";

const TableRequests = ({
  requests,
  title,
  openCloseModalForm,
  onOpenCloseViewRequest,
  onOpenCloseEditRequest,
  noEdit,
  returns,
  past,
  all,
  subscribers,
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("departue_date_time");

  const [modalDelete, setModalDelete] = useState(false);
  const userData = useSelector((state) => state.user.userData);


  const placeMap = {
    "rennes-airport": "Aéroport",
    "rennes-trainstation": "Gare",
    1: "Aéroport",
    3: "Gare",
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function somme () {
    let total = 0;
     requests.map((request) => {
      total += (request.pay_amount/100);
    })
    return total.toFixed(0);
  
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "desc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };


  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const sortedRequests = requests.sort((a, b) => {

    let dateA = null;
    let dateB = null;
    let timeA = null;
    let timeB = null;

    if (returns) {
      dateA = moment(a.arrival_date_time).format("YYYY-MM-DD");
      dateB = moment(b.arrival_date_time).format("YYYY-MM-DD");
      timeA = a.time_arrival_id;
      timeB = b.time_arrival_id;
    } else {
      dateA = moment(a.departue_date_time).format("YYYY-MM-DD");
      dateB = moment(b.departue_date_time).format("YYYY-MM-DD");
      timeA = a.time_departue_id;
      timeB = b.time_departue_id;
    }
    if (past) {
      dateB = moment(a.departue_date_time).format("YYYY-MM-DD");
      dateA = moment(b.departue_date_time).format("YYYY-MM-DD");
      timeA = a.time_departue_id;
      timeB = b.time_departue_id;
    }
    if (all) {
      if (a.status === "todo" && b.status === "todo") {
        dateA = moment(a.departue_date_time).format("YYYY-MM-DD");
        dateB = moment(b.departue_date_time).format("YYYY-MM-DD");
        timeA = a.time_departue_id;
        timeB = b.time_departue_id;
      } else if (a.status === "todo" && b.status === "pickup") {
        dateA = moment(a.departue_date_time).format("YYYY-MM-DD");
        dateB = moment(b.arrival_date_time).format("YYYY-MM-DD");
        timeA = a.time_departue_id;
        timeB = b.time_arrival_id;
      } else if (a.status === "pickup" && b.status === "todo") {
        dateA = moment(a.arrival_date_time).format("YYYY-MM-DD");
        dateB = moment(b.departue_date_time).format("YYYY-MM-DD");
        timeA = a.time_arrival_id;
        timeB = b.time_departue_id;
      } else if (a.status === "pickup" && b.status === "pickup") {
        dateA = moment(a.arrival_date_time).format("YYYY-MM-DD");
        dateB = moment(b.arrival_date_time).format("YYYY-MM-DD");
        timeA = a.time_arrival_id;
        timeB = b.time_arrival_id;
      }
    }

    if (dateA < dateB) {
      return -1; // Date A est avant la date B
    } else if (dateA > dateB) {
      return 1; // Date A est après la date B
    } else {
      return timeA - timeB; // Même date, trier par heure de départ
    }
  });

  const createSortHandler = (property, property2) => (event) => {
    handleRequestSort(event, property);
    handleRequestSort(event, property2);

   
  };

  const renderStatus = (status) => {

 

    if (status === "todo") {
      return (
        <Button variant="success" style={{ fontFamily: "Satoshi-Bold" }}>
          Réservation
        </Button>
      );
    }
    if (status === "pickup") {
      return (
        <Button variant="warning" style={{ fontFamily: "Satoshi-Bold" }}>
          Parking
        </Button>
      );
    }
    if (status === "return") {
      return (
        <Button variant="secondary" style={{ fontFamily: "Satoshi-Bold" }}>
          Terminée
        </Button>
      );
    }
  };

  return (
    <>
      {modalDelete ? (
        <ModalDelete
          openCloseModalForm={() => setModalDelete(!modalDelete)}
          modalValue={modalDelete}
        />
      ) : null}

      <Row style={{ marginBottom: "1rem", paddingTop:'2rem' }}>
        <Col className="text-left ">
          <span className="title">
            {title} <span className="small font-italic">({requests.length} - {somme()}€)</span> 
          </span>
        </Col>
      </Row>

      <Row>
        <Col>
          <TableContainer component={Paper}>
            <Table style={{ width: "100%" }}>
              <TableHead className="tableBackground">
                <TableRow>
                  <TableCell align="left">
                    <span className="tableHeaderStyle">Aller</span>
                  </TableCell>

                  <TableCell align="left">
                    <span className="tableHeaderStyle">Retour</span>
                  </TableCell>
                  <TableCell align="left">
                    <span className="tableHeaderStyle">Client</span>
                  </TableCell>
                  <TableCell align="left">
                    <span className="tableHeaderStyle">Prix</span>
                  </TableCell>
                  <TableCell align="left">
                    <span className="tableHeaderStyle">Services +</span>
                  </TableCell>

                  <TableCell align="left">
                    <span className="tableHeaderStyle">Statut</span>
                  </TableCell>
                  <TableCell align="left">
                    <span className="tableHeaderStyle">
                      {userData.type != "driver" ? "Réservation" : "Actions"}
                    </span>
                  </TableCell>
                  {userData.type === "admin" && (
                    <TableCell align="left">
                      <span className="tableHeaderStyle">Action</span>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedRequests
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((request) => (
                    <TableRow key={request.id}>
                      <TableCell component="th" scope="row" align={"left"}>
                        <span
                          style={{
                            fontFamily: "Satoshi-black",
                            fontSize: 16,
                            color: request.status === "todo" ? "black" : "grey",
                          }}
                        >
                          {moment(request.departue_date_time).format(
                            "DD/MM/YYYY"
                          )}{" "}
                        </span>
                        <p
                          style={{
                            fontFamily: "Satoshi-Medium",
                            fontSize: 14,
                            color: request.status === "todo" ? "black" : "grey",
                          }}
                        >
                          {"  "} {request?.departue?.name}
                          {"   ("}
                          {
                            placeMap[
                              request.departue_place
                                ? request.departue_place
                                : request.place_id
                            ]
                          }
                          {")"}
                        </p>
                      </TableCell>
                      <TableCell align="left">
                        <span
                          style={{
                            fontFamily: "Satoshi-black",
                            fontSize: 16,
                            color:
                              request.status === "pickup" ? "black" : "grey",
                          }}
                        >
                          {moment(request.arrival_date_time).format(
                            "DD/MM/YYYY"
                          )}
                        </span>
                        <p
                          style={{
                            fontFamily: "Satoshi-Medium",
                            fontSize: 14,
                            color:
                              request.status === "pickup" ? "black" : "grey",
                          }}
                        >
                          {" "}
                          {request?.arrival?.name}
                          {"  ("}
                          {
                            placeMap[
                              request.arrival_place
                                ? request.arrival_place
                                : request.place_id
                            ]
                          }
                          {")"}
                        </p>
                      </TableCell>

                      <TableCell align="left">
                        {request?.client?.name || request?.name}

                        {subscribers && subscribers?.map((sub, index) => {
                          if (sub?.user?.id === request?.client?.id) {
                            return <HiBadgeCheck key={index} color={sub?.subscription?.color} />;
                          }
                          return null;
                        })}



                      </TableCell>

                      <TableCell align="left">
                        {request?.pay_amount / 100 + "€"}
                      </TableCell>

                      <TableCell align="left">
                        {request?.extra_services !== "[]" ? "Oui" : "Non"}
                      </TableCell>

                      <TableCell align="left">
                        {renderStatus(request.status)}
                      </TableCell>

                      {userData.type === "admin" && (
                        <TableCell align="left">
                          <Col
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "start",
                            }}
                          >
                            {request?.fligth_number === "mobile" ? (
                              <div>
                                <BiMobileAlt
                                  size={20}
                                  style={{
                                    marginRight: 5,
                                    marginTop: "auto",
                                    marginBottom: "auto",
                                  }}
                                />{" "}
                                Mobile{" "}
                              </div>
                            ) : request?.fligth_number === "web" ? (
                              <div
                                style={{
                                  fontSize: 14,
                                  marginTop: "auto",
                                  marginBottom: "auto",
                                }}
                              >
                                <BiWorld
                                  size={20}
                                  style={{
                                    marginRight: 5,
                                    marginTop: "auto",
                                    marginBottom: "auto",
                                  }}
                                />
                                Web{" "}
                              </div>
                            ) : request?.fligth_number === "web (manuelle)" ? (
                              <div
                                style={{
                                  fontSize: 14,
                                  marginTop: "auto",
                                  marginBottom: "auto",
                                }}
                              >
                                <BiPencil
                                  size={20}
                                  style={{
                                    marginRight: 5,
                                    marginTop: "auto",
                                    marginBottom: "auto",
                                  }}
                                />
                                Manuelle
                              </div>
                            ) : null}
                          </Col>
                        </TableCell>
                      )}

                      <TableCell align="left">
                        <Row>
                          {!noEdit && userData.type === "driver" ? (
                            <Col>
                              <Button
                                onClick={() =>
                                  openCloseModalForm("taking", request.id)
                                }
                                className="buttonGeneralStyle"
                                style={{ width: "auto" }}
                              >
                                Prise en charge
                              </Button>
                            </Col>
                          ) : null}

                          <Col xs={3}>
                            <AiFillEye
                              style={{ cursor: "pointer" }}
                              onClick={() => onOpenCloseViewRequest(request.id)}
                              size={"30"}
                            />
                          </Col>

                          {userData.type === "admin" ? (
                            <Col xs={3}>
                              <AiFillEdit
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  onOpenCloseEditRequest(request.id)
                                }
                                size={"30"}
                              />
                            </Col>
                          ) : null}

                          {userData.type === "admin" ? (
                            <Col xs={3}>
                              <BiTrash
                                style={{ cursor: "pointer" }}
                                onClick={() => setModalDelete(request.id)}
                                size={"30"}
                              />
                            </Col>
                          ) : null}
                        </Row>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20, 50]}
            component="div"
            count={requests.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Nombre de lignes"
          />
        </Col>
      </Row>
    </>
  );
};

export default TableRequests;
