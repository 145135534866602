import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { useSelector } from "react-redux";
import * as authApi from "../../../Axios/Call/AuthApi";
import LoadingButton from "../../Commons/LoadingButton/LoadingButton";
import moment from "moment";

const ClientModalView = ({
  onOpenCloseViewRequest,
  openModalView,
  renderStatus,
}) => {
  const [request, setRequest] = useState({});
  const userData = useSelector((state) => state.user.userData);

  useEffect(() => {
    if (Object.keys(userData).length && openModalView) {
      if (userData.type === "client") {
        authApi
          .clientrquestById(openModalView)
          .then((response) => {
            setRequest(response.data);
          })
          .catch(() => {});
      }
    }
  }, [userData, openModalView]);



  return (
    <>
      <Modal show={true} onHide={() => onOpenCloseViewRequest()} size={"lg"}>
        <Modal.Header closeButton>
          <Row>
            <Col>
              <span className="title">Détails de la reservation</span>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body style={{ marginTop: -10, paddingTop: 0 }}>
          <Row>
            <Col className="text-left">
              {Object.keys(request).length ? (
                <>
                  <Row style={{ marginTop: 10 }}>
                    <Col style={{ backgroundColor: "#232D4C", padding: 10 }}>
                      <span style={{ color: "white", fontSize: 20 }}>
                        Client
                      </span>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 10, marginBottom: "10px" }}>
                    <Col xs={12} lg={6} className="text-left">
                      <span>
                        Prénom: {request?.client?.name || request?.name}
                      </span>
                    </Col>
                    <Col xs={12} lg={6} className="text-left">
                      <span>
                        Nom: {request?.client?.surname || request?.surname}
                      </span>
                    </Col>
                    <Col xs={12} lg={6} className="text-left">
                      <span>
                        Mail: {request?.client?.email || request?.email}
                      </span>
                    </Col>
                    <Col xs={12} lg={6} className="text-left">
                      <span>
                        Téléphone: {request?.client?.phone || request?.phone}{" "}
                      </span>
                    </Col>
                    <Col xs={12} lg={6} className="text-left">
                      <span>Plaque d'immatriculation: {request?.plate}</span>
                    </Col>
                    <Col xs={12} lg={6} className="text-left">
                      <span>Numéro de billet: {request.fligth_number}</span>
                    </Col>
                  </Row>

                  <>
                    <Row>
                      <Col style={{ backgroundColor: "#232D4C", padding: 10 }}>
                        <span style={{ color: "white", fontSize: 20 }}>
                          Voiturier
                        </span>
                      </Col>
                    </Row>
                    {request.driver ? (
                      <Row style={{ marginTop: 10, marginBottom: "10px" }}>
                        <Col xs={12} lg={6} className="text-left">
                          <span>
                            <b>Prénom: </b>
                            {request.driver.name}
                          </span>
                        </Col>
                        <Col xs={12} lg={6} className="text-left">
                          <span>Nom: {request.driver.surname}</span>
                        </Col>
                        <Col xs={12} lg={6} className="text-left">
                          <span>Mail: {request.driver.email} </span>
                        </Col>
                        <Col xs={12} lg={6} className="text-left">
                          <span>Téléphone: {request.driver.phone} </span>
                        </Col>
                      </Row>
                    ) : (
                      <span>Valet non affecté</span>
                    )}
                  </>

                  <Row style={{ marginTop: 10 }}>
                    <Col style={{ backgroundColor: "#232D4C", padding: 10 }}>
                      <span style={{ color: "white", fontSize: 20 }}>
                        Informations
                      </span>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 10, marginBottom: "10px" }}>
                    <Col xs={12} lg={6} className="text-left">
                      <span>Départ: {request.departue_place}</span>
                    </Col>
                    <Col xs={12} lg={6} className="text-left">
                      <span>
                        Sélectionnez un point: {request.departue_destination}
                      </span>
                    </Col>
                    <Col xs={12} lg={6} className="text-left">
                      <span>Retours: {request.arrival_place}</span>
                    </Col>
                    <Col xs={12} lg={6} className="text-left">
                      <span>
                        Sélectionnez un point: {request.return_destination}
                      </span>
                    </Col>
                    <Col xs={12} lg={6} className="text-left">
                      <span>
                        Date de départ :{" "}
                        {moment(request.departue_date_time).format(
                          "DD/MM/YYYY"
                        )}{" "}
                        {request.departue_time.name}
                      </span>
                    </Col>
                    <Col xs={12} lg={6} className="text-left">
                      <span>
                        Date de retour:{" "}
                        {moment(request.arrival_date_time).format("DD/MM/YYYY")}{" "}
                        {request.arrival_time.name}
                      </span>
                    </Col>
                    <Col xs={12} lg={6} className="text-left">
                      <span>Service additionnel:</span>
                    </Col>
                    {request.extra_services
                      ? request.extra_services.map((service) => {
                          return (
                            <Col xs={12} lg={6} className="text-left">
                              <span>{service.name}</span>
                            </Col>
                          );
                        })
                      : null}
                    <Col xs={12} lg={6} className="text-left">
                      <span>{renderStatus(request.status)}</span>
                    </Col>

                    <Col xs={12} lg={6} className="text-left">
                      <span>Prix: {(request.pay_amount / 100).toFixed(2)}</span>
                    </Col>
                  </Row>
                </>
              ) : (
                <LoadingButton />
              )}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ClientModalView;
