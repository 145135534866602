import React, {useState} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import Img1 from '../../../Assets/Images/1.jpeg';
import Img2 from '../../../Assets/Images/2.jpeg';
import Img3 from '../../../Assets/Images/3.jpeg';
import classes from './HowItWorks.module.css';
import FooterImg from '../../../Assets/Images/footerImg.jpg';
import Container from "react-bootstrap/Container";
import { ImGoogle2 } from 'react-icons/im';

const HowItWorks = () => {
  const [features, setFeatures] = useState([{
    title: 'Réservation',
    description: 'En ligne facilement et rapidement sur notre site arvin-care.com',
    image: Img1,
  },{
    title: 'Prise en charge',
    description: 'Votre voiturier attitré vous attend au dépose minute du pôle de voyageur sélectionné. Votre véhicule sera stationné dans un parking à proximité, couvert et sécurisé',
    image: Img2,
  },{
    title: 'Retour',
    description: "À votre retour, votre voiturier suit en temps réel votre heure d'arrivée et achemine votre véhicule au dépose minute.",
    image: Img3,
  }])
 
  return(<>
 
  <Container fluid style={{marginTop: '2rem', backgroundColor:"#FFF"}}>
    <Row style={{paddingTop: '2rem', backgroundColor:"#FFF"}}>
      <Col className='text-center'>
        <h1 className={classes.title} style={{color:"#000"}}>Comment ça marche ?</h1>
      </Col>
    </Row>
    <Row style={{paddingTop: '1rem',color:"#000"}} >
      <Col xs={12} lg={{span: 6, offset: 3}}>
        <span style={{fontSize: 18, textAlign: "justify"}} >
          Arvin Care est un service de voiturier à l'aéroport de Rennes-Saint-Jacques et à la gare de Rennes. Nos services vous libèreront des contraintes de stationnement lors de vos départs et retours en laissant votre véhicule à l’un de nos voituriers au dépose minute du pôle de voyageur sélectionné.
          L'objectif d'Arvincare est de fournir un service de qualité, rapide et efficace, à un prix compétitif.
        </span>  
      </Col>
    </Row>
    <Row style={{marginTop: '0rem', marginBottom: '0rem'}}>
      {/*
      <Col xs={12} md={6}>
          <Image src={ImageLeft} style={{width: '100%'}}/>
      </Col>
      <Col xs={12} md={6}>
          <Image src={ImageRight} style={{width: '100%'}} />
      </Col>
      */}
        <Col xs={12} md={12}  style={{marginTop: '3rem', marginBottom: '-1rem', backgroundColor:"white"}}>
          <Image src={FooterImg} style={{width: '60%'}} />
      </Col>
  </Row>
    <Row style={{marginTop: '1rem', marginBottom: '2rem', backgroundColor:"white"}}>
      <Col xs={12} lg={{span: 8, offset: 2}}>
        <Row>
          <Col>
            <span className={classes.title} style={{color:"#000"}}>Les étapes</span>
          </Col>
        </Row>
        <Row style={{marginTop: '1rem'}}>
          {features.map((feature, index) => {
            return(
            <Col key={index} xs={12} lg={4} style={{marginTop: 20}}>
              <Card style={{minHeight: 380}}>
                <Row><Col>
                  <Image src={feature.image} style={{width: '100%'}}/>
                </Col></Row>
                <Row style={{padding: 20}}>
                  <Col className='text-left' xs={12}>
                    <span className={classes.titleCard}>{feature.title}</span>
                  </Col>
                  <Col className='text-left' style={{marginTop: 10}} xs={12}>
                    <span style={{fontSize: 16}}>{feature.description}</span>
                  </Col>
                </Row>
              </Card>
            </Col>
            )
          })}
        </Row>
      </Col>
    </Row>
    </Container>
  
  </>)
}


export default HowItWorks;