import React, {useState, useEffect} from 'react';
import { useParams, useHistory } from 'react-router';
import * as authApi from '../../../Axios/Call/AuthApi';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import classes from './DepartuePriceList.module.css';
import LoadingButton from '../../../Components/Commons/LoadingButton/LoadingButton';
import AlertGeneral from '../../../Components/Commons/AlertGeneral/AlertGeneral';
import { AiOutlineForm } from 'react-icons/ai';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const DepartuePriceList = () => {
    const {id} = useParams();
    const history = useHistory();
    const [departue, setDepartue] = useState({});
    const [departuePrices, setDepartuePrices] = useState([]);
    const [loadingApi, setLoadingApi] = useState(false);
    const [errorApi, setErrorApi] = useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('nom');

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };

      function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
          return -1;
        }
        if (b[orderBy] > a[orderBy]) {
          return 1;
        }
        return 0;
      }
      
      function getComparator(order, orderBy) {
        return order === 'desc'
          ? (a, b) => descendingComparator(a, b, orderBy)
          : (a, b) => -descendingComparator(a, b, orderBy);
      }

      const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
      };
      

      function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
      }

      const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
      };


    useEffect(() => {
        if(id){
            setLoadingApi(true)
            authApi.getDepartuePrices(id).then(response => {
                setDepartue(response.data.departue);
                setDepartuePrices(response.data.departuePrices);
                setLoadingApi(false);
            }).catch(error => {
                setLoadingApi(false);
            })
        }
    }, [id]);

    return(<>
        {errorApi ? <AlertGeneral color='danger' description='Error loading clients' setTrigger={setErrorApi} /> : null}
        <Container fluid >
            <Row style={{paddingTop: '4rem'}}>
                <Col>
                <Row>
                    <Col className='text-left my-auto' xs={12} lg={4}>
                            <span className='title'>{departue.name}</span>
                        </Col>
                        <Col className='text-left'>
                            <Button className='buttonGeneralStyle' onClick={() => history.push('/prices-add-edit/'+id)}>Créer un prix</Button>
                        </Col>
                    </Row>
                    <Row className='cardStyle'>
                        <Col xs={12} md={{span: 8}}>
                        <Row className='tableBackground' style={{marginTop: '0rem'}}>
                            <Col xs={6} md={6} lg={2} className='text-left'>
                                <span className='tableHeaderStyle'>Nombre de jours</span>
                            </Col>
                            <Col xs={6} md={6} lg={2} className='text-left'>
                                <span className='tableHeaderStyle'>Tarif Arvin Care</span>
                            </Col>
                            <Col xs={6} md={6} lg={2} className='text-left'>
                                <span className='tableHeaderStyle'>Tarif local</span>
                            </Col>
                            <Col xs={6} md={6} lg={2} className='text-left'>
                                <span className='tableHeaderStyle'>Différence</span>
                            </Col>
                            <Col xs={6} md={6} lg={2} className={`${classes.colStyle} text-left`}>
                                <span className='tableHeaderStyle'>Modifier</span>
                            </Col>
                        </Row>
                        {loadingApi ? <LoadingButton /> : 
                            departuePrices.map(departue => {
                                return(
                                    <>
                                    <Row style={{marginTop: '1rem'}} key={departue.id}>
                                        <Col xs={6} md={6} lg={2} className='text-left'>
                                            <span>{departue.days}</span>
                                        </Col>
                                        <Col xs={6} md={6} lg={2} className='text-left'>
                                            <span>{departue.our_price} €</span>
                                        </Col>
                                        <Col xs={6} md={6} lg={2} className='text-left'>
                                            <span>{departue.their_price} €</span>
                                        </Col>
                                        <Col xs={6} md={6} lg={2} className='text-left'>
                                            <span>- {(departue.percentage * 100).toFixed(1)} %</span>
                                        </Col>
                                        <Col xs={6} md={6} lg={2} className={`${classes.colStyle} text-left`}>
                                            <span><AiOutlineForm onClick={() => history.push('/prices-add-edit/'+departue.departue_id+'/'+departue.id)}/></span>
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop: 5}}>
                                        <Col xs={{span: 12, offset: 0}} className='text-left'>
                                            <div style={{width: '100%', height: 2, backgroundColor:'#E8E8E8' }}/>
                                        </Col>
                                    </Row>
                                </>
                            )
                        })}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    
    </>)
}

export default DepartuePriceList;